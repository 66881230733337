import { useAppDispatch, useAppSelector } from "hooks";
import { Modal } from "react-bootstrap";
import {
    adminCreateUserSelectActions,
    adminCreateUserSelectSelectors,
} from "./state";
import SearchDropdown, { SearchDropdownProps } from "components/SearchDropdown";
import { useState } from "react";
import { useGetAccountsQuery } from "api";

function AdminCreateUserSelect() {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState("");
    const showAdminCreateUserSelect = useAppSelector(
        adminCreateUserSelectSelectors.selectShow
    );

    const { data: users = [] } = useGetAccountsQuery({
        role: "user",
        search,
    });

    const handleSearchChange = (newSearch: string) => {
        setSearch(newSearch);
    };

    const handleSelect = (user: User) => {
        dispatch(adminCreateUserSelectActions.setSelected(user));
    };

    const handleExit = () => {
        dispatch(adminCreateUserSelectActions.hideAdminCreateModal());
    };

    const props: SearchDropdownProps<User> = {
        data: users,
        fieldsToDisplay: ["business"],
        onSelect: handleSelect,
        onSearchChange: handleSearchChange,
        placeholder: "Search for a user...",
    };
    return (
        <Modal show={showAdminCreateUserSelect} onHide={handleExit}>
            <Modal.Header closeButton>
                <Modal.Title>Select User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SearchDropdown {...props} />
            </Modal.Body>
        </Modal>
    );
}

export default AdminCreateUserSelect;
