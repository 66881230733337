import {
    InboundShipmentReceivedItem,
    InboundShipmentTracking,
} from "@librex-fulfillment/librex-zchemas";
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import {
    InboundShipmentItem_FE,
    InboundShipment_FE,
} from "features/inventory/inbound-shipments/types";
import { RootState } from "store";
import { isActiveShipment } from "utilities";

export type InboundShipmentState = {
    inboundShipment?: Partial<InboundShipment_FE>;
};

const initialState = {} as InboundShipmentState;
export const inboundShipmentDetailsSlice = createSlice({
    name: "inboundShipmentDetails" as const,
    initialState,
    reducers: {
        addTracking(state, action: PayloadAction<InboundShipmentTracking>) {
            if (!state.inboundShipment) return state;

            if (!state.inboundShipment?.tracking) {
                state.inboundShipment.tracking = [];
            }
            state.inboundShipment.tracking.push(action.payload);
        },
        receiveItem(state, action: PayloadAction<InboundShipmentReceivedItem>) {
            if (!state.inboundShipment?.received_items) {
                throw new Error(
                    "Invalid state when receiving item for inbound shipment"
                );
            }
            state.inboundShipment.received_items.push(action.payload);
        },
        removeClientItem(
            state,
            action: PayloadAction<
                InboundShipment_FE["received_items"][number]["_id"]
            >
        ) {
            if (!state.inboundShipment?.received_items) {
                return state;
            }
            state.inboundShipment.received_items =
                state.inboundShipment.received_items.filter(
                    (receivedItem) => receivedItem._id !== action.payload
                );
        },
        removeTrackingByIndex(state, action: PayloadAction<number>) {
            if (!state.inboundShipment?.tracking) return state;

            state.inboundShipment.tracking.splice(action.payload, 1);
        },
        resetState() {
            return initialState;
        },
        setInboundShipment(
            state,
            action: PayloadAction<Partial<InboundShipment_FE>>
        ) {
            state.inboundShipment = action.payload;
        },
        setName(state, action: PayloadAction<string>) {
            state.inboundShipment!.name = action.payload;
        },
        setNotes(state, action: PayloadAction<string>) {
            state.inboundShipment!.notes = action.payload;
        },
        setWarehouseId(state, action: PayloadAction<string>) {
            state.inboundShipment!.warehouse_id = action.payload;
        },
    },
});

export const inboundShipmentActions = inboundShipmentDetailsSlice.actions;

const selectActive = (state: RootState) =>
    state.inbound_shipment.inboundShipmentDetails.inboundShipment;
const selectCompanyId = (state: RootState) =>
    state.inbound_shipment.inboundShipmentDetails.inboundShipment?.company_id;
const selectItems = (state: RootState) =>
    state.inbound_shipment.inboundShipmentDetails.inboundShipment?.items;
const selectReceivedItems = (state: RootState) =>
    state.inbound_shipment.inboundShipmentDetails.inboundShipment
        ?.received_items;
const selectStatus = (state: RootState) =>
    state.inbound_shipment.inboundShipmentDetails.inboundShipment?.status;
const selectTracking = (state: RootState) =>
    state.inbound_shipment.inboundShipmentDetails.inboundShipment?.tracking;

const selectHasClientItems = createSelector(
    selectReceivedItems,
    (items) => items?.some((item) => item._id.startsWith("client_")) ?? false
);
const selectIsClosed = createSelector(
    selectStatus,
    (status) => !isActiveShipment(status)
);
const selectIsOpenAndActive = createSelector(
    selectIsClosed,
    selectActive,
    (isClosed, active) => !isClosed && active
);
const selectNewlyReceivedItems = createSelector(
    selectReceivedItems,
    (items) => items?.filter((item) => item._id.startsWith("client_")) ?? []
);

const selectSavedItems = createSelector(
    selectReceivedItems,
    (items) => items?.filter((item) => !item._id.startsWith("client_")) ?? []
);

const selectItemByIndex = (index?: number) =>
    createSelector(selectItems, (items) => {
        if (!Array.isArray(items) || Number.isNaN(index)) return undefined;
        const item = items.at(index!);
        if (!item) return undefined;
        return item as InboundShipmentItem_FE; // Types aren't inferring correctly so explicitly casting (2023-11-11)
    });
const selectItemBySku = (sku?: string) =>
    createSelector(selectItems, (items) => {
        const foundItem = items?.find((item) => item.sku === sku);
        if (!foundItem) return undefined;
        return foundItem as InboundShipmentItem_FE; // Types aren't inferring correctly so explicitly casting (2023-11-12)
    });
const selectNumReceivedForItemBySku = (sku?: string) =>
    createSelector(
        selectReceivedItemsBySku(sku),
        (items) =>
            items?.reduce((acc: number, item) => acc + item.quantity, 0) ?? 0
    );
const selectTotalQuantityReceived = createSelector(
    selectReceivedItems,
    (items) => items?.reduce((acc: number, item) => acc + item.quantity, 0) ?? 0
);
const selectTotalQuantityRequested = createSelector(
    selectItems,
    (items) => items?.reduce((acc: number, item) => acc + item.quantity, 0) ?? 0
);
const selectTrackingByIndex = (index?: number) =>
    createSelector(selectTracking, (tracking) => {
        if (
            !Array.isArray(tracking) ||
            Number.isNaN(index) ||
            index === undefined
        ) {
            return undefined;
        }

        const item = tracking.at(index);
        return item;
    });
const selectReceivedItemsBySku = (sku?: string) =>
    createSelector(selectReceivedItems, (items) =>
        items?.filter((item) => item.sku === sku)
    );

export const inboundShipmentSelectors = {
    selectActive,
    selectAmazonRemoval: (state: RootState) =>
        state.inbound_shipment.inboundShipmentDetails.inboundShipment?.traits
            ?.amazon_removal,
    selectCartonLabels: (state: RootState) =>
        state.inbound_shipment.inboundShipmentDetails.inboundShipment
            ?.carton_labels,
    selectCompanyId,
    selectCreatedDate: (state: RootState) =>
        state.inbound_shipment.inboundShipmentDetails.inboundShipment
            ?.createdAt,
    selectDates: (state: RootState) =>
        state.inbound_shipment.inboundShipmentDetails.inboundShipment?.dates,
    selectHasBeenBilled: (state: RootState) =>
        state.inbound_shipment.inboundShipmentDetails.inboundShipment?.traits
            ?.has_been_billed,
    selectHasClientItems,
    selectId: (state: RootState) =>
        state.inbound_shipment.inboundShipmentDetails.inboundShipment?._id,
    selectIsClosed,
    selectIsOpenAndActive,
    selectItems: (state: RootState) =>
        state.inbound_shipment.inboundShipmentDetails.inboundShipment?.items,
    selectItemByIndex,
    selectItemBySku,
    selectName: (state: RootState) =>
        state.inbound_shipment.inboundShipmentDetails.inboundShipment?.name,
    selectNewlyReceivedItems,
    selectNotes: (state: RootState) =>
        state.inbound_shipment.inboundShipmentDetails.inboundShipment?.notes,
    selectNumCartons: (state: RootState) =>
        state.inbound_shipment.inboundShipmentDetails.inboundShipment?.traits
            ?.num_cartons,
    selectReceivedItems,
    selectSavedItems,
    selectStatus,
    selectTotalQuantityReceived,
    selectTotalQuantityRequested,
    selectTracking,
    selectTrackingByIndex,
    selectType: (state: RootState) =>
        state.inbound_shipment.inboundShipmentDetails.inboundShipment?.type,
    selectUser: (state: RootState) =>
        state.inbound_shipment.inboundShipmentDetails.inboundShipment?.user,
    selectWarehouseId: (state: RootState) =>
        state.inbound_shipment.inboundShipmentDetails.inboundShipment
            ?.warehouse_id,
    selectWarehouseDisplay: (state: RootState) =>
        state.inbound_shipment.inboundShipmentDetails.inboundShipment?.warehouse
            ?.display,
    /**
     * DERIVERED SELECTORS
     */
    selectIsOpenInboundShipment: (state: RootState) =>
        isActiveShipment(
            state.inbound_shipment.inboundShipmentDetails.inboundShipment
                ?.status
        ),
    selectNumReceivedForItemBySku,
    selectTotalItems: (state: RootState) =>
        state.inbound_shipment.inboundShipmentDetails.inboundShipment?.items?.reduce(
            (acc: number, item) => acc + item.quantity,
            0
        ),
    selectIntegrationByKey:
        (key: keyof InboundShipment_FE["integrations"]) => (state: RootState) =>
            state.inbound_shipment.inboundShipmentDetails.inboundShipment
                ?.integrations?.[key],
    selectReceivedItemsBySku,
    /* Selectors for GraphQL mutations */
    selectQuantityReceivedCountForSku:
        (sku?: string) =>
        (state: RootState): number =>
            state.inbound_shipment.inboundShipmentDetails.inboundShipment?.received_items?.reduce(
                (acc: number, item) => {
                    if (item.sku === sku) {
                        return acc + item.quantity;
                    }
                    return acc;
                },
                0
            ) || 0,

    selectUpdateOneInboundShipmentFilter: createSelector(
        [
            (state: RootState) => ({
                _id: state.inbound_shipment.inboundShipmentDetails
                    .inboundShipment?._id,
                company_id:
                    state.inbound_shipment.inboundShipmentDetails
                        .inboundShipment?.company_id,
            }),
        ],
        (filter) => filter
    ),
};
