import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { InventoryAuditType } from "../../types";
import { RootState } from "store";

export const sliceName = "inventoryAudit" as const;
export type InventoryAuditSliceName = typeof sliceName;

export type InventoryAuditState = {
    filter: {
        type?: string;
        sku?: string;
    };
};

const initialState = {
    filter: {},
} as InventoryAuditState;
const inventoryAuditSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        clearFilterSku(state) {
            state.filter.sku = undefined;
        },
        clearFilterType(state) {
            state.filter.type = undefined;
        },
        setFilterType(state, action: PayloadAction<InventoryAuditType>) {
            state.filter.type = action.payload;
        },
        setFilterSku(state, action: PayloadAction<string | undefined>) {
            state.filter.sku = action.payload;
        },
    },
});

export const inventoryAuditActions = inventoryAuditSlice.actions;

export const inventoryAuditSelectors = {
    selectFilterType: (state: RootState) =>
        state.inventory[sliceName].filter.type,
    selectFilterSku: (state: RootState) =>
        state.inventory[sliceName].filter.sku,
    selectGqlQueryVariables: (state: RootState) =>
        state.inventory[sliceName].filter,
};

export default inventoryAuditSlice;
