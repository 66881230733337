import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { librexV3BaseQuery } from "./base";

export const authApi = createApi({
    baseQuery: librexV3BaseQuery("/auth"),
    tagTypes: ["Auth"],
    // Need to override reducer path since there is an exisitng products reducer
    reducerPath: "authApi",
    endpoints: (builder) => ({
        loginWithEmailAndPassword: builder.mutation<
            AuthData | null,
            LoginCredentials
        >({
            query: (credentials) => ({
                url: "/login",
                method: "POST",
                body: credentials,
            }),
            invalidatesTags: (results) => [
                { type: "Auth" as const, id: results?.user_id },
            ],
        }),
        generateAccessToken: builder.mutation<string | null, string>({
            query: () => ({
                url: "/login",
                method: "POST",
            }),
        }),
    }),
});

export const {
    useGenerateAccessTokenMutation,
    useLoginWithEmailAndPasswordMutation,
} = authApi;
