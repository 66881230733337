/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */
import axios from "axios";
import { V2_AUTH } from "../LibrexConstants";
import { ACCESS_TOKEN, REFRESH_TOKEN, USER_ID } from "./ManagerConstants";
import { v4 } from "uuid";

export const buildLibrexHeaders = () => {
    const accessToken = localStorage.getItem("access_token");
    return {
        headers: {
            Authorization: `BEARER ${accessToken}`,
            "request-id": v4(),
        },
    };
};

export const logout = () => {
    localStorage.clear();
};

/**
 * Generates a refresh token for access the Librex API using email and plain text password.
 * Sets following items in local storage
 * - refresh_token
 * - user_id
 * - role
 * IFF reply turnns a shipheroAccessToken the value is saved in sessionStorage
 * @param email Email of the user
 * @param password Plain-text password of the user
 */
export const generateRefreshTokenWithEmailAndPassword = async (
    email: string,
    password: string
) => {
    const body = {
        email,
        password,
    };
    const url = `${V2_AUTH}/login`;
    const generate_refresh_token_response = await librexPostRequest(url, body);

    if (
        generate_refresh_token_response.success &&
        generate_refresh_token_response.data
    ) {
        localStorage.setItem(
            REFRESH_TOKEN,
            generate_refresh_token_response.data
        );
        localStorage.setItem(
            "user_id",
            generate_refresh_token_response.user_id
        );
        localStorage.setItem("role", generate_refresh_token_response.role);

        if (generate_refresh_token_response.shipheroAccessToken) {
            localStorage.setItem(
                "shipheroAccessToken",
                generate_refresh_token_response.shipheroAccessToken
            );
        }
    }

    return generate_refresh_token_response;
};

/**
 * Generates a refresh token for access the Librex API and stores it in local storage;
 * @param {string} email Email of the user attempting to login
 * @param {string} password Plain-text password of the user attempting to login
 */
export const generateAccessTokenWithRefreshToken = async () => {
    const refresh_token = localStorage.getItem(REFRESH_TOKEN);
    const body = {
        refresh_token,
    };

    const url = `${V2_AUTH}/token/refresh`;
    const generate_access_token_response: LibrexAPIReply<AuthData> =
        await librexPostRequest(url, body, { has_retried: true });

    if (!generate_access_token_response?.data?.token)
        return generate_access_token_response;

    localStorage.setItem(
        ACCESS_TOKEN,
        generate_access_token_response.data.token
    );
    localStorage.setItem(USER_ID, generate_access_token_response.data.user_id);
    return generate_access_token_response;
};

export const librexPostRequest = async (
    url: string,
    body: any,
    req_options: any = {}
): Promise<any> => {
    const { has_retried, ...options } = req_options;
    if (!options.headers) {
        options.headers = {};
    }
    options.headers = {
        ...options.headers,
        ...buildLibrexHeaders().headers,
    };

    return axios
        .post(url, body, options)
        .then((response) => response.data)
        .catch(async (error) => {
            // If auth fails attempt to regenerate the access token then try again
            if (
                error.response &&
                error.response.status === 401 &&
                has_retried !== true
            ) {
                const generate_access_token_response =
                    await generateAccessTokenWithRefreshToken();
                if (generate_access_token_response.success) {
                    options.headers = {
                        ...options.headers,
                        ...buildLibrexHeaders().headers,
                        has_retried: true,
                    };
                    return librexPostRequest(url, body, options);
                }
                logout();
            } else if (error.response) {
                return error.response.data;
            } else {
                return {
                    success: false,
                    status: 500,
                    message:
                        "An unkown error occured. Please try again or contact Librex Fulfillment support.",
                };
            }
        });
};

export const librexPutRequest = async (
    url: any,
    body: any,
    options: any = {}
): Promise<any> => {
    if (!options.headers) {
        options.headers = {};
    }
    options.headers = {
        ...options.headers,
        ...buildLibrexHeaders().headers,
    };

    return axios
        .put(url, body, options)
        .then((response) => response.data)
        .catch(async (error) => {
            // If auth fails attempt to regenerate the access token then try again
            if (error.response && error.response.status === 401) {
                const generate_access_token_response =
                    await generateAccessTokenWithRefreshToken();
                if (generate_access_token_response.success) {
                    options.headers = {
                        ...buildLibrexHeaders().headers,
                    };
                    return librexPutRequest(url, body, options);
                }
                logout();
            } else if (error.response) {
                return error.response.data;
            }
        });
};

export const librexGetRequest = async (
    url: any,
    { options, has_retried }: any = {}
): Promise<any> => {
    const headers = options || buildLibrexHeaders();

    return axios
        .get(url, headers)
        .then((response) => response.data)
        .catch(async (error) => {
            // If auth fails attempt to regenerate the access token then try again
            if (
                error.response &&
                error.response.status === 401 &&
                has_retried !== true
            ) {
                const generate_access_token_response =
                    await generateAccessTokenWithRefreshToken();
                if (generate_access_token_response.success) {
                    return librexGetRequest(url, {
                        options,
                        has_retried: true,
                    });
                }
                logout();
            } else if (error.response) {
                return error.response.data;
            }
        });
};
