export const isOneOrMoreArray = (array?: any[]) =>
    Array.isArray(array) && array.length > 0;

export const isValidIndex = (arr: any[], index: number) => {
    // handle invalid data types
    if (!Array.isArray(arr)) return false;
    if (typeof index !== "number") return false;
    // handle array index out of bounds
    if (index < 0) return false;
    if (index >= arr.length) return false;

    return true;
};

export function sortByName(
    list: any[],
    AtoZ: boolean,
    field: string,
    backup = ""
) {
    // Without this typescript throws "attempt to assign to readonly property" due to _id
    const dereferenced_list: any[] = [...list];
    if (AtoZ) {
        return dereferenced_list.sort((a, z) => {
            const safe_field = field || backup;
            const val1 = `${a[safe_field]}`.toLowerCase();
            const val2 = `${z[safe_field]}`.toLowerCase();
            return val1.localeCompare(val2);
        });
    }
    return dereferenced_list.sort((z, a) => {
        const val1 = `${a[field] ? a[field] : a[backup]}`.toLowerCase();
        const val2 = `${z[field] ? z[field] : z[backup]}`.toLowerCase();

        return val1.localeCompare(val2);
    });
}

export const sortByNumber = (data: any[], field: string, ascending = false) => {
    const dereferenced_list: any[] = [...data];
    if (ascending) {
        return dereferenced_list.sort((a, z) => a[field] - z[field]);
    }
    return data.sort((z, a) => a[field] - z[field]);
};

export const sortByDate = (data: any[], field: string, ascending = false) => {
    const dereferenced_list: any[] = [...data];
    if (ascending) {
        return dereferenced_list.sort(
            (a, z) =>
                new Date(a[field]).getTime() - new Date(z[field]).getTime()
        );
    }

    return dereferenced_list.sort(
        (z, a) => new Date(a[field]).getTime() - new Date(z[field]).getTime()
    );
};
