import { ReactNode } from "react";
import { OrderStatusProcessingIcon } from "../icons/OrdersIcons";

import AmazonSourceIconSmall from "../assets/img/selling-channels/AmazonSmall.png";
import AmazonSourceIconLarge from "../assets/img/selling-channels/AmazonLarge.png";
import EbaySourceIconSmall from "../assets/img/selling-channels/EbaySmall.png";
import EbaySourceIconLarge from "../assets/img/selling-channels/EbayLarge.png";
import EtsySourceIconSmall from "../assets/img/selling-channels/EtsySmall.png";
import EtsySourceIconLarge from "../assets/img/selling-channels/EtsyLarge.png";
import HsnSourceIcon from "../assets/img/selling-channels/Hsn.png";
import ManualOrderSourceIconSmall from "../assets/img/selling-channels/ManualOrderSmall.png";
import ManualOrderSourceIconLarge from "../assets/img/selling-channels/ManualOrderLarge.png";
import ShopifySourceIconSmall from "../assets/img/selling-channels/ShopifySmall.png";
import ShopifySourceIconLarge from "../assets/img/selling-channels/ShopifyLarge.png";
import WalmartSourceIconSmall from "../assets/img/selling-channels/WalmartSmall.png";
import WalmartSourceIconLarge from "../assets/img/selling-channels/WalmartLarge.png";
import WooCommerceSourceIconSmall from "../assets/img/selling-channels/WooCommerceSmall.png";
import WooCommerceSourceIconLarge from "../assets/img/selling-channels/WooCommerceLarge.png";
import { isNonEmptyString, isStringOrUndefined } from "./utilities";
import { isValidCustomerAddress } from "./account";

export const calculateOrderSubtotal = (
    order_items: CreateOrderItemInput | OrderItemI
): number | undefined => {
    if (!Array.isArray(order_items)) return;
    return order_items.reduce(
        (subtotal: number, item: CreateOrderItemInput | OrderItemI) => {
            const price = Number.isNaN(parseInt(item.price, 10))
                ? 0
                : parseInt(item.price, 10);
            return subtotal + price;
        },
        0
    );
};

export const getOrderSource = (order: OrderI): string | undefined => {
    /**
     * Orders created via the API will have a source of "api" but the appropriate
     * shop name will be in the shop_name field. This allows us to display the
     * correct source image for the order.
     */
    const shouldUseShopName = ["api"].includes(
        order.source?.toLowerCase() ?? ""
    );
    return shouldUseShopName ? order.shop_name : order.source;
};

export function getOrderSourceImageSourceSmall(order: OrderI) {
    switch (getOrderSource(order)?.toLowerCase()) {
        case "amazon":
            return AmazonSourceIconSmall;
        case "ebay":
            return EbaySourceIconSmall;
        case "etsy":
            return EtsySourceIconSmall;
        case "hsn":
            return HsnSourceIcon;
        case "shopify":
            return ShopifySourceIconSmall;
        case "woocommerce":
            return WooCommerceSourceIconSmall;
        case "walmart":
            return WalmartSourceIconSmall;
        default:
            return ManualOrderSourceIconSmall;
    }
}
export function getOrderSourceImageSourceLarge(order: OrderI) {
    switch (getOrderSource(order)?.toLowerCase()) {
        case "amazon":
            return AmazonSourceIconLarge;
        case "ebay":
            return EbaySourceIconLarge;
        case "etsy":
            return EtsySourceIconLarge;
        case "hsn":
            return HsnSourceIcon;
        case "shopify":
            return ShopifySourceIconLarge;
        case "walmart":
            return WalmartSourceIconLarge;
        case "woocommerce":
            return WooCommerceSourceIconLarge;
        default:
            return ManualOrderSourceIconLarge;
    }
}

export function getOrderSourceName(order: OrderI): string {
    switch (getOrderSource(order)?.toLowerCase()) {
        case "amazon":
            return "Amazon";
        case "ebay":
            return "Ebay";
        case "etsy":
            return "Etsy";
        case "hsn":
            return "HSN";
        case "shopify":
            return "Shopify";
        case "walmart":
            return "Walmart";
        case "woocommerce":
            return "WooCommerce";
        default:
            return "Manual Order";
    }
}

export function getOrderStatusIcon({ status }: OrderI) {
    switch (status) {
        case "canceled":
            return (
                <span className="text-muted">
                    <OrderStatusProcessingIcon />
                </span>
            );
        case "pending":
            return <OrderStatusProcessingIcon />;
        default:
            // eslint-disable-next-line react/jsx-no-useless-fragment
            return <></>;
    }
}

export function getOrderStatusText({ status }: OrderI): ReactNode {
    switch (status) {
        case "canceled":
            return "Order canceled";
        case "fulfilled":
            return (
                <>
                    <span className="text-success fw-bold">Completed -</span>{" "}
                    Order fulfilled
                </>
            );
        case "pending":
            return "Order is processing";
        default:
            return "";
    }
}

/**
 * Determines if the provided item input has all required fields, value types, and values for creating an order item.
 * If valid returns true
 * If invalid return string reason for invalidity
 */
export const isValidOrderItem = (
    input: CreateOrderItemInput | OrderItemI
): string | true => {
    if (!input) return "Invalid input type";
    if (isNonEmptyString(input.name) !== true) return "Invalid item name";
    if (typeof input.quantity !== "number" || input.quantity < 1)
        return "Invalid quantity";
    if (isNonEmptyString(input.sku) !== true) return "Invalid sku";

    return true;
};

export const isValidOrderItems = (
    input: CreateOrderItemInput[] | OrderItemI[]
): string | true => {
    if (!Array.isArray(input)) return "Items not an array";

    if (input.length === 0) return "No items in the order";

    for (let item_idx = 0; item_idx < input.length; item_idx++) {
        const is_item_valid = isValidOrderItem(input[item_idx]);

        if (is_item_valid !== true) return is_item_valid;
    }

    return true;
};

export const isValidOrderAttributes = (
    input: OrderAttributeI
): string | true => {
    // All values must be a boolean for order attributes
    const values = Object.values(input);
    if (values.every((value) => typeof value === "boolean") === false)
        return "Invalid order attributes";

    return true;
};

export const isValidOrder = (input: CreateOrderInput): string | true => {
    if (!input) return "Invalid order data, we're not sure what";

    const is_valid_customer_address = isValidCustomerAddress(
        input.customer_address
    );
    if (is_valid_customer_address !== true) return is_valid_customer_address;

    if (typeof input.is_business_address !== "boolean")
        return "Is business address must be true or false";

    const is_items_valid = isValidOrderItems(input.items);
    if (is_items_valid !== true) return is_items_valid;

    const is_order_attributes_valid = isValidOrderAttributes(
        input.order_attributes
    );
    if (is_order_attributes_valid !== true) return is_order_attributes_valid;

    if (
        isStringOrUndefined(input.order_number) !== true &&
        typeof input.order_number !== "number"
    )
        return "Order number must be a string or number";

    return true;
};

export const reduceOrderSubtotal = (
    subtotal: number,
    item: CreateOrderItemInput | OrderItemI
) => {
    const price = Number.isNaN(parseInt(item.price, 10))
        ? 0
        : parseInt(item.price, 10);

    const quantity = item.quantity ?? 0;
    return subtotal + price * quantity;
};
