// Grab host from the browser window
export const HOST =
    process.env.REACT_APP_LIBREX_API_HOST ??
    `${window.location.protocol}//${window.location.hostname}`;
export const API_ENDPOINT = "api";
export const V1_ENDPOINT = "v1";
export const V2_ENDPOINT = "v2";
export const V3_ENDPOINT = "v3";
export const AUTH_ENDPOINT = "auth";
export const TOKEN_ENDPOINT = "token";

export const API_BASE = `${HOST}/${API_ENDPOINT}`;
export const V1_BASE = `${API_BASE}/${V1_ENDPOINT}`;
export const V2_BASE = `${API_BASE}/${V2_ENDPOINT}`;
export const V3_BASE = `${API_BASE}/${V3_ENDPOINT}`;

export const V2_AUTH = `${HOST}/${API_ENDPOINT}/${V2_ENDPOINT}/${AUTH_ENDPOINT}`;

export const V3_GRAPHQL_URI = `${V3_BASE}/graphql`;

export const ITEM_SKU_FIELD = "sku";
export const ITEM_NAME_FIELD = "name";

export const SHIPMENT_TYPES = (): LooseObject<string> => ({
    fulfillment: "Fullfillment",
    fba_prep_kitting: "FBA Prep/Kitting",
    storage: "Storage",
});

export const LOCATIONS = (): LooseObject<string> => ({
    fredrickMD: "Frederick, Maryland",
    jessupMD: "Jessup, Maryland",
    rochesterNY: "Rochester, New York",
    chicagoIL: "Chicago, Illinois",
    houstonTX: "Houston, Texas",
    seattleWA: "Seattle, Washington",
    losangelesCA: "Los Angeles, California",
});

export const LOCATION_IDS: LooseObject<string> = {
    V2FyZWhvdXNlOjcyNjky: "Fredrick, Maryland",
    x: "Jessup, Maryland",
    V2FyZWhvdXNlOjgyMDU2: "Rochester, NY",
    V2FyZWhvdXNlOjc4MjY3: "Chicago, Illinois",
    xxxx: "Houston, Texas",
    xxx: "Seattle, Washington",
    xxxxx: "Los Angeles, California",
};

export const LOCATION_ADDRESSES: LooseObject<string> = {
    V2FyZWhvdXNlOjcyNjky: "4780 Winchester Blvd Suite 4, Frederick, MD 21703",
    V2FyZWhvdXNlOjgyMDU2: "700 Mile Crossing Blvd Unit 1A, Rochester, NY 14624",
    V2FyZWhvdXNlOjc4MjY3: "133 Juniper St, Manteno, IL 60950",
};

/** ************************************************************************
 * ALL INBOUND SHIPMENT ARE CURRENTLY BEING ROUTED TO FREDERICK, MARYLAND *
 ************************************************************************* */
// fulfillment services are offerred in Frederick, Maryland only
const INBOUND_SHIPMENT__FULFILLMENT__WAREHOUSE_LOCATIONS = new Set([
    "V2FyZWhvdXNlOjcyNjky",
    "V2FyZWhvdXNlOjgyMDU2",
]);
// fba prep and kitting services are offered in Chicago, IL only
const INBOUND_SHIPMENT__FBA_PREP_KITTING__WAREHOUSE_LOCATIONS = new Set([
    "V2FyZWhvdXNlOjcyNjky",
    "V2FyZWhvdXNlOjgyMDU2",
]);
// storage services are offered in Chicago, IL only
const INBOUND_SHIPMENT__STORAGE__WAREHOUSE_LOCATIONS = new Set([
    "V2FyZWhvdXNlOjcyNjky",
    "V2FyZWhvdXNlOjgyMDU2",
]);
/** ************************************************************************
 * ALL INBOUND SHIPMENT ARE CURRENTLY BEING ROUTED TO FREDERICK, MARYLAND *
 ************************************************************************* */

// Utility object for deciding which shipment types can be sent to which warehouse
export const SHIPMENT_TYPES_AND_WAREHOUSES: LooseObject<Set<string>> = {
    fulfillment: INBOUND_SHIPMENT__FULFILLMENT__WAREHOUSE_LOCATIONS,
    fba_prep_kitting: INBOUND_SHIPMENT__FBA_PREP_KITTING__WAREHOUSE_LOCATIONS,
    storage: INBOUND_SHIPMENT__STORAGE__WAREHOUSE_LOCATIONS,
};

export const SHIPMENT_STATUS = () => ({
    not_received: "inbound",
    inbound: "Inbound",
    processing: "Processing",
    completed: "Completed",
    canceled: "Canceled",
    error: "Error",
});

export const PREP_STATUS: LooseObject<string> = {
    requested: "Requested",
    prepped: "Prepped",
    sent: "Sent",
    canceled: "Canceled",
    error: "Error",
};

export const EVENT_TYPE__CREATED = "created";
export const EVENT_TYPE__DELIVERED = "delivered";
export const EVENT_TYPE__FORWARDED = "forwarded";
export const EVENT_TYPE__RECEIVED = "received";
export const EVENT_TYPE__OUT_OF_STOCK = "out-of-stock";
export const PRODUCT_EVENT_TYPE_ENUM = [
    EVENT_TYPE__CREATED,
    EVENT_TYPE__DELIVERED,
    EVENT_TYPE__FORWARDED,
    EVENT_TYPE__RECEIVED,
    EVENT_TYPE__OUT_OF_STOCK,
];

/*
 * Builds a PDF file from a Base64 encoded binary string
 * @param {string} encodedFile | Base64 encoded binary string representing a pdf file
 */
export const buildFileUrl = (encodedFile: any) => {
    // Decode the Base64 string to a binary
    const decodedFile = window.atob(encodedFile);

    // Get the length of the binary and initiate an 8bit array for the Blob to create a pdf
    const binaryLength = decodedFile.length;
    const fileBytes = new Uint8Array(binaryLength);

    // Convert the binary string into an 8bit
    for (let i = 0; i < binaryLength; i++) {
        const ascii = decodedFile.charCodeAt(i);
        fileBytes[i] = ascii;
    }

    // Use the blob API with an 8bit character array to generate the pdf
    const blob = new Blob([fileBytes], { type: "application/pdf" });

    // Use the URL object to generate a link to download the pdf
    const URL = window.URL || window.webkitURL;
    return URL.createObjectURL(blob);
};
