import { useSelector } from "react-redux";
import { queryAndFilterSelectors } from "../slice";
import PreviousPage from "./PreviousPage";
import NextPage from "./NextPage";
import styles from "./Pagination.module.scss";

const Pagination = () => {
    const pagination = useSelector(queryAndFilterSelectors.selectPagination);

    const firstItemIndex = useSelector(
        queryAndFilterSelectors.selectFirstItemIndex
    );
    const lastItemIndex = useSelector(
        queryAndFilterSelectors.selectLastItemIndex()
    );

    return (
        <div className={styles.pagination}>
            <PreviousPage />
            <p>
                {firstItemIndex} to {lastItemIndex} of {pagination.count}{" "}
            </p>
            <NextPage />
        </div>
    );
};

export default Pagination;
