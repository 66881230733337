/// Order GraphQL Zchemas

import { z } from "zod";
import { ZShipheroAddress } from "./common";

// MAX_STRING_SIZE_FOR_LABEL characters is the max length of any field for a label
// Some fields have a smaller max length, but we'll use MAX_STRING_SIZE_FOR_LABEL for all to make it easy
const MAX_STRING_SIZE_FOR_LABEL = 50;
export const ZShipheroOrderAddress = ZShipheroAddress.omit({
  name: true,
}).extend({
  company: z.string().max(MAX_STRING_SIZE_FOR_LABEL).optional(),
  country_code: z.string().max(MAX_STRING_SIZE_FOR_LABEL).optional(),
  email: z.string().max(MAX_STRING_SIZE_FOR_LABEL).optional(),
  first_name: z.string().max(MAX_STRING_SIZE_FOR_LABEL).optional(),
  last_name: z.string().max(MAX_STRING_SIZE_FOR_LABEL).optional(),
  state_code: z.string().max(MAX_STRING_SIZE_FOR_LABEL).optional(),
});
export type ShipheroOrderAddress = z.infer<typeof ZShipheroOrderAddress>;

export const ZShipheroOrderHolds = z.object({
  address_hold: z.boolean().optional(),
  client_hold: z.boolean().optional(),
  fraud_hold: z.boolean().optional(),
  operator_hold: z.boolean().optional(),
  payment_hold: z.boolean().optional(),
  shipping_method_hold: z.boolean().optional(),
});
export type ShipheroOrderHolds = z.infer<typeof ZShipheroOrderHolds>;

export const ZShipheroOrderLineItem = z.object({
  backorder_quantity: z.number().optional(),
  barcode: z.string().optional(),
  created_at: z.string().optional(),
  custom_barcode: z.string().optional(),
  customs_value: z.string().optional(),
  eligible_for_return: z.boolean().optional(),
  fulfillment_status: z.string().optional(),
  id: z.string().optional(),
  legacy_id: z.number().optional(),
  locked_to_warehouse_id: z.string().optional(),
  option_title: z.string().optional(),
  order_id: z.string().optional(),
  partner_line_item_id: z.string(),
  price: z.string().default("0.00"),
  product_id: z.string().optional(),
  product_name: z.string(),
  promotion_discount: z.string().optional(),
  quantity: z.number().positive(),
  quantity_allocated: z.number().optional(),
  quantity_pending_fulfillment: z.number().optional(),
  quantity_shipped: z.number().optional(),
  sku: z.string(),
  subtotal: z.string().optional(),
  updated_at: z.string().optional(),
  warehouse: z.string().optional(),
  warehouse_id: z.string().optional(),
});
export type ShipheroOrderLineItem = z.infer<typeof ZShipheroOrderLineItem>;

export const ZShipheroOrderShippingLines = z.object({
  carrier: z.string().optional(),
  method: z.string().optional(),
  price: z.string().optional(),
  title: z.string().optional(),
});
export type ShipheroOrderShippingLines = z.infer<
  typeof ZShipheroOrderShippingLines
>;

export const ZShipheroOrder = z.object({
  address_is_business: z.boolean().optional(),
  adult_signature_required: z.boolean().optional(),
  alcohol: z.boolean().optional(),
  allocation_priority: z.number().optional(),
  allow_partial: z.boolean().optional(),
  allow_split: z.boolean().optional(),
  auto_print_return_label: z.boolean().optional(),
  billing_address: ZShipheroOrderAddress.optional(),
  box_name: z.string().optional(),
  currency: z.string().optional(),
  custom_invoice_url: z.string().optional(),
  customer_account_id: z.string().optional(),
  dry_ice_weight_in_lbs: z.string().optional(),
  email: z.string().optional(),
  expected_weight_in_oz: z.string().optional(),
  flagged: z.boolean().optional(),
  fulfillment_status: z.string().optional(), // Status of the order (pending, fulfilled, cancelled, etc)
  gift_invoice: z.boolean().optional(),
  gift_note: z.string().optional(),
  has_dry_ice: z.boolean().optional(),
  hold_until_date: z.string().optional(),
  holds: ZShipheroOrderHolds.optional(),
  id: z.string().optional(),
  ignore_address_validation_errors: z.boolean().optional(),
  incoterms: z.string().optional(),
  insurance: z.boolean().optional(),
  insurance_amount: z.string().optional(),
  legacy_id: z.number().optional(),
  line_items: z.array(ZShipheroOrderLineItem).min(1),
  order_date: z.string().optional(),
  order_number: z.string().optional(),
  packing_note: z.string().optional(),
  partner_order_id: z.string(),
  priority_flag: z.boolean().optional(),
  profile: z.string().optional(),
  ready_to_ship: z.boolean().optional(),
  require_signature: z.boolean().optional(),
  required_ship_date: z.string().optional(),
  saturday_delivery: z.boolean().optional(),
  shipping_address: ZShipheroOrderAddress,
  shipping_lines: ZShipheroOrderShippingLines.optional(),
  shop_name: z.string().optional(),
  skip_address_validation: z.boolean().optional(),
  source: z.string().optional(),
  subtotal: z.string().optional(),
  tags: z.array(z.string()).optional(),
  tax_id: z.string().optional(),
  tax_type: z.string().optional(),
  total_discounts: z.string().optional(),
});
export type ShipheroOrder = z.infer<typeof ZShipheroOrder>;
