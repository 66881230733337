import { ZMongoId, ZTimestamps } from "common";
import { STRING_MEDIUM_MAX_LENGTH } from "consts";
import { z } from "zod";

export const ZS3DocumentFileType = z.enum(["pdf", "image"]);
export type S3DocumentFileType = z.infer<typeof ZS3DocumentFileType>;
export const S3_DOCUMENT_FILE_TYPE = ZS3DocumentFileType.enum;
export const S3_DOCUMENT_FILE_TYPES = Object.values(S3_DOCUMENT_FILE_TYPE);

export const ZS3Document = z
  .object({
    company_id: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
    deleted: z.boolean().default(false),
    file_type: ZS3DocumentFileType,
    path: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH).optional(),
    reference_id: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
  })
  .extend(ZMongoId)
  .extend(ZTimestamps);
export type S3Document = z.infer<typeof ZS3Document>;
