import type { RootState } from "store";
import {
    PayloadAction,
    createListenerMiddleware,
    createSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { B2BPick_Input_FE } from "../../types";
import { b2bBoxItemFactory, b2bFormBoxFactory } from "../../factories";
import { b2bOrderActions } from "features/b2b/state";
import {
    B2BBox,
    B2BBoxItem,
    B2BPickCreateInput,
    B2BPickItem,
    ProductLocation,
} from "@librex-fulfillment/librex-zchemas";

export const sliceName = "form" as const;
export type B2BAdminFormSliceName = typeof sliceName;

type AddPickFormState = {
    location?: ProductLocation;
    picks: B2BPick_Input_FE[];
};
type FormState = {
    // Maps item_id to currently selected location and created picks
    picks: Record<string, AddPickFormState>;
    box: Omit<B2BBox, "dimensions" | "quantity">;
};

export type B2BAdminFormState = FormState;
const initialState = {
    box: b2bFormBoxFactory(),
} as B2BAdminFormState;

export const b2bAdminFormSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        addPickForItem(state, action: PayloadAction<B2BPick_Input_FE>) {
            state!.picks[action.payload.item_id].picks.push(action.payload);
        },
        addFormBoxItem(state) {
            state!.box.items.push(b2bBoxItemFactory());
        },
        picksLoaded(state, action: PayloadAction<B2BPickItem[]>) {
            if (!Array.isArray(action.payload)) return;
            state.picks = action.payload.reduce((itemMap, pickItem) => {
                itemMap[pickItem.item_id] = {
                    picks: [],
                };
                return itemMap;
            }, {} as FormState["picks"]);
        },
        removeFormBoxItemByIndex(state, action: PayloadAction<number>) {
            if (!state?.box?.items.at(action.payload)) return;
            state.box.items.splice(action.payload, 1);

            // There should always be at least one empty item in the box
            if (state.box.items.length === 0) {
                state.box.items.push(b2bBoxItemFactory());
            }
        },
        removeFormPickForItemByIndex(
            state,
            action: PayloadAction<{
                item_id: string;
                idx: number;
            }>
        ) {
            if (
                !state?.picks[action.payload.item_id]?.picks.at(
                    action.payload.idx
                )
            ) {
                return;
            }
            state.picks[action.payload.item_id].picks = state.picks[
                action.payload.item_id
            ].picks.filter((_, idx) => idx !== action.payload.idx);
        },
        resetFormBoxState(state) {
            state!.box = b2bFormBoxFactory();
        },
        resetAdminFormState() {
            return initialState;
        },
        restPickState(state) {
            if (!state.picks) return;
            Object.keys(state.picks).forEach((item_id) => {
                if (!state.picks[item_id]) return;
                state.picks[item_id].picks = [];
            });
        },
        setBoxItemItemByIndex(
            state,
            {
                payload: { idx, ...boxItem },
            }: PayloadAction<
                Omit<B2BBoxItem, "quantity" | "_id"> & { idx: number }
            >
        ) {
            if (!state?.box.items.at(idx)) return;
            state.box.items[idx] = {
                ...state.box.items[idx],
                ...boxItem,
            };
        },
        setBoxItemQuantityByIndex(
            state,
            action: PayloadAction<{
                idx: number;
                quantity: number;
            }>
        ) {
            if (!state?.box.items.at(action.payload.idx)) return;
            state.box.items[action.payload.idx].quantity =
                action.payload.quantity;
        },
        setFormPickItemLocation(
            state,
            payload: PayloadAction<{
                item_id: string;
                location: ProductLocation;
            }>
        ) {
            if (!state?.picks[payload.payload.item_id]) return;
            state.picks[payload.payload.item_id].location =
                payload.payload.location;
        },
        setFormPickItemQuantityByPickIndex(
            state,
            {
                payload: { item_id, idx, quantity },
            }: PayloadAction<{
                idx: number;
                item_id: string;
                quantity: number;
            }>
        ) {
            if (!state?.picks[item_id]?.picks.at(idx)) return;

            state.picks[item_id].picks.at(idx)!.quantity = quantity;
        },
    },
});

export const b2bAdminFormActions = b2bAdminFormSlice.actions;

export const b2bAdminFormSelectors = {
    // Simple selectors - Direct state access selctors go here
    selectPickLocationForPickItem: (item_id: string) => (state: RootState) =>
        state.b2b.admin[sliceName].picks[item_id]?.location,
    selectFormBoxItems: (state: RootState) =>
        state.b2b.admin[sliceName].box?.items,
    /*
     * Derived selectors - Computed state access selectors go here
     */
    selectIsLastFormBoxItemLine: (idx: number) => (state: RootState) =>
        idx + 1 === state.b2b.admin[sliceName].box?.items.length,
    selectFormPickForItem: (item_id: string) => (state: RootState) =>
        state.b2b.admin[sliceName].picks[item_id]?.picks,
    /* Selectors for GraphQL mutations */
    selectCreatePicksMutationRecords: createSelector(
        [
            (state: RootState): B2BPickCreateInput[] => {
                if (!state.b2b.admin[sliceName].picks) return [];
                const formPicks = Object.values(
                    state.b2b.admin[sliceName].picks
                );
                if (!Array.isArray(formPicks)) return [];

                const picks = formPicks.flatMap((pickState: AddPickFormState) =>
                    pickState.picks.map(({ _id, location, ...pick }) => pick)
                );

                return picks;
            },
        ],
        (picks) => picks
    ),
};

const b2bAdminFormSliceMiddlewear = createListenerMiddleware();
/*
 * Reset admin form box state everyttime a the box in state is added to the b2b order
 */
b2bAdminFormSliceMiddlewear.startListening({
    actionCreator: b2bOrderActions.addBoxToB2bOrder,
    effect: (action, listenerApi) => {
        listenerApi.dispatch(b2bAdminFormActions.resetFormBoxState());
    },
});

/*
 * Reset admin form state everytime a new b2b order is loaded
 */
b2bAdminFormSliceMiddlewear.startListening({
    actionCreator: b2bOrderActions.resetState,
    effect: (action, listenerApi) => {
        listenerApi.dispatch(b2bAdminFormActions.resetAdminFormState());
    },
});

/**
 * Keep pick state up-to-date every time a b2b is loaded or updated
 */
b2bAdminFormSliceMiddlewear.startListening({
    actionCreator: b2bOrderActions.b2bLoaded,
    effect: (action, listenerApi) => {
        listenerApi.dispatch(
            b2bAdminFormActions.picksLoaded(action.payload.pickItems)
        );
    },
});
b2bAdminFormSliceMiddlewear.startListening({
    actionCreator: b2bOrderActions.b2bOrderUpdated,
    effect: (action, listenerApi) => {
        listenerApi.dispatch(
            b2bAdminFormActions.picksLoaded(action.payload.pickItems)
        );
    },
});

/**
 * When picks are created they'll be saved to the b2bOrder
 * We need to clear pick state in the form so items don't appear twice
 */
b2bAdminFormSliceMiddlewear.startListening({
    actionCreator: b2bOrderActions.picksCreated,
    effect: (action, listenerApi) => {
        listenerApi.dispatch(b2bAdminFormActions.restPickState());
    },
});

export { b2bAdminFormSliceMiddlewear };

export default b2bAdminFormSlice.reducer;
