import { USER_ID } from "../../managers/ManagerConstants";
import { baseSlice } from "../base";

export const userApi = baseSlice.injectEndpoints({
    endpoints: (builder) => ({
        getUser: builder.query<User | null, void>({
            query: () => `/users/${localStorage.getItem(USER_ID)}`,
            transformResponse: (response: { data: User | null }) =>
                response.data,
            providesTags: (result) =>
                result
                    ? [{ type: "User" as const, id: result._id }]
                    : [{ type: "User" as const, id: "LIST" }],
        }),
        updateUser: builder.mutation<
            User | null,
            {
                user_id: string;
                user_update: UserUpdate;
            }
        >({
            query: ({ user_id, user_update }) => ({
                url: `/users/${user_id}`,
                method: "PUT",
                body: {
                    updates: user_update,
                },
            }),
            transformResponse: (response: { data: User | null }) =>
                response.data,
            invalidatesTags: (result, next, arg) => [
                { type: "User", id: arg.user_id },
            ],
        }),
    }),
});

export const { useGetUserQuery, useUpdateUserMutation } = userApi;
