import { combineReducers } from "redux";
import b2bOrderSlice from "./b2bOrderSlice";
import { b2bUiSlice } from "./uiSlice";
import adminB2BReducer from "../admin/state";
import { b2bSearchSlice } from "../shared/B2BSearch";

const b2bReducer = combineReducers({
    admin: adminB2BReducer,
    [b2bOrderSlice.name]: b2bOrderSlice.reducer,
    [b2bUiSlice.name]: b2bUiSlice.reducer,
    [b2bSearchSlice.name]: b2bSearchSlice.reducer,
});

export * from "./b2bOrderSlice";
export * from "./uiSlice";

export default b2bReducer;
