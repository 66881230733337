import { z } from "zod";
import { ZShipheroDimensions } from "./common";

export const ZShipheroCase = z.object({
  case_barcode: z.string().optional(),
  case_quantity: z.number().optional(),
  id: z.string().optional(),
  legacy_id: z.number().optional(),
});
export type ShipheroCase = z.infer<typeof ZShipheroCase>;

export const ZShipheroFbaInventory = z.object({
  id: z.string().optional(),
  legacy_id: z.number().optional(),
  marketplace_id: z.string().optional(),
  merchant_id: z.string().optional(),
  quantity: z.number().optional(),
});
export type ShipheroFbaInventory = z.infer<typeof ZShipheroFbaInventory>;

export const ZShipheroProductImage = z.object({
  position: z.number().optional(),
  src: z.string().optional(),
});
export type ShipheroProductImage = z.infer<typeof ZShipheroProductImage>;

export const ZShipheroKitComponent = z.object({
  account_id: z.string().optional(),
  id: z.string().optional(),
  legacy_id: z.number().optional(),
  quantity: z.number().optional(),
  sku: z.string().optional(),
});
export type ShipheroKitComponent = z.infer<typeof ZShipheroKitComponent>;

const ZShipheroProductVendor = z.object({
  price: z.string().optional(),
  vendor_id: z.string().optional(),
  vendor_sku: z.string().optional(),
});

const ZShipheroWarehouseProduct = z.object({
  account_id: z.string().optional(),
  active: z.boolean().optional(),
  allocated: z.number().optional(),
  available: z.number().optional(),
  backorder: z.number().optional(),
  created_at: z.string().datetime().optional(),
  custom: z.boolean().optional(),
  customs_value: z.string().optional(),
  id: z.string().optional(),
  inventory_bin: z.string().optional(),
  inventory_overstock_bin: z.string().optional(),
  legacy_id: z.number().optional(),
  non_sellable_quantity: z.number().optional(),
  on_hand: z.number().optional(),
  price: z.string().optional(),
  reorder_amount: z.number().optional(),
  reorder_level: z.number().optional(),
  replenishment_level: z.number().optional(),
  reserve_inventory: z.number().optional(),
  sell_ahead: z.number().optional(),
  sku: z.string().optional(),
  updated_at: z.string().datetime().optional(),
  value: z.string().optional(),
  value_currency: z.string().optional(),
  warehouse_id: z.string().optional(),
  warehouse_identifier: z.string().optional(),
  warehouse: z.string().optional(),
});
export type ShipheroWarehouseProduct = z.infer<
  typeof ZShipheroWarehouseProduct
>;

export const ZShipheroProduct = z.object({
  account_id: z.string().optional(),
  barcode: z.string().optional(),
  cases: z.array(ZShipheroCase).optional(),
  country_of_manufacture: z.string().optional(),
  created_at: z.string().datetime().optional(),
  customs_description: z.string().optional(),
  customs_value: z.string().optional(),
  dimensions: ZShipheroDimensions.optional(),
  dropship: z.boolean().optional(),
  fba_inventory: z.array(ZShipheroFbaInventory).optional(),
  final_sale: z.boolean().optional(),
  id: z.string().optional(),
  ignore_on_customs: z.boolean().optional(),
  ignore_on_invoice: z.boolean().optional(),
  images: z.array(ZShipheroProductImage).optional(),
  kit: z.boolean().optional(),
  kit_build: z.boolean().optional(),
  kit_components: z.array(ZShipheroKitComponent).optional(),
  large_thumbnail: z.string().optional(),
  legacy_id: z.number().optional(),
  name: z.string().optional(),
  needs_lot_tracking: z.boolean().optional(),
  needs_serial_number: z.boolean().optional(),
  no_air: z.boolean().optional(),
  not_owned: z.boolean().optional(),
  packer_notes: z.string().optional(),
  product_note: z.string().optional(),
  sku: z.string().optional(),
  tags: z.array(z.string()).optional(),
  tariff_code: z.string().optional(),
  thumbnail: z.string().optional(),
  updated_at: z.string().datetime().optional(),
  vendors: z.array(ZShipheroProductVendor).optional(),
  virtual: z.boolean().optional(),
  warehouse_products: z.array(ZShipheroWarehouseProduct).optional(),
});
export type ShipheroProduct = z.infer<typeof ZShipheroProduct>;
