import {
    B2BBox,
    B2BPickItem,
    B2BStatus,
    ShipheroDimensions,
} from "@librex-fulfillment/librex-zchemas";

export function calcTotalNumCartons(boxes?: B2BBox[]) {
    return boxes?.reduce((total, box) => total + box.quantity, 0) ?? 0;
}

export function calcTotalQuantityOfBaseItems(pickItems?: B2BPickItem[]) {
    return (
        pickItems?.reduce((total, pickItem) => total + pickItem.quantity, 0) ??
        0
    );
}

/**
 * Returns true if the prep status is an active-state status
 */
export const isOpenB2bOrder = (status?: B2BStatus) => {
    if (!status || status === "canceled" || status === "sent") {
        return false;
    }
    return true;
};

export function printDimensionsSize(dimensions: Partial<Dimensions>) {
    return `${dimensions.length ?? ""} x ${dimensions.width ?? ""} x ${
        dimensions.height ?? ""
    }`;
}

export function printDimensionsWeight(dimensions: ShipheroDimensions) {
    const hasUnit = Number.isNaN(Number.parseInt(dimensions.weight ?? "", 10));
    const unit = hasUnit ? "" : "lbs";
    return `${dimensions.weight}${unit}`;
}

export function getB2bStatusText(status: B2BStatus): string {
    switch (status) {
        case "canceled":
            return "This B2B order has been canceled";
        case "requested":
            return "We are processing your B2B order request";
        case "prepped":
            return "We are packing your shipment";
        case "sent":
            return "Your B2B order has been sent";
        default:
            return "";
    }
}
