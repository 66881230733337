import { useDispatch, useSelector } from "react-redux";
import { queryAndFilterActions, queryAndFilterSelectors } from "../slice";

const PreviousPage = ({
    disabled,
    ...props
}: Omit<React.HTMLProps<HTMLButtonElement>, "onClick" | "type">) => {
    const dispatch = useDispatch();
    const hasPreviousPage = useSelector(
        queryAndFilterSelectors.selectPaginationHasPreviousPage
    );

    const handleClick = () => {
        dispatch(queryAndFilterActions.previousPage());
    };
    return (
        <button
            disabled={disabled || !hasPreviousPage}
            type="button"
            onClick={handleClick}
            {...props}
        >
            {"<"}
        </button>
    );
};

export default PreviousPage;
