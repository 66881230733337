import { ZMongoId, ZTimestamps } from "common";
import { STRING_LONG_MAX_LENGTH, STRING_MEDIUM_MAX_LENGTH } from "consts";
import { z } from "zod";

export const ZB2BItem = z
  .object({
    fnsku_preapplied: z.boolean().default(false),
    item_id: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
    name: z.string().trim().max(STRING_LONG_MAX_LENGTH),
    quantity: z.number().int().positive(),
    sku: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
  })
  .extend(ZMongoId)
  .extend(ZTimestamps);
export type B2BItem = z.infer<typeof ZB2BItem>;

export const ZB2BItemCreateInput = ZB2BItem.omit({
  createdAt: true,
  updatedAt: true,
}).partial({
  _id: true,
  fnsku_preapplied: true,
});
export type B2BItemCreateInput = z.infer<typeof ZB2BItemCreateInput>;

export const ZB2BPickItem = z.object({
  item_id: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
  name: z.string().trim().max(STRING_LONG_MAX_LENGTH),
  quantity: z.number().int().positive(),
  sku: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
});
export type B2BPickItem = z.infer<typeof ZB2BPickItem>;
