import { isNonEmptyString, isStringOrUndefined } from "./utilities";

export function buildFullNameString(name?: FullName) {
    if (!name) return "";
    return `${name.first_name} ${name.last_name}`;
}

export function displayUser({
    firstName = "",
    lastName = "",
    business = "",
}: User) {
    return `${business} | ${firstName} ${lastName}`;
}

export const extractUsernames = (users: User[]) => {
    if (!Array.isArray(users)) {
        return [];
    }
    return users.map((user) => {
        const name = `${user.firstName ? user.firstName : ""} ${
            user.lastName ? user.lastName : ""
        }`;
        return {
            userId: user._id,
            name,
            business: user.business ? user.business : "",
        };
    });
};

export const getUserBusiness = (users: User[], userId: string) => {
    for (let i = 0; i < users.length; i++) {
        const user = users[i];
        if (user._id === userId) {
            return user.business ? user.business : "";
        }
    }
};

export const extractStreetAddressFromCustomerAddress = (
    customer_address: CustomerAddressI
): StreetAddressI => {
    const { email, first_name, last_name, phone, ...street_address } =
        customer_address;
    return street_address as StreetAddressI;
};

export const isSameFullName = (
    name_1?: FullName,
    name_2?: FullName
): boolean => {
    if (!name_1 && !name_2) return true;
    if (!name_1 || !name_2) return false;
    return (
        name_1?.first_name === name_2?.first_name &&
        name_1?.last_name === name_2?.last_name
    );
};

export const isSameStreetAddress = (
    street_address_1?: StreetAddressI,
    street_address_2?: StreetAddressI
): boolean => {
    if (!street_address_1 && !street_address_2) return true;
    if (!street_address_1 || !street_address_2) return false;
    return (
        street_address_1.city === street_address_2.city &&
        street_address_1.state === street_address_2.state &&
        street_address_1.street1 === street_address_2.street1 &&
        street_address_1.street2 === street_address_2.street2 &&
        street_address_1.zip === street_address_2.zip
    );
};

export const isSameCustomerAddress = (
    {
        first_name: first_name_1,
        last_name: last_name_1,
        email: email_1,
        phone: phone_1,
        ...street_address_1
    }: CustomerAddressI,
    {
        first_name: first_name_2,
        last_name: last_name_2,
        email: email_2,
        phone: phone_2,
        ...street_address_2
    }: CustomerAddressI
): boolean =>
    first_name_1 === first_name_2 &&
    last_name_1 === last_name_2 &&
    email_1 === email_2 &&
    phone_1 === phone_2 &&
    isSameStreetAddress(street_address_1, street_address_2);

export const isValidFullName = (name?: FullName) =>
    !!name && !!name.first_name && !!name.last_name;

/* Returns true if all required address fields have true falsy values */
export const isValidStreetAddress = ({
    city,
    country,
    province,
    state,
    street1,
    zip,
}: StreetAddressI) => {
    // All address must have a city
    if (isNonEmptyString(city) !== true) return "Enter a city";
    // Address must have a state or a province
    if (isNonEmptyString(state) !== true && isNonEmptyString(province) !== true)
        return "Enter a state or province";
    // US addresses don't use a province
    if (isNonEmptyString(state) === true && isNonEmptyString(province) === true)
        return "Enter either a state or a province";
    // Internation address require a country
    if (
        isNonEmptyString(province) === true &&
        isNonEmptyString(country) !== true
    )
        return "Enter a country";
    if (isNonEmptyString(street1) !== true) return "Enter Street Address 1";
    if (isNonEmptyString(zip) !== true) return "Enter a zip";

    return true;
};

export const isValidCustomerAddress = ({
    email,
    first_name,
    last_name,
    phone,
    ...street_address
}: CustomerAddressI): string | true => {
    if (isStringOrUndefined(email) !== true) return "Invalid email";
    if (isNonEmptyString(first_name) !== true) return "Invalid first name";
    if (isNonEmptyString(last_name) !== true) return "Invalid last name";
    if (isStringOrUndefined(phone) !== true) return "Invalid phone number";
    const is_valid_street_address = isValidStreetAddress(street_address);
    if (is_valid_street_address !== true) return is_valid_street_address;

    return true;
};
