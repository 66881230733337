import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
// Redux related imports
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import { ApolloProvider } from "@apollo/client";
import { librexGraphqlClient } from "api/graphql";

const App = lazy(() => import("./app/App"));

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");

ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
        <ApolloProvider client={librexGraphqlClient}>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </ApolloProvider>
    </React.StrictMode>
);
