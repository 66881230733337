import { z } from "zod";
import { ZAddress, ZCoordinates } from "common";
import { STRING_MEDIUM_MAX_LENGTH } from "consts";

export const ZWarehouse = z.object({
    _id: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
    active: z.boolean().default(true),
    address: ZAddress,
    coordinates: ZCoordinates.optional(),
    display: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
    identifier: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
    phone_number: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
    return_address: ZAddress.optional(),
});
export type Warehouse = z.infer<typeof ZWarehouse>;
