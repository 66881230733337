import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../../store";
import {
    isValidCollecitonProductInput,
    isValidIndex,
} from "../../../utilities";

export interface CollectionsState {
    data: CollectionI;
    errors: string[];
    showReviewModal: boolean;
}

const initialState = {
    data: {
        name: "New Collection",
        products: [],
    },
    errors: [],
    showReviewModal: false,
} as CollectionsState;

export const collectionsSlice = createSlice({
    name: "collections",
    initialState,
    reducers: {
        addCollectionFormItem: (
            state,
            { payload }: PayloadAction<CollectionProductI>
        ) => {
            if (
                state.data.products.some(
                    (product) => product._id === payload._id
                )
            )
                return;
            if (isValidCollecitonProductInput(payload) !== true) return;
            state.data.products.push(payload);
        },
        addCollectionFormError: (state, { payload }: PayloadAction<string>) => {
            state.errors.push(payload);
        },
        clearCollectionProducts: (state) => {
            state.data.products = [];
        },
        closeReviewCollectionModal: (state) => {
            state.showReviewModal = false;
        },
        dismissCollectionFormError: (state) => {
            state.errors.shift(); // Remove from the front of the array
        },
        openReviewCollectionModal: (state) => {
            state.showReviewModal = true;
        },
        removeCollectionFormItemByIdx: (
            state,
            { payload }: PayloadAction<number>
        ) => {
            if (isValidIndex(state.data.products, payload) !== true) return;
            state.data.products.splice(payload, 1);
        },
        setActiveCollection: (state, action: PayloadAction<CollectionI>) => {
            state.data = action.payload;
        },
        upsertProdctInActiveCollection: (
            state,
            action: PayloadAction<CollectionProductI>
        ) => {
            let found = false;
            const new_products = [];

            for (let i = 0; i < state.data.products.length; i++) {
                const product = state.data.products[i];
                if (product._id === action.payload._id) {
                    found = true;
                    new_products.push(action.payload);
                } else {
                    new_products.push(product);
                }
            }

            if (found === false) {
                new_products.push(action.payload);
            }

            state.data.products = new_products;
        },
    },
});

export const {
    addCollectionFormItem,
    addCollectionFormError,
    clearCollectionProducts,
    closeReviewCollectionModal,
    dismissCollectionFormError,
    openReviewCollectionModal,
    removeCollectionFormItemByIdx,
    setActiveCollection,
    upsertProdctInActiveCollection,
} = collectionsSlice.actions;

export const selectActiveCollection = (state: RootState) =>
    state.collections.data;

export const selectCollectionFormItems = (state: RootState) =>
    state.collections.data.products;

export const selectCollectionFormItemIds = createSelector(
    [selectCollectionFormItems],
    (products) => products.map((product) => product._id)
);

export const selectShowCollectionReviewModal = (state: RootState) =>
    state.collections.showReviewModal;

export const selectCollectionProductsLength = (state: RootState) =>
    state.collections.data.products.length;

export const selectFirstCollectionFormErrorMessage = (state: RootState) =>
    state.collections.errors[0];

export default collectionsSlice.reducer;
