import { useDebounceValue } from "hooks";
import { useCallback, useEffect, useId, useMemo, useReducer } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { AnyAction } from "redux";
import styles from "./SearchDropdown.module.scss";

export type SearchDropdownProps<T = any> = {
    data: T[];
    fieldsToDisplay: string[];
    onSelect: (item: T) => void;
    onSearchChange: (search: string) => void;
    placeholder?: string;
    selected?: T | null;
};

type SearchDropdownState = {
    query: string;
    isSearching: boolean;
};
const searchDropdownReducer = (
    state: SearchDropdownState,
    action: AnyAction
) => {
    switch (action.type) {
        case "setSearch":
            return { ...state, query: action.payload };
        case "setShowSearchResults":
            return { ...state, isSearching: action.payload };
        default:
            return state;
    }
};

function SearchDropdown({
    data,
    fieldsToDisplay,
    onSelect,
    onSearchChange,
    placeholder,
    selected,
}: SearchDropdownProps) {
    const getDisplayValue = useCallback(
        (item: Record<string, any>) =>
            fieldsToDisplay.map((field) => item[field]).join(" | "),
        [fieldsToDisplay]
    );

    const id = useId();
    const DROPDOWN_ID = useMemo(() => `search-dropdown-${id}`, [id]);
    const SEARCH_ID = useMemo(() => `search-${id}`, [id]);
    const [state, internalDispatch] = useReducer(searchDropdownReducer, {
        query: "",
        isSearching: false,
    });
    const debouncedSearch = useDebounceValue(state.query, 500);

    useEffect(() => {
        onSearchChange(debouncedSearch);
    }, [debouncedSearch, onSearchChange]);

    useEffect(() => {
        if (selected) {
            internalDispatch({
                type: "setSearch",
                payload: getDisplayValue(selected),
            });
        } else {
            internalDispatch({
                type: "setSearch",
                payload: "",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        internalDispatch({
            type: "setSearch",
            payload: e.target.value,
        });
    };
    const showSearch = () => {
        internalDispatch({
            type: "setShowSearchResults",
            payload: true,
        });
    };
    const hideSearch = () => {
        internalDispatch({
            type: "setShowSearchResults",
            payload: false,
        });
    };

    const handleSelectItem = (item: Record<string, any>) => () => {
        hideSearch();
        onSelect(item);
    };
    const handleKeyDown =
        (item: Record<string, any>) =>
        (event: React.KeyboardEvent<HTMLButtonElement>) => {
            if (event.code === "Enter" || event.code === " ") {
                hideSearch();
                handleSelectItem(item);
            }
        };

    return (
        <Dropdown show={state.isSearching} className={styles.searchDropdown}>
            <div className="d-flex">
                <input
                    className="form-control"
                    type="text"
                    name={SEARCH_ID}
                    id={SEARCH_ID}
                    onFocus={showSearch}
                    onChange={handleSearchChange}
                    placeholder={placeholder}
                    value={state.query}
                />
            </div>
            <Dropdown.Menu id={DROPDOWN_ID}>
                {data.map((item) => (
                    <li key={item._id} className={styles.dropdownItem}>
                        <Button
                            onClick={handleSelectItem(item)}
                            onKeyDown={handleKeyDown(item)}
                            variant=""
                        >
                            {getDisplayValue(item)}
                        </Button>
                    </li>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default SearchDropdown;
