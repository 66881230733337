import type { RootState } from "store";
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { B2BStatus } from "@librex-fulfillment/librex-zchemas";

export type B2BSearchState = {
    query: string;
    filter: {
        status?: string;
        company_id?: string;
    };
};

const initialState: B2BSearchState = {
    query: "",
    filter: {},
};

export const b2bSearchSlice = createSlice({
    name: "search" as const,
    initialState,
    reducers: {
        clearAllFilters: (state) => {
            state.filter = {};
        },
        clearFilterCompanyId: (state) => {
            state.filter.company_id = undefined;
        },
        clearFilterStatus: (state) => {
            state.filter.status = undefined;
        },
        clearQuery: (state) => {
            state.query = "";
        },
        reset: () => initialState,
        setCompanyId: (state, action: PayloadAction<string>) => {
            state.filter.company_id = action.payload;
        },
        setStatus: (state, action: PayloadAction<B2BStatus>) => {
            state.filter.status = action.payload;
        },
        setQuery: (state, action) => {
            state.query = action.payload;
        },
    },
});

export const b2bSearchReducer = b2bSearchSlice.reducer;

export const b2bSearchActions = b2bSearchSlice.actions;

export const b2bSearchSelectors = {
    selectQuery: (state: RootState) => state.b2b.search.query,
    selectFilter: (state: RootState) => state.b2b.search.filter,
    selectStatus: (state: RootState) => state.b2b.search.filter.status,
    selectCompanyId: (state: RootState) => state.b2b.search.filter.company_id,
    selectSearchQueryInput: createSelector(
        [
            (state: RootState) => ({
                query: state.b2b.search.query,
                filter: state.b2b.search.filter,
            }),
        ],
        (filter) => filter
    ),
};
