import qs from "qs";
import { baseSlice } from "../base";

export const invoiceUrl = (
    route: string = "",
    query: Record<string, any> = {}
): string => `/invoice${route}?${qs.stringify(query)}`;

export const invoiceApi = baseSlice.injectEndpoints({
    endpoints: (builder) => ({
        fetchInvoices: builder.query<
            ZohoInvoice[] | undefined,
            Partial<ZohoInvoice> | undefined
        >({
            query: (arg) => invoiceUrl("", arg),
            transformResponse: (response: LibrexAPIReply<ZohoInvoice[]>) =>
                response.data,
            providesTags: (result = []) => [
                ...result.map((invoice) => ({
                    type: "Invoice" as const,
                    id: invoice.invoice_number,
                })),
                { type: "Invoice" as const, id: "LIST" as const },
            ],
        }),
        fetchInvoice: builder.query<ZohoInvoice | undefined, string>({
            query: (invoice_id) => invoiceUrl(`/${invoice_id}`),
            transformResponse: (response: LibrexAPIReply<ZohoInvoice>) =>
                response.data,
            providesTags: (result) => [
                { type: "Invoice" as const, id: result?.invoice_number },
            ],
        }),
    }),
});

export const { useFetchInvoicesQuery, useFetchInvoiceQuery } = invoiceApi;
