import {
    INBOUND_SHIPMENT_TYPE,
    InboundShipmentCreateInput,
    InboundShipmentItemCreateInput,
    InboundShipmentType,
} from "@librex-fulfillment/librex-zchemas";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WAREHOUSE_ID__FREDERICK_MD } from "consts";
import { RootState } from "store";

type CreateInboundShipmentState = {
    inboundShipment: Partial<InboundShipmentCreateInput>;
};
const initialState: CreateInboundShipmentState = {
    inboundShipment: {
        company_id: "",
        dates: {
            estimated_arrival: undefined,
        },
        integrations: {},
        items: [],
        notes: "",
        traits: {
            amazon_removal: false,
            has_been_billed: false,
            num_cartons: 0,
            num_pallets: 0,
        },
        type: INBOUND_SHIPMENT_TYPE.FULFILLMENT,
        warehouse_id: WAREHOUSE_ID__FREDERICK_MD,
    },
};

export const createInboundShipmentSlice = createSlice({
    name: "create",
    initialState,
    reducers: {
        addItem(state, action: PayloadAction<InboundShipmentItemCreateInput>) {
            if (!state.inboundShipment.items) {
                state.inboundShipment.items = [];
            }
            state.inboundShipment.items.push(action.payload);
        },
        removeProductByIndex(state, action: PayloadAction<number>) {
            if (!state.inboundShipment.items) {
                return;
            }
            state.inboundShipment.items.splice(action.payload, 1);
        },
        reset() {
            return initialState;
        },
        setAmazonRemoval(state, action: PayloadAction<boolean>) {
            state.inboundShipment.traits!.amazon_removal = action.payload;
        },
        setCompanyId(state, action: PayloadAction<string>) {
            state.inboundShipment.company_id = action.payload;
        },
        setEstimatedArrival(state, action: PayloadAction<string>) {
            state.inboundShipment.dates!.estimated_arrival = action.payload;
        },
        setItems(
            state,
            action: PayloadAction<InboundShipmentItemCreateInput[]>
        ) {
            state.inboundShipment.items = action.payload;
        },
        setNotes(state, action: PayloadAction<string>) {
            state.inboundShipment.notes = action.payload;
        },
        setNumCartons(state, action: PayloadAction<number>) {
            state.inboundShipment.traits!.num_cartons = action.payload;
        },
        setShipmentType(state, action: PayloadAction<InboundShipmentType>) {
            state.inboundShipment.type = action.payload;
        },
        setWarehouseId(state, action: PayloadAction<string>) {
            state.inboundShipment.warehouse_id = action.payload;
        },
    },
});

export const createInboundShipmentActions = createInboundShipmentSlice.actions;
export const createInboundShipmentSelectors = {
    selectAmazonRemoval: (state: RootState) =>
        state.inbound_shipment.create.inboundShipment.traits!.amazon_removal,
    selectCompanyId: (state: RootState) =>
        state.inbound_shipment.create.inboundShipment.company_id,
    selectCreateShipmentInput: (state: RootState) =>
        state.inbound_shipment.create.inboundShipment,
    selectInboundShipment: (state: RootState) =>
        state.inbound_shipment.create.inboundShipment,
    selectItems: (state: RootState) =>
        state.inbound_shipment.create.inboundShipment.items,
    selectNumCartons: (state: RootState) =>
        state.inbound_shipment.create.inboundShipment.traits!.num_cartons,
    selectType: (state: RootState) =>
        state.inbound_shipment.create.inboundShipment.type,
    selectWarehouseId: (state: RootState) =>
        state.inbound_shipment.create.inboundShipment.warehouse_id,
};
