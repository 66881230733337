import z from "zod";

export const ZPaddingTypes = z.enum(["bubble-wrap", "eco-peanuts", "custom"]);
export type PaddingTypes = z.infer<typeof ZPaddingTypes>;
export const PADDING_TYPES = ZPaddingTypes.enum;

export const ZPackageType = z.enum([
  "",
  "box",
  "bubble-mailer",
  "poly-mailer",
  "custom",
]);
export type PackageType = z.infer<typeof ZPackageType>;
export const PACKAGE_TYPES = ZPackageType.enum;

export const ZReturnPreference = z.enum([
  "",
  "discard",
  "donate",
  "isolate",
  "restock",
  "repackage_restock",
  "custom_work_order",
]);
export type ReturnPreference = z.infer<typeof ZReturnPreference>;
export const RETURN_PREFERENCES = ZReturnPreference.enum;

export const ZLowStockNoticeType = z.enum(["product_stock"]);
export type LowStockNoticeType = z.infer<typeof ZLowStockNoticeType>;
export const LOW_STOCK_NOTICE_TYPES = ZLowStockNoticeType.enum;

export const ZPackagingPrefs = z.object({
  marketing_insert_required: z.boolean().optional(),
  package_type: ZPackageType.optional(),
  padding_type: ZPaddingTypes.array().optional(),
});
export type PackagingPrefs = z.infer<typeof ZPackagingPrefs>;

export const ZReturnPrefs = z.object({
  item_condition: z.object({
    sellable: ZReturnPreference.optional(),
    damaged: ZReturnPreference.optional(),
    saveable: ZReturnPreference.optional(),
  }),
});
export type ReturnPrefs = z.infer<typeof ZReturnPrefs>;

export const ZStockPrefs = z.object({
  type: ZLowStockNoticeType.default(LOW_STOCK_NOTICE_TYPES.product_stock),
  threshold: z.number().default(0),
});
export type StockPrefs = z.infer<typeof ZStockPrefs>;
