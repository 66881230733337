export const CANCEL_ORDER = "order/cancelOrder";
export const CREATE_ORDER = "order/createOrder";

export const FETCH_ADDRESS_COORDINATES = "order/fetchAddressCoordinates";
export const FETCH_FULFILLMENT_COST = "order/fetchFulfullmentCost";
export const FETCH_ITEMS_FOR_ORDER_FROM_SHIPHERO =
    "order/fetchItemsForOrderFromShiphero";
export const FETCH_ORDERS_BY_ORDER_NUMBER = "order/fetchOrdersByOrderNumber";
export const FETCH_ORDER_HISTORY = "order/fetchOrderHistory";
export const FETCH_ORDERS_FROM_SHIPHERO = "order/fetchOrdersFromShiphero";
