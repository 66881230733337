import { RootState } from "store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type AdminCreateUserSelectState = {
    show: boolean;
    selectedUser?: User;
};

const initialState: AdminCreateUserSelectState = {
    show: false,
};

export const adminCreateUserSelectSlice = createSlice({
    name: "adminCreateUserSelect",
    initialState,
    reducers: {
        hideAdminCreateModal(state) {
            state.show = false;
        },
        removeSelectedUser(state) {
            state.selectedUser = undefined;
        },
        showAdminCreateModal(state) {
            state.show = true;
        },
        setSelected(state, action: PayloadAction<User>) {
            state.selectedUser = action.payload;
            // Automatically close the modal when a user is selected
            if (action.payload) {
                state.show = false;
            }
        },
    },
});

export const adminCreateUserSelectActions = adminCreateUserSelectSlice.actions;
export const adminCreateUserSelectSelectors = {
    selectShow: (state: RootState) => state.adminCreateUserSelect.show,
    selectSelectedUser: (state: RootState) =>
        state.adminCreateUserSelect.selectedUser,
};
