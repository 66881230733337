import { combineReducers } from "redux";
import inventoryAuditSlice from "./stock/components/ProductHistory/state";
import stockReducer from "./stock/state";

const inventoryReducer = combineReducers({
    [inventoryAuditSlice.name]: inventoryAuditSlice.reducer,
    stock: stockReducer,
});

export default inventoryReducer;
