import React from "react";

export enum KeyboardKey {
    ENTER = "Enter",
    SPACE = " ",
}

/**
 * Returns true the event key is a key associated with submitting/selecting via keyboard
 */
export const isKeyboardSubmitEventAllowSpace = (event: React.KeyboardEvent) => {
    if (event.key === KeyboardKey.ENTER || event.key === KeyboardKey.SPACE)
        return true;
    return false;
};

/**
 * Returns true the event key is a key associated with submitting/selecting via keyboard
 */
export const isKeyboardSubmitEvent = (event: React.KeyboardEvent) => {
    if (event.key === KeyboardKey.ENTER) return true;
    return false;
};

export const preventFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
};

export const preventSubmitOnEnter = (event: React.KeyboardEvent) => {
    if (event.key === KeyboardKey.ENTER) event.preventDefault();
};

export const handleEnterSpaceSubmitKeyDown = (action: () => void) => {
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (["Enter", "Spacer"].includes(e.key)) {
            action();
        }
    };

    return handleKeyDown;
};
