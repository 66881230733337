import { isNonEmptyString } from "./utilities";
import { Product_FE } from "features/inventory/stock/types";

export function buildCollectionProductFromProduct({
    product,
    quantity = 0,
}: {
    allowed_warehouses_ids?: string[];
    product: Product_FE;
    quantity?: number;
}) {
    return {
        _id: product._id,
        name: product.name,
        sku: product.sku,
        quantity,
        quantity_in_stock: product.on_hand,
    } as CollectionProductI;
}

export const isValidCollecitonProductInput = (product: CollectionProductI) => {
    if (!product) return "Invalid product data";
    if (isNonEmptyString(product._id) !== true) return "Invalid product id";
    if (isNonEmptyString(product.name) !== true) return "Invalid product name";
    if (typeof product.quantity !== "number" || product.quantity < 0)
        return "Quantity must a number greater than 0";
    if (isNonEmptyString(product.sku) !== true) return "Invalid product sku";
    return true;
};

export const isValidCollecitonProduct = (product: CollectionProductI) => {
    if (!product) return "Invalid product data";
    if (isNonEmptyString(product._id) !== true) return "Invalid product id";
    if (isNonEmptyString(product.name) !== true) return "Invalid product name";
    if (typeof product.quantity !== "number" || product.quantity < 1)
        return "Quantity must a number greater than 0";
    if (isNonEmptyString(product.sku) !== true) return "Invalid product sku";
    return true;
};
