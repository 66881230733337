export function OrderStatusProcessingIcon() {
    return (
        <svg
            width="45"
            height="45"
            viewBox="0 0 45 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 22.5C0 10.0736 10.0736 0 22.5 0C34.9264 0 45 10.0736 45 22.5C45 34.9264 34.9264 45 22.5 45C10.0736 45 0 34.9264 0 22.5Z"
                fill="white"
            />
            <path
                d="M27.5 26.5C27.2239 26.5 27 26.7239 27 27C27 27.2761 27.2239 27.5 27.5 27.5V26.5ZM32.861 13.75C32.5849 13.7473 32.3588 13.9689 32.3561 14.245L32.3114 18.7448C32.3086 19.0209 32.5303 19.247 32.8064 19.2498C33.0825 19.2525 33.3086 19.0309 33.3113 18.7547L33.3511 14.7549L37.3509 14.7947C37.627 14.7974 37.8531 14.5758 37.8558 14.2997C37.8586 14.0236 37.6369 13.7975 37.3608 13.7947L32.861 13.75ZM34.5 19.5C34.5 23.366 31.366 26.5 27.5 26.5V27.5C31.9183 27.5 35.5 23.9183 35.5 19.5H34.5ZM32.499 14.6C33.7373 15.8632 34.5 17.592 34.5 19.5H35.5C35.5 17.3198 34.6272 15.3425 33.2131 13.9L32.499 14.6Z"
                fill="currentColor"
            />
            <path
                d="M27.5 12.5C27.7761 12.5 28 12.2761 28 12C28 11.7239 27.7761 11.5 27.5 11.5L27.5 12.5ZM22.139 25.25C22.4151 25.2527 22.6412 25.0311 22.6439 24.755L22.6886 20.2552C22.6914 19.9791 22.4697 19.753 22.1936 19.7502C21.9175 19.7475 21.6914 19.9691 21.6887 20.2453L21.6489 24.2451L17.6491 24.2053C17.373 24.2026 17.1469 24.4242 17.1442 24.7003C17.1414 24.9764 17.3631 25.2025 17.6392 25.2053L22.139 25.25ZM20.5 19.5C20.5 15.634 23.634 12.5 27.5 12.5L27.5 11.5C23.0817 11.5 19.5 15.0817 19.5 19.5L20.5 19.5ZM22.501 24.4C21.2627 23.1368 20.5 21.408 20.5 19.5L19.5 19.5C19.5 21.6802 20.3728 23.6575 21.7869 25.1L22.501 24.4Z"
                fill="currentColor"
            />
            <rect
                x="9"
                y="21"
                width="16.5"
                height="11"
                rx="1.5"
                fill="#E5E9F2"
                stroke="currentColor"
            />
            <line
                x1="11.5"
                y1="23.5"
                x2="21"
                y2="23.5"
                stroke="currentColor"
                strokeLinecap="round"
            />
            <line
                x1="11.5"
                y1="26.5"
                x2="12.5"
                y2="26.5"
                stroke="currentColor"
                strokeLinecap="round"
            />
            <line
                x1="11.5"
                y1="29"
                x2="12.5"
                y2="29"
                stroke="currentColor"
                strokeLinecap="round"
            />
            <line
                x1="14.5"
                y1="26.5"
                x2="23"
                y2="26.5"
                stroke="currentColor"
                strokeLinecap="round"
            />
            <line
                x1="14.5"
                y1="29"
                x2="23"
                y2="29"
                stroke="currentColor"
                strokeLinecap="round"
            />
            <circle cx="22.5" cy="22.5" r="22" stroke="currentColor" />
        </svg>
    );
}

export function OrderIsGiftIcon(): any {
    return (
        <svg
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="9.21436"
                y="16.6367"
                width="16.8571"
                height="9.71429"
                rx="1.5"
                stroke="currentColor"
            />
            <rect
                x="8.5"
                y="13.0654"
                width="18.2857"
                height="3.28571"
                rx="1.5"
                stroke="currentColor"
            />
            <path d="M17.6428 26.1374V13.2803" stroke="currentColor" />
            <path
                d="M21.5855 9.95026C21.9895 10.65 21.7497 11.5448 21.05 11.9488C20.904 12.033 20.6208 12.1128 20.2151 12.169C19.826 12.223 19.3807 12.2485 18.9533 12.2575C18.6166 12.2645 18.2969 12.2613 18.0332 12.2548C18.1405 11.9715 18.2798 11.621 18.4396 11.2595C18.6272 10.8352 18.8381 10.407 19.0532 10.0578C19.2815 9.68738 19.4693 9.48272 19.587 9.41476C20.2867 9.01076 21.1815 9.25051 21.5855 9.95026Z"
                stroke="currentColor"
            />
            <path
                d="M13.7002 9.95026C13.2962 10.65 13.5359 11.5448 14.2357 11.9488C14.3816 12.033 14.6649 12.1128 15.0705 12.169C15.4597 12.223 15.905 12.2485 16.3323 12.2575C16.669 12.2645 16.9887 12.2613 17.2525 12.2548C17.1452 11.9715 17.0058 11.621 16.846 11.2595C16.6584 10.8352 16.4476 10.407 16.2324 10.0578C16.0041 9.68738 15.8164 9.48272 15.6987 9.41476C14.9989 9.01076 14.1042 9.25051 13.7002 9.95026Z"
                stroke="currentColor"
            />
            <rect
                x="0.5"
                y="0.5"
                width="34"
                height="34"
                rx="17"
                stroke="currentColor"
            />
        </svg>
    );
}

export function RequireSignatureIcon(): any {
    return (
        <svg
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M8 12L15 19" stroke="currentColor" strokeLinecap="round" />
            <path d="M15 12L8 19" stroke="currentColor" strokeLinecap="round" />
            <line
                x1="7.5"
                y1="22.5"
                x2="29.5"
                y2="22.5"
                stroke="currentColor"
                strokeLinecap="round"
            />
            <rect
                x="0.5"
                y="0.5"
                width="34"
                height="34"
                rx="17"
                stroke="currentColor"
            />
        </svg>
    );
}

export function InsureOrderIcon(): any {
    return (
        <svg
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.9679 8.11513C17.1808 7.93976 17.3604 7.78726 17.5 7.66667C17.6396 7.78726 17.8192 7.93976 18.0321 8.11513C18.5712 8.55905 19.3268 9.1518 20.1921 9.74559C21.0558 10.3384 22.038 10.9386 23.0295 11.3922C23.8426 11.7642 24.6885 12.0501 25.4957 12.1383C25.3908 17.8996 23.3703 21.4756 21.3834 23.6184C20.3588 24.7233 19.3357 25.4549 18.5725 25.9088C18.1911 26.1357 17.8752 26.2928 17.6576 26.3922C17.5972 26.4197 17.5445 26.4428 17.5 26.4617C17.4555 26.4428 17.4028 26.4197 17.3424 26.3922C17.1248 26.2928 16.8089 26.1357 16.4275 25.9088C15.6643 25.4549 14.6412 24.7233 13.6166 23.6184C11.6297 21.4756 9.60917 17.8996 9.50427 12.1383C10.3115 12.0501 11.1574 11.7642 11.9705 11.3922C12.962 10.9386 13.9442 10.3384 14.8079 9.74559C15.6732 9.1518 16.4288 8.55905 16.9679 8.11513Z"
                stroke="currentColor"
            />
            <path
                d="M15 18L17 20L20 15"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="0.5"
                y="0.5"
                width="34"
                height="34"
                rx="17"
                stroke="currentColor"
            />
        </svg>
    );
}
