import { z } from "zod";
import { ZMongoId, ZTimestamps } from "common";
import { ZShipheroDimensions } from "shiphero";
import { STRING_LONG_MAX_LENGTH, STRING_MEDIUM_MAX_LENGTH } from "consts";

export const ZB2BBoxItem = z
  .object({
    item_id: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
    name: z.string().trim().max(STRING_LONG_MAX_LENGTH),
    quantity: z.number().int().positive(),
    sku: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
  })
  .extend(ZMongoId);
export type B2BBoxItem = z.infer<typeof ZB2BBoxItem>;

export const ZB2BBoxItemCreateInput = ZB2BBoxItem.partial({
  _id: true,
});
export type B2BBoxItemCreateInput = z.infer<typeof ZB2BBoxItemCreateInput>;

export const ZB2BBox = z
  .object({
    dimensions: ZShipheroDimensions,
    items: z
      .array(ZB2BBoxItem)
      .min(1, { message: "At least one item is required" }),
    quantity: z.number().int().positive(),
  })
  .extend(ZMongoId)
  .extend(ZTimestamps);
export type B2BBox = z.infer<typeof ZB2BBox>;

export const ZB2BBoxCreateInput = ZB2BBox.omit({
  createdAt: true,
  updatedAt: true,
}).partial({
  _id: true,
});
export type B2BBoxCreateInput = z.infer<typeof ZB2BBoxCreateInput>;
