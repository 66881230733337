import type { RootState } from "store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProductSearchVariables } from "../graphql-documents/PRODUCT_SEARCH";
import { Product } from "@librex-fulfillment/librex-zchemas";

type StockListState = ProductSearchVariables;

export const listSlice = createSlice({
    name: "list",
    initialState: {
        search: "",
        filter: {},
    } as StockListState,
    reducers: {
        setSearchQueryInput: (state, action) => {
            state.search = action.payload;
        },
        setFilterField: (state, action: PayloadAction<Partial<Product>>) => {
            state.filter = {
                ...state.filter,
                ...action.payload,
            };
        },
    },
});

export const stockListActions = listSlice.actions;

export const stockListSelectors = {
    selectSearch: (state: RootState) => state.inventory.stock.list.search,
    selectListQueryInput: (state: RootState) => state.inventory.stock.list,
};
