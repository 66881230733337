/* Reducer actions */
export const DELETE_USER = "user/deleteUser";
export const SET_USER = "user/setUser";
export const UPDATE_USER = "user/updateUser";

/* Thunk actions */
export const FETCH_USER = "user/fetchUser";
export const FETCH_MAPBOX_TOKEN = "user/fetchMapboxToken";
export const LOGIN = "user/login";
export const LOGOUT = "user/logout";
