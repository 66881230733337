import { z } from "zod";

export const ZProductLocation = z.object({
  company_id: z.string().trim(),
  location_id: z.string().trim(),
  location_name: z.string().trim(),
  quantity: z.number(),
  sku: z.string().trim(),
  warehouse_id: z.string().trim(),
});
export type ProductLocation = z.infer<typeof ZProductLocation>;
