import type { RootState } from "store";
import {
    createListenerMiddleware,
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";
import { b2bOrderActions } from "./b2bOrderSlice";

export type B2BUIState = {
    expanded: Record<string, boolean>;
};

const sliceName = "ui" as const;

const initialState: B2BUIState = {
    expanded: {},
};

export const b2bUiSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        collapseLine: (state, { payload }: PayloadAction<string>) => {
            state.expanded[payload] = false;
        },
        expandLine: (state, { payload }: PayloadAction<string>) => {
            state.expanded[payload] = true;
        },
        reset: () => ({ ...initialState }),
    },
});

export const b2bUISliceName = b2bUiSlice.name;
export const b2bUiActions = b2bUiSlice.actions;

export const b2bUiSelectors = {
    selectIsLineExpanded: (index: string) => (state: RootState) =>
        state.b2b.ui.expanded[index] ?? false,
};

export const b2bUiSliceMiddlewear = createListenerMiddleware();
b2bUiSliceMiddlewear.startListening({
    actionCreator: b2bOrderActions.resetState,
    effect: (action, listenerApi) => {
        listenerApi.dispatch(b2bUiActions.reset());
    },
});

export default b2bUiSlice.reducer;
