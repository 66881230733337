import { ZMongoId, ZTimestamps } from "common";
import { STRING_LONG_MAX_LENGTH, STRING_MEDIUM_MAX_LENGTH } from "consts";
import { ZShipheroDimensions } from "shiphero";
import { z } from "zod";
import { ZS3Document } from "s3";
import { ZB2BItem, ZB2BItemCreateInput, ZB2BPickItem } from "./items";
import { ZB2BBox } from "./boxes";

export const ZB2BDates = z.object({
  cancelled_date: z.string().datetime().optional(),
  prepped_date: z.string().datetime().optional(),
  sent_date: z.string().datetime().optional(),
});
export type B2BDates = z.infer<typeof ZB2BDates>;

export const ZB2BDocumentType = z.enum([
  "fnsku",
  "shipping_label",
  "pallet_label",
]);
export const B2B_DOCUMENT_TYPE = ZB2BDocumentType.enum;
export const B2B_DOCUMENT_TYPES = Object.values(B2B_DOCUMENT_TYPE);
export type B2BDocumentType = z.infer<typeof ZB2BDocumentType>;

export const ZB2BFileType = z.enum(["pdf"]);
export const B2B_FILE_TYPE = ZB2BFileType.enum;
export const B2B_FILE_TYPES = Object.values(B2B_FILE_TYPE);
export type B2BFileType = z.infer<typeof ZB2BFileType>;

export const ZB2BDocument = ZS3Document.extend({
  document_type: ZB2BDocumentType,
  reference_id: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
  file_type: ZB2BFileType,
});
export type B2BDocument = z.infer<typeof ZB2BDocument>;

export const ZB2BStatus = z.enum([
  "canceled",
  "error",
  "prepped",
  "processing",
  "requested",
  "sent",
]);
export const B2B_STATUS = ZB2BStatus.enum;
export const B2B_STATUSES = Object.values(B2B_STATUS);
export type B2BStatus = z.infer<typeof ZB2BStatus>;

export const ZB2BTracking = z.object({
  carrier: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH).optional(),
  tracking_number: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH).optional(),
});
export type B2BTracking = z.infer<typeof ZB2BTracking>;

export const ZB2BPallet = z.object({
  dimensions: ZShipheroDimensions,
  quantity: z.number().int().positive(),
});
export type B2BPallet = z.infer<typeof ZB2BPallet>;

export const ZB2BOrder = z
  .object({
    active: z.boolean().default(true),
    boxes: z.array(ZB2BBox).default([]),
    company_id: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
    dates: ZB2BDates.default({}),
    has_been_billed: z.boolean().default(false),
    items: z.array(ZB2BItem).default([]),
    name: z.string().trim().max(STRING_LONG_MAX_LENGTH).default(""),
    notes: z.string().trim().max(STRING_LONG_MAX_LENGTH).default(""),
    status: ZB2BStatus.default(B2B_STATUS.requested),
    tracking: z.array(ZB2BTracking).default([]),
    pallets: z.array(ZB2BPallet).default([]),
    pickItems: z.array(ZB2BPickItem).default([]),
    warehouse_id: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
  })
  .extend(ZMongoId)
  .extend(ZTimestamps);
export type B2BOrder = z.infer<typeof ZB2BOrder>;

export const ZB2BOrderCreateInput = ZB2BOrder.omit({
  active: true,
  boxes: true,
  createdAt: true,
  dates: true,
  has_been_billed: true,
  history: true,
  items: true,
  pallets: true,
  pickItems: true,
  status: true,
  updatedAt: true,
})
  .partial({
    _id: true,
    name: true,
    notes: true,
    tracking: true,
  })
  .extend({
    items: z.array(ZB2BItemCreateInput).default([]),
  });
export type B2BOrderCreateInput = z.infer<typeof ZB2BOrderCreateInput>;
