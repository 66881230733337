import {
    ShipheroOrder,
    ShipheroOrderLineItem,
} from "@librex-fulfillment/librex-zchemas";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { getAmountFromNumber, getNumberFromAmount } from "utilities/money";
import { v4 } from "uuid";

type CreateOrderState = {
    order: Partial<ShipheroOrder>;
    search: string;
    addingProducts: boolean;
};
const getInitialState = (): CreateOrderState => ({
    order: {
        partner_order_id: v4(),
    },
    search: "",
    addingProducts: true,
});

const CreateOrderSlice = createSlice({
    name: "CreateOrder",
    initialState: getInitialState(),
    reducers: {
        addProduct: (state, action: PayloadAction<ShipheroOrderLineItem>) => {
            if (!Array.isArray(state.order.line_items)) {
                state.order.line_items = [];
            }
            state.order.line_items.push(action.payload);
            // We need to update subtotal when adding line items
            const price = parseFloat(action.payload.price.replace("$", ""));
            if (!Number.isNaN(price)) {
                const subtotal = parseFloat(state.order.subtotal ?? "0");
                state.order.subtotal = getAmountFromNumber(subtotal + price);
            }
        },
        finishedAddingProducts: (state) => {
            state.addingProducts = false;
        },
        removeProduct: (state, action: PayloadAction<string>) => {
            if (!Array.isArray(state.order.line_items)) {
                return;
            }
            let foundItem: ShipheroLineItem | undefined;
            state.order.line_items = state.order.line_items.filter((item) => {
                if (item.sku === action.payload) {
                    foundItem = item;
                }
                return item.sku !== action.payload;
            });

            // We need to update subtotal when removing line items
            if (foundItem?.price) {
                const price = getNumberFromAmount(foundItem.price);
                if (Number.isNaN(price)) {
                    return;
                }
                const subtotal = getNumberFromAmount(
                    state.order.subtotal ?? "0"
                );
                const quantity = foundItem?.quantity ?? 1;

                const removalTotal = price * quantity;

                state.order.subtotal = getAmountFromNumber(
                    subtotal - removalTotal
                );
            }
        },
        reset: () => getInitialState(),
        setOrderState: (
            state,
            action: PayloadAction<Partial<ShipheroOrder>>
        ) => {
            state.order = { ...state.order, ...action.payload };
        },
        setSearch: (state, action: PayloadAction<string>) => {
            state.search = action.payload;
        },
        startAddingProducts: (state) => {
            state.addingProducts = true;
        },
        updateLineItemQuantity: (
            state,
            {
                payload: { sku, quantity },
            }: PayloadAction<{ sku: string; quantity: number }>
        ) => {
            if (!Array.isArray(state.order.line_items)) {
                return;
            }
            let foundItem: ShipheroLineItem | undefined;
            let oldQuantity = 0;
            state.order.line_items = state.order.line_items.map((item) => {
                if (item.sku === sku) {
                    // Make a deep value so the value is not updated when the line item is updated
                    oldQuantity = JSON.parse(JSON.stringify(item.quantity));
                    foundItem = item;
                    item.quantity = quantity;
                }
                return item;
            });

            // We need to update subtotal when updating line item quantity
            if (foundItem?.price) {
                const price = getNumberFromAmount(foundItem.price);
                const oldTotal = price * oldQuantity;
                const newTotal = price * quantity;
                const subtotal = getNumberFromAmount(
                    state.order.subtotal ?? "0"
                );
                state.order.subtotal = getAmountFromNumber(
                    subtotal - oldTotal + newTotal
                );
            }
        },
        updateShippingAddress: (
            state,
            action: PayloadAction<Partial<ShipheroOrder["shipping_address"]>>
        ) => {
            if (!state.order.shipping_address) {
                state.order.shipping_address = {
                    address1: "",
                    city: "",
                    zip: "",
                };
            }
            state.order.shipping_address = {
                ...state.order.shipping_address,
                ...action.payload,
            };
        },
    },
});

export const createOrderActions = CreateOrderSlice.actions;

export const createOrderSelectors = {
    selectBillingAddress: (state: RootState) =>
        state.order.CreateOrder.order.billing_address,
    selectHasInsurance: (state: RootState) =>
        state.order.CreateOrder.order.insurance ?? false,
    selectIsAddingProducts: (state: RootState) =>
        state.order.CreateOrder.addingProducts,
    selectIsGift: (state: RootState) =>
        state.order.CreateOrder.order.gift_invoice ?? false,
    selectLineItems: (state: RootState) =>
        state.order.CreateOrder.order.line_items ?? [],
    selectOrder: (state: RootState) => state.order.CreateOrder.order,
    selectOrderNumber: (state: RootState) =>
        state.order.CreateOrder.order.order_number,
    selectRequireSignature: (state: RootState) =>
        state.order.CreateOrder.order.require_signature ?? false,
    selectSearch: (state: RootState) => state.order.CreateOrder.search,
    selectShippingAddress: (state: RootState) =>
        state.order.CreateOrder.order.shipping_address,
    selectSubtotal: (state: RootState) =>
        state.order.CreateOrder.order.subtotal,
    /** Derived selectors  */
    selectLineItemSkus: (state: RootState) =>
        state.order.CreateOrder.order.line_items?.map((item) => item.sku) ?? [],
};

export default CreateOrderSlice;
