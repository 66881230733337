import { ZTimestamps } from "common";
import { z } from "zod";

export const ZInventoryAuditTypes = z.enum([
  "B2B",
  "INBOUND_SHIPMENT",
  "MANUAL_ADJUSTMENT",
  "ORDER_SHIPMENT",
  "TRANSFER",
  "RETURN",
]);
export const INVENTORY_AUDIT_TYPES = ZInventoryAuditTypes.enum;
export type InventoryAuditTypes = z.infer<typeof ZInventoryAuditTypes>;

export const ZInventoryAudit = z
  .object({
    _id: z.string(),
    change_in_quantity: z.number(),
    company_id: z.string(),
    location_id: z.string().optional(),
    message: z.string().max(100, {
      message: "Message too long. Max length is 100 characters",
    }),
    name: z.string().optional(),
    previous_quantity: z.number(),
    reference_id: z.string().optional(),
    sku: z.string(),
    type: ZInventoryAuditTypes,
    warehouse_id: z.string(),
  })
  .extend(ZTimestamps);
export type InventoryAudit = z.infer<typeof ZInventoryAudit>;

export const ZInventoryAuditCreateInput = ZInventoryAudit.partial({
  previous_quantity: true,
}).omit({
  _id: true,
  createdAt: true,
  updatedAt: true,
});
export type InventoryAuditCreateInput = z.infer<
  typeof ZInventoryAuditCreateInput
>;
