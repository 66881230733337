import { baseSlice } from "api/base";
import QueryString from "qs";

export const shippingQuery = (
    path: string = "/",
    query: Record<string, any> = {}
) =>
    `/shipping${path}${QueryString.stringify(query, { addQueryPrefix: true })}`;

type ShippingTrackingQueryInput = {
    carrier: string;
    tracking_number: string;
};

export const shippingApi = baseSlice.injectEndpoints({
    endpoints: (builder) => ({
        getShipmentTracking: builder.query<
            ShippoOrderHistory[] | null,
            ShippingTrackingQueryInput
        >({
            query: (query) => shippingQuery(`/tracking`, query),
            transformResponse: (response: {
                data: ShippoOrderHistory[] | null;
            }) => response.data,
            providesTags: (results) =>
                Array.isArray(results)
                    ? [
                          ...results.map(({ object_id }) => ({
                              type: "ShippoTracking" as const,
                              id: object_id,
                          })),
                      ]
                    : [],
        }),
    }),
});

export const { useGetShipmentTrackingQuery } = shippingApi;
