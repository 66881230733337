import { useAppDispatch, useAppSelector, useDebounceCallback } from "hooks";
import {
    ChangeEventHandler,
    useCallback,
    useId,
    useMemo,
    useState,
} from "react";
import { queryAndFilterActions, queryAndFilterSelectors } from "../slice";
import { Form } from "react-bootstrap";
import styles from "./Query.module.scss";

type QueryProps = {
    placeholder?: string;
};

function Query({ placeholder = "Search" }: QueryProps) {
    const id = useId();
    const inputId = useMemo(() => `search-${id}`, [id]);

    const query = useAppSelector(queryAndFilterSelectors.selectQuery);

    const [internalQuery, setInternalQuery] = useState(query);

    const dispatch = useAppDispatch();
    useDebounceCallback(
        useCallback(() => {
            dispatch(queryAndFilterActions.setQuery(internalQuery));
        }, [dispatch, internalQuery])
    );

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setInternalQuery(event.target.value);
    };

    return (
        <Form.Label htmlFor={inputId} className={styles.label}>
            <span className="visually-hidden">Search</span>
            <Form.Control
                id={inputId}
                type="text"
                value={internalQuery}
                onChange={handleChange}
                placeholder={placeholder}
                className={styles.query}
            />
        </Form.Label>
    );
}

export default Query;
