/**
 * Formats a date to DD MMM YYYY where MMM is the 3 character abreviation for a month.
 * Ex. 20 Oct 2021
 * @param {string} old_date String representing a date valid in the js Date object
 * @returns {string} The formatted date. 'Not available' if invalid input supplied.
 */
// Array of 3 month abbreviates
const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];
export const formatDateDDMMYYYY = (old_date: any) => {
    if (!old_date) {
        return "Not available";
    }
    const date = new Date(old_date);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    // Check for invalid dates
    if (Number.isNaN(date.getDate())) {
        return "Not available";
    }
    return `${date.getDate()} ${MONTHS[date.getMonth()]} ${date.getFullYear()}`;
};

/**
 * Utility function for determining if a supplied date is today
 */
export function isToday(date: Date) {
    const today = new Date();
    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    );
}

/**
 * Builds a string to display a 12 hour time string with AM/PM
 */
export function buildTimeString(date: Date) {
    let time_of_day = "AM";
    let hours = date.getHours();
    const minutes =
        date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    if (hours > 12) {
        hours -= 12;
        time_of_day = "PM";
    }
    return `${hours}:${minutes} ${time_of_day}`;
}

/**
 * Renders the date and time of an event
 * If the date is today "Today" is returned
 * Otherwise the format is MM-DD-YYYY, HH:MM AM/PM
 */
export function getEventTime(time: Date | string): string {
    // Make a date object from the time, verify the date is valid
    const date = new Date(time);
    if (!date || Number.isNaN(date.getTime())) {
        return "";
    }
    const time_string = buildTimeString(date);
    const day_string = isToday(date)
        ? "Today"
        : `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
    return `${day_string}, ${time_string}`;
}

export function slashSeperatedDate(time: Date | string): string {
    const date = new Date(time);

    const date_num = date.getDate();
    const day = date_num < 10 ? `0${date_num}` : `${date_num}`;
    const month_num = date.getMonth() + 1;
    const month = month_num < 10 ? `0${month_num}` : month_num;
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
}
