import { z } from "zod";
import { STRING_MEDIUM_MAX_LENGTH } from "./consts";

export const ZAddress = z.object({
  street1: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
  street2: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH).optional(),
  city: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
  state: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
  province: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH).optional(),
  country: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH).optional(),
  zip: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
});
export type Address = z.infer<typeof ZAddress>;

export const ZCoordinates = z.object({
  latitude: z.number(),
  longitude: z.number(),
});
export type Coordinates = z.infer<typeof ZCoordinates>;

// ZTimestamps is a Zod object schema that defines createdAt and updatedAt
// And is intended ot be used in .extend() to add createdAt and updatedAt
// Which is why we do not use .object() here
export const ZTimestamps = {
  createdAt: z.string(),
  updatedAt: z.string(),
};

export const ZMongoId = {
  _id: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
};
