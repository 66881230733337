import { combineReducers } from "redux";
import { queryAndFilterSlice } from "./QueryAndFilter";
import { dirtyStateSlice } from "./DirtyState";

const modulesReducer = combineReducers({
    [dirtyStateSlice.name]: dirtyStateSlice.reducer,
    [queryAndFilterSlice.name]: queryAndFilterSlice.reducer,
});

export default modulesReducer;
