import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";

type DirtyStateState = {
    dirty: boolean;
    show: boolean;
};

const initialState: DirtyStateState = {
    dirty: false,
    show: false,
};

export const dirtyStateSlice = createSlice({
    name: "dirtyState",
    initialState,
    reducers: {
        hideDirtyStateModal: (state) => {
            state.show = false;
        },
        markClean: (state) => {
            state.dirty = false;
        },
        markDirty: (state) => {
            state.dirty = true;
        },
        showDirtyStateModal: (state) => {
            state.show = true;
        },
    },
});

export const dirtyStateActions = dirtyStateSlice.actions;

const selectIsDirty = (state: RootState) => state.modules.dirtyState.dirty;
const selectShowDirtyStateModal = (state: RootState) =>
    state.modules.dirtyState.show;

export const dirtyStateSelectors = {
    selectIsDirty,
    selectShowDirtyStateModal,
};
