import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

export interface AppState {
    active_page: string;
    feature: string;
    sub_feature: string;
    view_mode: "mobile" | "desktop";
}
const initialState = {
    active_page: "",
    feature: "",
    sub_feature: "",
    view_mode: "desktop",
} as AppState;

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setActivePage: (state, { payload }) => {
            if (typeof payload === "string") state.active_page = payload;
        },
        setFeature: (state, action) => {
            state.feature = action.payload;
        },
        setSubFeature: (state, action) => {
            state.sub_feature = action.payload;
        },
        setViewMode: (state, action) => {
            state.view_mode = action.payload;
        },
    },
});

export const appActions = appSlice.actions;

export const appSelectors = {
    /* Explicit selectors */
    selectActivePage: (state: RootState) => state.app.active_page,
    selectFeature: (state: RootState) => state.app.feature,
    selectSubFeature: (state: RootState) => state.app.sub_feature,
    selectViewMode: (state: RootState) => state.app.view_mode,
    /* Derived Selectors */
    selectIsViewModeMobile: (state: RootState) =>
        state.app.view_mode === "mobile",
};
