export const moneyDisplay = (number: number) => {
    const numArr = `${number}`.split(".");
    const dollarMoney = numArr[0];
    let formatted = "";
    if (dollarMoney.length <= 3) {
        formatted = dollarMoney;
    } else {
        const first = dollarMoney.length % 3;

        if (first !== 0) {
            formatted += `${dollarMoney.slice(0, first)},`;
        }

        for (let i = first; i < dollarMoney.length + 1; i += 3) {
            formatted += dollarMoney.slice(i, i + 3);
            if (i + 3 < dollarMoney.length) {
                formatted += ",";
            }
        }
    }
    let centsMoney = numArr[1];
    if (!centsMoney) {
        centsMoney = "00";
    } else if (centsMoney.length < 2) {
        centsMoney += "0";
    }
    formatted = `$${formatted}.${centsMoney}`;
    return formatted;
};

export const validateQuantityInput = (value: string) => {
    const value_as_num = parseInt(value, 10);
    return Number.isNaN(value_as_num) === false && value_as_num > 0;
};
