import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { inboundShipmentActions } from "../state/inboundShipment";
import { dirtyStateActions, dirtyStateSelectors } from "modules/DirtyState";
import { inboundShipmentReceivingActions } from "../state/receiving";
import { RootState } from "store";

export const inboundMiddleware = createListenerMiddleware();
inboundMiddleware.startListening({
    matcher: isAnyOf(
        inboundShipmentActions.setInboundShipment,
        inboundShipmentActions.resetState
    ),
    effect: (action, listenerApi) => {
        const isDirty = dirtyStateSelectors.selectIsDirty(
            listenerApi.getState() as RootState
        );
        if (!isDirty) return;
        listenerApi.dispatch(dirtyStateActions.markClean());
    },
});

inboundMiddleware.startListening({
    matcher: isAnyOf(
        inboundShipmentActions.receiveItem,
        inboundShipmentActions.setName,
        inboundShipmentActions.setNotes,
        inboundShipmentActions.setWarehouseId,
        inboundShipmentReceivingActions.updateNewlyReceivedItemBySku
    ),
    effect: (action, listenerApi) => {
        listenerApi.dispatch(dirtyStateActions.markDirty());
    },
});
