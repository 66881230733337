import type { RootState } from "store";
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { B2BOrder_FE, B2BPick_FE } from "../types";
import { v4 } from "uuid";
import {
    CLIENT_BOX_ITEM_PREFIX,
    CLIENT_BOX_PREFIX,
    b2bBoxFactory,
    b2bPalletFactory,
} from "../factories";
import {
    calcTotalNumCartons,
    calcTotalQuantityOfBaseItems,
    isOpenB2bOrder,
} from "../helpers";
import {
    B2BBox,
    B2BDocumentType,
    B2BPallet,
    B2BPick,
    B2BPickItem,
    B2BStatus,
    B2BTracking,
} from "@librex-fulfillment/librex-zchemas";

export const sliceName = "b2bOrder" as const;
export type B2BOrderSliceName = typeof sliceName;

export type B2BOrderState = {
    b2bOrder?: B2BOrder_FE;
    picks?: B2BPick_FE[];
};

const initialState = {} as B2BOrderState;
const b2bOrderSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        addBoxToB2bOrder(state, action: PayloadAction<Partial<B2BBox>>) {
            state.b2bOrder!.boxes.push({
                ...b2bBoxFactory(),
                ...action.payload,
            });
        },
        addPallet(state, action: PayloadAction<B2BPallet>) {
            state.b2bOrder!.pallets.push({
                id: v4(),
                ...action.payload,
            });
        },
        addTrackingRecord(state, action: PayloadAction<B2BTracking>) {
            if (!Array.isArray(state.b2bOrder?.tracking)) {
                state.b2bOrder!.tracking = [];
            }
            state.b2bOrder!.tracking.push(action.payload);
        },
        b2bLoaded(state, action: PayloadAction<B2BOrder_FE>) {
            state.b2bOrder = {
                ...action.payload,
                pallets:
                    action.payload.pallets.map((pallet) => ({
                        ...b2bPalletFactory,
                        ...pallet,
                    })) ?? [],
            };
        },
        b2bOrderUpdated(state, action: PayloadAction<B2BOrder_FE>) {
            const pallets = action.payload.pallets ?? state.b2bOrder?.pallets;
            state.b2bOrder = {
                ...state.b2bOrder!,
                ...action.payload,
                pallets:
                    pallets.map((pallet) => ({
                        ...b2bPalletFactory,
                        ...pallet,
                    })) ?? [],
            };
        },
        /**
         * Adds picks to state and resets form picks
         */
        picksCreated(state, action: PayloadAction<B2BPick[]>) {
            if (!Array.isArray(action.payload)) return;
            if (!Array.isArray(state.picks)) state.picks = [];
            state.picks = state.picks.concat(action.payload);
        },
        picksLoaded(state, action: PayloadAction<B2BPick[]>) {
            if (!Array.isArray(action.payload)) return;
            state.picks = action.payload;
        },
        removeBoxByIndex(state, action: PayloadAction<number>) {
            if (
                !Array.isArray(state.b2bOrder!.boxes) ||
                !state.b2bOrder?.boxes.at(action.payload)
            ) {
                return;
            }
            state.b2bOrder!.boxes.splice(action.payload, 1);
        },
        removePalletByIndex(state, action: PayloadAction<number>) {
            if (
                !Array.isArray(state.b2bOrder!.pallets) ||
                !state.b2bOrder!.pallets.at(action.payload)
            ) {
                return;
            }

            state.b2bOrder!.pallets.splice(action.payload, 1);
        },
        removePickForItemByPickId(state, action: PayloadAction<string>) {
            if (!Array.isArray(state.picks)) return;
            state.picks = state.picks.filter(
                (pick) => pick._id !== action.payload
            );
        },
        resetState(state) {
            state.b2bOrder = undefined;
            state.picks = undefined;
        },
        setB2bName(state, action: PayloadAction<string>) {
            if (!state.b2bOrder) return;
            state.b2bOrder.name = action.payload;
        },
        setNotes(state, action: PayloadAction<string>) {
            state.b2bOrder!.notes = action.payload;
        },
        setStatus(state, action: PayloadAction<B2BStatus>) {
            state.b2bOrder!.status = action.payload;
        },
        setWarehouseId(state, action: PayloadAction<string>) {
            state.b2bOrder!.warehouse_id = action.payload;
        },
    },
});

export const b2bOrderActions = b2bOrderSlice.actions;

const selectStatus = (state: RootState) =>
    state.b2b[sliceName].b2bOrder?.status;

/* Derived selectors */
const selectIsOpenOrder = createSelector(selectStatus, (status) =>
    isOpenB2bOrder(status)
);
export const b2bOrderSelectors = {
    // Simple selectors - Direct state access selctors go here
    selectB2bId: (state: RootState) => state.b2b[sliceName].b2bOrder?._id,
    selectB2bName: (state: RootState) => state.b2b[sliceName].b2bOrder?.name,
    selectBoxes: (state: RootState) =>
        state.b2b[sliceName].b2bOrder?.boxes ?? [],
    selectBusiness: (state: RootState) =>
        state.b2b[sliceName].b2bOrder?.user?.business,
    selectCompanyId: (state: RootState) =>
        state.b2b[sliceName].b2bOrder?.company_id,
    selectCreatedDate: (state: RootState) =>
        state.b2b[sliceName].b2bOrder?.createdAt,
    selectDates: (state: RootState) => state.b2b[sliceName].b2bOrder?.dates,
    selectDocuments: (state: RootState) =>
        state.b2b[sliceName].b2bOrder?.documents,
    selectEmail: (state: RootState) =>
        state.b2b[sliceName].b2bOrder?.user?.email,
    selectHasBeenBilled: (state: RootState) =>
        state.b2b[sliceName].b2bOrder?.has_been_billed,
    selectIsOpenOrder,
    selectItemByIndex: (index: number) => (state: RootState) =>
        state.b2b[sliceName].b2bOrder?.items?.at(index),
    selectItems: (state: RootState) => state.b2b[sliceName].b2bOrder?.items,
    selectNotes: (state: RootState) => state.b2b[sliceName].b2bOrder?.notes,
    selectPallets: (state: RootState) => state.b2b[sliceName].b2bOrder?.pallets,
    selectPickItems: (state: RootState) =>
        state.b2b[sliceName].b2bOrder?.pickItems,
    selectPicks: (state: RootState) => state.b2b[sliceName].picks,
    selectPicksForItem: (item_id: string) => (state: RootState) =>
        state.b2b[sliceName].picks?.filter((pick) => pick.item_id === item_id),
    selectSentDate: (state: RootState) =>
        state.b2b[sliceName].b2bOrder?.dates?.sent_date,
    selectStatus,
    selectShipheroAccountId: (state: RootState) =>
        state.b2b[sliceName].b2bOrder?.user?.shipheroAccountId,
    selectTracking: (state: RootState) =>
        state.b2b[sliceName].b2bOrder?.tracking,
    selectWarehouse: (state: RootState) =>
        state.b2b[sliceName].b2bOrder?.warehouse,
    selectWarehouseId: (state: RootState) =>
        state.b2b[sliceName].b2bOrder?.warehouse_id,
    /*
     * Derived selectors - Computed state access selectors go here
     */
    selectDocumentsOfType: (type: B2BDocumentType) => (state: RootState) =>
        state.b2b[sliceName].b2bOrder?.documents?.filter(
            (doc) => doc.document_type === type
        ),
    selectFirstDocumentOfType: (type: B2BDocumentType) => (state: RootState) =>
        state.b2b[sliceName].b2bOrder?.documents?.find(
            (doc) => doc.document_type === type
        ),
    selectFirstTrackingRecord: (state: RootState) =>
        state.b2b[sliceName].b2bOrder?.tracking?.at(0),
    selectFullName: (state: RootState) =>
        `${state.b2b[sliceName].b2bOrder?.user?.firstName ?? ""} ${
            state.b2b[sliceName].b2bOrder?.user?.lastName ?? ""
        }`,
    selectHasBoxes: (state: RootState) =>
        Array.isArray(state.b2b[sliceName].b2bOrder?.boxes) &&
        state.b2b[sliceName].b2bOrder!.boxes.length > 0,
    selectHasPallets: (state: RootState) =>
        Array.isArray(state.b2b[sliceName].b2bOrder?.pallets) &&
        state.b2b[sliceName].b2bOrder!.pallets.length > 0,
    selectHasPickingStarted: (state: RootState) =>
        Array.isArray(state.b2b[sliceName].picks) &&
        state.b2b[sliceName].picks.length > 0,
    selectNumberOfItems: (state: RootState) =>
        state.b2b[sliceName].b2bOrder?.items?.length ?? 0,
    selectPickedQuantityForPickItem:
        (pickItem: B2BPickItem) => (state: RootState) =>
            state.b2b[sliceName].picks?.reduce(
                (total, pick) =>
                    pick.item_id === pickItem.item_id
                        ? total + pick.quantity
                        : total,
                0
            ) ?? 0,
    selectTotalQuantityOfBoxes: (state: RootState) =>
        calcTotalNumCartons(state.b2b[sliceName].b2bOrder?.boxes),
    /*
     * We use pick items to calculate the total quantity of items in a B2B order.
     * Pick items are a generated list of the "base-most" SKUs in the order.
     * Taking into account the quantities of SKUs contained in a kit.
     */
    selectTotalQuantityOfPicksItems: (state: RootState) =>
        calcTotalQuantityOfBaseItems(state.b2b[sliceName].b2bOrder?.pickItems),
    /* Selectors for GraphQL mutations */
    selectUpdateOneB2bOrderFilter: createSelector(
        [
            (state: RootState) => ({
                _id: state.b2b[sliceName].b2bOrder?._id,
                company_id: state.b2b[sliceName].b2bOrder?.company_id,
            }),
        ],
        (filter) => filter
    ),
    selectUpdateOneB2bOrderMutation: createSelector(
        [
            (state: RootState) => ({
                record: {
                    boxes: state.b2b[sliceName].b2bOrder?.boxes.map((box) => {
                        const cleanItems = box.items.map((item) => {
                            const { _id, ...restItem } = item;
                            if (_id.includes(CLIENT_BOX_ITEM_PREFIX)) {
                                return restItem;
                            }
                            return item;
                        });

                        const { _id, createdAt, updatedAt, ...restBox } = box;
                        if (_id.includes(CLIENT_BOX_PREFIX)) {
                            return {
                                ...restBox,
                                items: cleanItems,
                            };
                        }
                        return {
                            _id,
                            ...restBox,
                            items: cleanItems,
                        };
                    }),
                    notes: state.b2b[sliceName].b2bOrder?.notes,
                    pallets: state.b2b[sliceName].b2bOrder?.pallets?.map(
                        ({ id, ...pallet }) => pallet
                    ),
                    status: state.b2b[sliceName].b2bOrder?.status,
                    tracking: state.b2b[sliceName].b2bOrder?.tracking,
                },
                filter: {
                    _id: state.b2b[sliceName].b2bOrder?._id,
                    company_id: state.b2b[sliceName].b2bOrder?.company_id,
                },
            }),
        ],
        (updateOneB2bOrderInput) => updateOneB2bOrderInput
    ),
};

export default b2bOrderSlice;
