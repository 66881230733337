import { useAppDispatch, useAppSelector } from "hooks";
import { queryAndFilterActions, queryAndFilterSelectors } from "../slice";
import styles from "./Filter.module.scss";

type Filter = {
    value: any;
    display: any;
};
type FilterProps = {
    filterKey: string;
    filters: Filter[];
};

/**
 * Only allows one filter to be active at a time
 */
function FilterSingle({ filterKey, filters }: FilterProps) {
    const activeFilter = useAppSelector(
        queryAndFilterSelectors.selectOneFilter(filterKey)
    );
    const dispatch = useAppDispatch();
    const doSetFilter = (filter: any) => () => {
        const update = {
            [filterKey]: filter,
        };
        if (filter === activeFilter) {
            // Set to undefined as null will be interpreted as a value
            // in the GraphQL query
            update[filterKey] = undefined;
        }
        dispatch(queryAndFilterActions.updateFilter(update));
    };
    return (
        <div className={styles.filters}>
            {filters.map((filter) => (
                <button
                    onClick={doSetFilter(filter.value)}
                    className={
                        filter.value === activeFilter ? styles.active : ""
                    }
                    key={filter.value}
                    type="button"
                >
                    {filter.display}
                </button>
            ))}
            <button
                onClick={doSetFilter(undefined)}
                className={!activeFilter ? styles.active : ""}
                type="button"
            >
                Clear
            </button>
        </div>
    );
}
/**
 * Helper function for building filter objects expected by this component
 * @param value Value of the fiter used for filter
 * @param display Pretty display value or name
 */
FilterSingle.buildFilter = (value: any, display: any) => ({
    value,
    display,
});

export default FilterSingle;
