/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { V3_BASE } from "../../../LibrexConstants";
import {
    librexGetRequest,
    librexPostRequest,
} from "../../../managers/HttpUtil";
import type { RootState } from "../../../store";
import * as orderActions from "../orderActions";

export interface OrderState {
    full_screen: boolean;
    orders: OrderI[];
    orders_loaded: boolean;
    shiphero_cursor: string;
    has_next_page: boolean;
}

const initialState = {
    full_screen: false,
    orders: [],
    orders_loaded: false,
    shiphero_cursor: "",
    has_next_page: true,
} as OrderState;

export const ordersSlice = createSlice({
    name: "orders",
    initialState,
    reducers: {
        setCursor: (state, { payload }: PayloadAction<string>) => {
            if (typeof payload === "string") {
                state.shiphero_cursor = payload;
            }
        },
        setOrders: (state, { payload }: PayloadAction<OrderI[]>) => {
            if (Array.isArray(payload)) {
                state.orders = payload;
            }
        },
        setFullScreen: (state, { payload }: PayloadAction<boolean>) => {
            state.full_screen = payload === true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            cancelOrder.fulfilled,
            (state, { payload }: PayloadAction<OrderI | undefined>) => {
                if (!payload) return;
                state.orders = state.orders.map((order) => {
                    if (payload?.order_number === order.order_number) {
                        order.status = "canceled";
                    }
                    return order;
                });
            }
        );

        builder.addCase(
            fetchOrdersFromShiphero.fulfilled,
            (state, { payload }: PayloadAction<LibrexAPIReply<OrderI[]>>) => {
                const { data, page_info } = payload;
                if (Array.isArray(data)) {
                    const current_orders = new Set(
                        state.orders.map((order) => order.order_number)
                    );
                    for (let idx = 0; idx < data.length; idx++) {
                        const order = data[idx];
                        if (current_orders.has(order.order_number)) continue;
                        state.orders.push(order);
                    }
                    state.orders_loaded = true;
                    state.shiphero_cursor = page_info?.endCursor ?? "";
                    state.has_next_page = page_info?.hasNextPage ?? false;
                }
            }
        );

        builder.addCase(
            fetchOrderByOrderNumber.fulfilled,
            (state, { payload }: PayloadAction<OrderI | null | undefined>) => {
                if (!payload) return;

                let found = false;
                for (let idx = 0; idx < state.orders.length; idx++) {
                    if (
                        state.orders[idx].order_number === payload.order_number
                    ) {
                        state.orders[idx] = payload;
                        found = true;
                        break;
                    }
                }

                if (found === false) state.orders.unshift(payload);
            }
        );

        builder.addCase(
            fetchItemsForOrderFromShiphero.fulfilled,
            (state, { payload }: PayloadAction<[string, OrderItemI[]]>) => {
                const [order_id, order_items] = payload;
                state.orders = state.orders.map((order) => {
                    if (order.shiphero_id === order_id) {
                        // If the order hasn't had items fetched yet it's value could be undefined
                        const previous_items = Array.isArray(order.items)
                            ? order.items
                            : [];
                        return {
                            ...order,
                            items: previous_items.concat(order_items),
                        };
                    }
                    return order;
                });
            }
        );

        builder.addCase(
            fetchCoordinatesForOrderAddress.fulfilled,
            (
                state,
                {
                    payload: { coordinates, shiphero_id },
                }: PayloadAction<{
                    coordinates: [number, number];
                    shiphero_id: string;
                }>
            ) => {
                if (Array.isArray(coordinates)) {
                    state.orders = state.orders.map((order) => {
                        if (order.shiphero_id === shiphero_id) {
                            return {
                                ...order,
                                shipping_address: {
                                    ...order.shipping_address,
                                    coordinates,
                                },
                            };
                        }
                        return order;
                    });
                }
            }
        );

        builder.addCase(
            fetchOrderHistory.fulfilled,
            (
                state,
                {
                    payload: { order_history, shiphero_id },
                }: PayloadAction<{ order_history: any[]; shiphero_id: string }>
            ) => {
                if (
                    Array.isArray(order_history) &&
                    typeof shiphero_id === "string"
                ) {
                    state.orders = state.orders.map((order) => {
                        if (order.shiphero_id === shiphero_id) {
                            return {
                                ...order,
                                order_history,
                            };
                        }
                        return order;
                    });
                }
            }
        );

        builder.addCase(
            fetchFulfullmentCost.fulfilled,
            (
                state,
                {
                    payload: { fulfillment_items, shiphero_id },
                }: PayloadAction<{
                    shiphero_id: string;
                    fulfillment_items: ZohoLineItem[] | undefined;
                }>
            ) => {
                if (
                    !Array.isArray(fulfillment_items) ||
                    typeof shiphero_id !== "string"
                )
                    return;
                let fulfillment_cost = 0;
                for (let idx = 0; idx < fulfillment_items.length; idx++) {
                    const { rate } = fulfillment_items[idx];
                    if (typeof rate !== "number") continue;
                    fulfillment_cost += rate;
                }

                state.orders = state.orders.map((order) => {
                    if (order.shiphero_id === shiphero_id) {
                        return {
                            ...order,
                            fulfillment_cost,
                        };
                    }
                    return order;
                });
            }
        );
    },
});

export const { setCursor, setOrders, setFullScreen } = ordersSlice.actions;

export const selectOrders = (state: RootState) => state.order.orders.orders;
export const selectOrdersLoaded = (state: RootState) =>
    state.order.orders.orders_loaded;
export const selectHasNextPage = (state: RootState) =>
    state.order.orders.has_next_page;
export const selectFullScreen = (state: RootState) =>
    state.order.orders.full_screen;

export const selectOrderById = (
    state: RootState,
    order_id?: string
): OrderI | undefined => {
    // Verify that an order number was passed to the selector
    if (typeof order_id !== "string" || order_id === "") return undefined;
    return state.order.orders.orders?.find(
        (order) => order.shiphero_id === order_id
    );
};

export const cancelOrder = createAsyncThunk(
    orderActions.CANCEL_ORDER,
    async (data: ShipheroCancelOrderMutation) => {
        const url = `${V3_BASE}/orders/cancel`;
        toast.info("Canceling order...", { toastId: "cancelOrderStart" });
        const api_response: LibrexAPIReply<OrderI> = await librexPostRequest(
            url,
            { data }
        );
        if (api_response.success) {
            toast.success(api_response.message);
        } else {
            toast.error(api_response.message);
        }
        return api_response.data;
    }
);

export const fetchOrdersFromShiphero = createAsyncThunk(
    orderActions.FETCH_ORDERS_FROM_SHIPHERO,
    async (
        shiphero_account_id: string,
        { getState }
    ): Promise<LibrexAPIReply<OrderI[]>> => {
        const state = getState() as RootState;
        const cursor = state.order.orders.shiphero_cursor;
        const url = `${V3_BASE}/orders/shiphero?shiphero_account_id=${shiphero_account_id}&cursor=${cursor}`;
        const fetch_orders_response: LibrexAPIReply<OrderI[]> =
            await librexGetRequest(url);

        return fetch_orders_response;
    }
);

export type FetchOrderByOrderNumberProps = {
    order_number: string;
    shiphero_account_id?: string;
};
export const fetchOrderByOrderNumber = createAsyncThunk(
    orderActions.FETCH_ORDERS_BY_ORDER_NUMBER,
    async ({
        order_number,
        shiphero_account_id,
    }: FetchOrderByOrderNumberProps): Promise<OrderI | undefined | null> => {
        if (!order_number) return null;
        const url = `${V3_BASE}/orders/shiphero?shiphero_account_id=${shiphero_account_id}&order_number=${order_number}`;
        const fetch_orders_response: LibrexAPIReply<OrderI[]> =
            await librexGetRequest(url);

        // This endpoint will always return an array, extract the order from the array
        if (!Array.isArray(fetch_orders_response.data)) return null;
        return fetch_orders_response.data[0];
    }
);

export const fetchItemsForOrderFromShiphero = createAsyncThunk(
    orderActions.FETCH_ITEMS_FOR_ORDER_FROM_SHIPHERO,
    async (order_id: string): Promise<[string, any]> => {
        if (!order_id) {
            return ["", undefined];
        }
        const url = `${V3_BASE}/orders/shiphero/${order_id}/items`;
        const fetch_orders_response = await librexGetRequest(url);

        return [order_id, fetch_orders_response.data];
    }
);

export const fetchCoordinatesForOrderAddress = createAsyncThunk(
    orderActions.FETCH_ADDRESS_COORDINATES,
    async ({
        address,
        shiphero_id,
    }: {
        address: StreetAddressI | CustomerAddressI;
        shiphero_id: string;
    }) => {
        const url = `${V3_BASE}/maps/address-coordinates`;
        const body = { address };
        const api_response = await librexPostRequest(url, body);

        return {
            shiphero_id,
            coordinates: api_response.data,
        };
    }
);

export const fetchOrderHistory = createAsyncThunk(
    orderActions.FETCH_ORDER_HISTORY,
    async ({
        carrier,
        tracking_number,
        shiphero_id,
    }: {
        carrier: string;
        tracking_number: string;
        shiphero_id: string;
    }) => {
        const url = `${V3_BASE}/orders/tracking-history?carrier=${carrier}&tracking_number=${tracking_number}`;
        const api_reponse = await librexGetRequest(url);

        return {
            shiphero_id,
            order_history: api_reponse,
        };
    }
);

export const fetchFulfullmentCost = createAsyncThunk(
    orderActions.FETCH_FULFILLMENT_COST,
    async ({
        shiphero_id,
        shipments,
    }: {
        shiphero_id: string;
        shipments: any[];
    }) => {
        const url = `${V3_BASE}/orders/fulfillment-cost`;
        const api_reponse = await librexPostRequest(url, { shipments });

        return {
            shiphero_id,
            fulfillment_items: api_reponse.data,
        };
    }
);

export default ordersSlice.reducer;
