import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store";

export type ViewUserState = {
    changes: Partial<User>;
    user?: User;
};

export const buildInitialState = (): ViewUserState => ({
    changes: {},
    user: undefined,
});

export const sliceName = "viewUser" as const;
export type ViewUserSliceName = typeof sliceName;

export const userSlice = createSlice({
    name: sliceName,
    initialState: buildInitialState(),
    reducers: {
        clearUser: (state) => {
            state.user = undefined;
            state.changes = {};
        },
        genericUserUpdate: (state, action: PayloadAction<Partial<User>>) => {
            if (state.user) {
                state.user = { ...state.user, ...action.payload };
                state.changes = { ...state.changes, ...action.payload };
            }
        },
        setUser: (state, action: PayloadAction<User | undefined>) => {
            state.user = action.payload;
            state.changes = {};
        },
    },
});

export const { clearUser, genericUserUpdate, setUser } = userSlice.actions;

export const selectBusiness = (state: RootState) =>
    state.modal[sliceName].user?.business;
export const selectChanges = (state: RootState) =>
    state.modal[sliceName].changes;
export const selectEmail = (state: RootState) =>
    state.modal[sliceName].user?.email;
export const selectFirstName = (state: RootState) =>
    state.modal[sliceName].user?.firstName;
export const selectLastName = (state: RootState) =>
    state.modal[sliceName].user?.lastName;
export const selectNotes = (state: RootState) =>
    state.modal[sliceName].user?.notes;
export const selectPhone = (state: RootState) =>
    state.modal[sliceName].user?.phone;
export const selectShipheroId = (state: RootState) =>
    state.modal[sliceName].user?.shipheroAccountId;
export const selectUser = (state: RootState) => state.modal[sliceName].user;
export const selectUserId = (state: RootState) =>
    state.modal[sliceName].user?._id;
export const selectWarehouses = (state: RootState) =>
    state.modal[sliceName].user?.warehouses;
export const selectZohoId = (state: RootState) =>
    state.modal[sliceName].user?.zohoCustomerId;

// Dervied selectors
export const selectHasShipheroAccount = (state: RootState) =>
    !!state.modal[sliceName].user?.shipheroAccountId;

export default {
    reducer: userSlice.reducer,
    sliceName,
};
