import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Product_FE } from "../types";
import {
    PackageType,
    PaddingTypes,
    ProductAttribute,
    ReturnPrefs,
} from "@librex-fulfillment/librex-zchemas";
import { RootState } from "store";
import {
    ProductSyncOneWithShipheroVariables,
    ProductUpdateOneVariables,
} from "../graphql-documents";

type StockDetailsState = {
    product?: Partial<Product_FE>;
    showAlignOneModal: boolean;
};

const initialState = {
    product: undefined,
    showAlignOneModal: false,
} as StockDetailsState;

export const detailsSlice = createSlice({
    name: "details",
    initialState,
    reducers: {
        clearProduct(state) {
            state.product = undefined;
        },
        hideAlignOneModal(state) {
            state.showAlignOneModal = false;
        },
        setProduct(state, action: PayloadAction<Partial<Product_FE>>) {
            state.product = action.payload;
        },
        setReturnItemCondition(
            state,
            action: PayloadAction<ReturnPrefs["item_condition"]>
        ) {
            if (!state.product) return;
            if (!state.product.return_prefs)
                state.product.return_prefs = {
                    item_condition: {},
                };

            state.product.return_prefs.item_condition = {
                ...state.product.return_prefs.item_condition,
                ...action.payload,
            };
        },
        showAlignOneModal(state) {
            state.showAlignOneModal = true;
        },
        toggleAttribute(state, action: PayloadAction<ProductAttribute>) {
            if (!state.product) return;
            if (!state.product.attributes) state.product.attributes = [];

            const index = state.product.attributes?.indexOf(action.payload);
            if (index === -1) {
                state.product.attributes.push(action.payload);
            } else {
                state.product.attributes.splice(index, 1);
            }
        },
        toggleMarketingInsert(state) {
            if (!state.product) return;
            if (!state.product.packing_prefs) state.product.packing_prefs = {};

            state.product.packing_prefs.marketing_insert_required =
                !state.product.packing_prefs.marketing_insert_required;
        },
        togglePackingType(state, action: PayloadAction<PackageType>) {
            if (!state.product) return;

            if (state.product.packing_prefs?.package_type === action.payload) {
                state.product.packing_prefs.package_type = undefined;
            } else {
                if (!state.product.packing_prefs) {
                    state.product.packing_prefs = {};
                }
                state.product.packing_prefs.package_type = action.payload;
            }
        },
        togglePaddingType(state, action: PayloadAction<PaddingTypes>) {
            if (!state.product) return;
            if (!state.product.packing_prefs) state.product.packing_prefs = {};
            if (!state.product.packing_prefs.padding_type) {
                state.product.packing_prefs.padding_type = [];
            }

            const index = state.product.packing_prefs?.padding_type?.indexOf(
                action.payload
            );
            if (index === -1) {
                state.product.packing_prefs.padding_type.push(action.payload);
            } else {
                state.product.packing_prefs.padding_type.splice(index, 1);
            }
        },
    },
});

export const stockDetailsActions = detailsSlice.actions;

const selectAttributes = (state: RootState) =>
    state.inventory.stock.details.product?.attributes;
const selectKitComponents = (state: RootState) =>
    state.inventory.stock.details.product?.kit_components;
const selectProduct = (state: RootState) =>
    state.inventory.stock.details.product;
const selectParentKits = (state: RootState) =>
    state.inventory.stock.details.product?.parent_kits;

/* Derived selectors */
const selectIsKit = createSelector(
    [selectKitComponents],
    (components) => components?.length !== 0
);
export const stockDetailsSelectors = {
    selectAttributes,
    selectCompany: (state: RootState) =>
        state.inventory.stock.details.product?.company,
    selectCompanyId: (state: RootState) =>
        state.inventory.stock.details.product?.company_id,
    selectId: (state: RootState) => state.inventory.stock.details.product?._id,
    selectIsKit,
    selectKitComponents,
    selectName: (state: RootState) =>
        state.inventory.stock.details.product?.name,
    selectPackingPrefs: (state: RootState) =>
        state.inventory.stock.details.product?.packing_prefs,
    selectParentKits,
    selectProduct,
    selectReturnPrefs: (state: RootState) =>
        state.inventory.stock.details.product?.return_prefs,
    selectShowAlignOneModal: (state: RootState) =>
        state.inventory.stock.details.showAlignOneModal,
    selectSku: (state: RootState) => state.inventory.stock.details.product?.sku,
    selectSpecialRequests: (state: RootState) =>
        state.inventory.stock.details.product?.special_requests,
    selectWarehouseProducts: (state: RootState) =>
        state.inventory.stock.details.product?.warehouse_products,
    // Derived selectors
    selectAlignOneGqlInput: createSelector(
        [
            (
                state: RootState
            ): Partial<ProductSyncOneWithShipheroVariables> => ({
                sku: state.inventory.stock.details.product?.sku,
                company_id: state.inventory.stock.details.product?.company_id!,
            }),
        ],
        (input) => input
    ),
    selectGqlFilterId: createSelector(
        [
            (state: RootState) => ({
                _id: state.inventory.stock.details.product?._id,
            }),
        ],
        (filter) => filter
    ),
    selectSyncOneShipheroGqlInput: createSelector(
        [
            (
                state: RootState
            ): Partial<ProductSyncOneWithShipheroVariables> => ({
                sku: state.inventory.stock.details.product?.sku,
                company_id: state.inventory.stock.details.product?.company_id!,
            }),
        ],
        (input) => input
    ),
    selectUpdateOneGqlInput: createSelector(
        [
            (state: RootState): ProductUpdateOneVariables => ({
                filter: {
                    _id: state.inventory.stock.details.product?._id,
                },
                record: {
                    attributes:
                        state.inventory.stock.details.product?.attributes,
                    packing_prefs:
                        state.inventory.stock.details.product?.packing_prefs,
                    return_prefs:
                        state.inventory.stock.details.product?.return_prefs,
                    special_requests:
                        state.inventory.stock.details.product?.special_requests,
                },
            }),
        ],
        (variables) => variables
    ),
};
