import {
    INBOUND_SHIPMENT_STATUS,
    InboundShipment,
    InboundShipmentItem,
    InboundShipmentStatus,
} from "@librex-fulfillment/librex-zchemas";
import { formatDistanceToNowStrict } from "date-fns";

/**
 * Returns the relative time since a date using 1 character to designate time unit.
 *
 * y = year
 * M = month
 * w = week
 * d = day
 * h = hour
 * m = minute
 * s = second
 */
export function getTimeDisplay(
    from: Date | number | string | undefined | null
) {
    if (!from) return "";

    let fromDate;
    if (typeof from === "string") fromDate = new Date(from);
    else {
        fromDate = from;
    }

    const time_difference = formatDistanceToNowStrict(fromDate);

    return time_difference
        .split(" ")
        .map((s) => {
            if (s === "year" || s === "years") {
                return "y";
            }
            if (s === "month" || s === "months") {
                return "M";
            }
            if (s === "week" || s === "weeks") {
                return "w";
            }
            if (s === "day" || s === "days") {
                return "d";
            }
            if (s === "hour" || s === "hours") {
                return "h";
            }
            if (s === "minute" || s === "minutes") {
                return "m";
            }
            if (s === "second" || s === "seconds") {
                return "s";
            }
            return s;
        })
        .join("");
}

/**
 * Utility function for deciding if an inbound shipment is currently active
 */
export function isActiveShipment(status?: InboundShipmentStatus) {
    const inactiveStatuses: InboundShipmentStatus[] = [
        INBOUND_SHIPMENT_STATUS.CANCELED,
        INBOUND_SHIPMENT_STATUS.ERROR,
        INBOUND_SHIPMENT_STATUS.COMPLETED,
    ];
    return !!status && !inactiveStatuses.includes(status);
}
/**
 * Utility function for determining if a product for a shipment is active
 * @deprecated
 */
export function isActiveShipmentProduct(
    inbound_shipment: InboundShipment | undefined,
    product: InboundShipmentItem | undefined
) {
    if (!inbound_shipment || !product) return false;
    return true;
}

export const getInboundShipmentStatusText = (status: string) => {
    switch (status) {
        case "inbound":
            return "We are waiting for your shipment to arrive";
        case "processing":
            return "We are receiving & inspecting your shipment";
        case "completed":
            return "Your shipment has arrived safely";
        case "canceled":
            return "Your shipment has been canceled";
        case "error":
            return "There was an error with your shipment";
        default:
            return "";
    }
};
