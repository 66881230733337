import { useDispatch, useSelector } from "react-redux";
import { queryAndFilterActions, queryAndFilterSelectors } from "../slice";

const NextPage = ({
    disabled,
    ...props
}: Omit<React.HTMLProps<HTMLButtonElement>, "onClick" | "type">) => {
    const dispatch = useDispatch();
    const hasNextPage = useSelector(
        queryAndFilterSelectors.selectPaginationHasNextPage
    );

    const handleClick = () => {
        dispatch(queryAndFilterActions.nextPage());
    };
    return (
        <button
            disabled={disabled || !hasNextPage}
            type="button"
            onClick={handleClick}
            {...props}
        >
            {">"}
        </button>
    );
};

export default NextPage;
