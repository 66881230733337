import { v4 } from "uuid";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { V3_BASE } from "../LibrexConstants";
import {
    ACCESS_TOKEN,
    SHIPHERO_ACCESS_TOKEN,
} from "../managers/ManagerConstants";

export const LIBREX_API_TAGS = [
    "Accounts",
    "B2B-Order",
    "Invoice",
    "Location",
    "Order",
    "Products",
    "ShippoTracking",
    "User",
    "Warehouse",
    "WarehouseProduct",
    "WarehouseProductLocation",
] as const;
export type LibrexApiTag = (typeof LIBREX_API_TAGS)[number];
export const ProductsTag = "Products";

export const baseSlice = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: V3_BASE,
        prepareHeaders: (headers) => {
            headers.set(
                "authorization",
                `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
            );
            headers.set("request-id", v4());

            const shiphero_access_token = localStorage.getItem(
                SHIPHERO_ACCESS_TOKEN
            );
            if (shiphero_access_token) {
                headers.set("shiphero-access-token", shiphero_access_token);
            }
            return headers;
        },
    }),
    tagTypes: LIBREX_API_TAGS,
    endpoints: () => ({}),
});

const librexBaseQuery = (base: string, path = "") =>
    fetchBaseQuery({
        baseUrl: `${base}${path}`,
        prepareHeaders: (headers) => {
            headers.set(
                "authorization",
                `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
            );
            return headers;
        },
    });

export const librexV3BaseQuery = (path = "") => librexBaseQuery(V3_BASE, path);
