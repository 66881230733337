import type { RootState } from "store";
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";

export type QueryState = {
    query: string;
    pagination: LibrexPagination;
    filter: {
        company_id?: string;
    } & Record<string, any>;
};

const initialState: QueryState = {
    pagination: {
        count: 0,
        skip: 0,
        limit: 20,
    },
    query: "",
    filter: {},
};

export const queryAndFilterSlice = createSlice({
    name: "queryAndFilter" as const,
    initialState,
    reducers: {
        clearAllFilters: (state) => {
            state.filter = {};
        },
        clearFilterKey: (state, { payload }: PayloadAction<string>) => {
            if (!state.filter[payload]) return;
            state.filter[payload] = undefined;
        },
        clearQuery: (state) => {
            state.query = "";
        },
        nextPage: (state) => {
            state.pagination.skip += state.pagination.limit;
        },
        previousPage: (state) => {
            state.pagination.skip -= state.pagination.limit;
        },
        reset: () => initialState,
        updateFilter: (state, action: PayloadAction<Record<string, any>>) => {
            state.filter = {
                ...state.filter,
                ...action.payload,
            };
        },
        updatePagination: (
            state,
            action: PayloadAction<Partial<LibrexPagination>>
        ) => {
            state.pagination = {
                ...state.pagination,
                ...action.payload,
            };
        },
        setQuery: (state, action) => {
            state.query = action.payload;
        },
    },
});

export const queryAndFilterReducer = queryAndFilterSlice.reducer;

export const queryAndFilterActions = queryAndFilterSlice.actions;

const selectFirstItemIndex = (state: RootState) =>
    state.modules.queryAndFilter.pagination.skip + 1;
const selectPagination = (state: RootState) =>
    state.modules.queryAndFilter.pagination;

export const queryAndFilterSelectors = {
    selectQuery: (state: RootState) => state.modules.queryAndFilter.query,
    selectFilter: (state: RootState) => state.modules.queryAndFilter.filter,
    selectPagination,
    selectSearchQueryInput: createSelector(
        [
            (state: RootState) => ({
                query: state.b2b.search.query,
                filter: state.b2b.search.filter,
            }),
        ],
        (filter) => filter
    ),
    /**
     * Derived selectors
     */
    selectFirstItemIndex,
    selectLastItemIndex: () =>
        createSelector([selectPagination], (pagination) => {
            const lastItemIndex = pagination.skip + pagination.limit;
            return lastItemIndex > pagination.count
                ? pagination.count
                : lastItemIndex;
        }),
    selectOneFilter: (key: string) => (state: RootState) =>
        state.modules.queryAndFilter.filter[key],
    selectPaginationHasNextPage: (state: RootState) =>
        state.modules.queryAndFilter.pagination.count >
        state.modules.queryAndFilter.pagination.skip +
            state.modules.queryAndFilter.pagination.limit,
    selectPaginationHasPreviousPage: (state: RootState) =>
        state.modules.queryAndFilter.pagination.skip > 0,
};
