import type { RootState } from "store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type InvoiceState = {
    invoice?: ZohoInvoice;
};

const getInitialState = (): InvoiceState => ({});

export const SLICE_NAME = "viewInvoice";

export const invoiceSlice = createSlice({
    name: SLICE_NAME,
    initialState: getInitialState(),
    reducers: {
        clearInvoice: (state) => {
            state.invoice = undefined;
        },
        setInvoice: (state, action: PayloadAction<ZohoInvoice>) => {
            state.invoice = action.payload;
        },
    },
});

export const { clearInvoice, setInvoice } = invoiceSlice.actions;

export const selectBalanceDue = (state: RootState) =>
    state[SLICE_NAME].invoice?.balance;
export const selectBillTo = (state: RootState) =>
    state[SLICE_NAME].invoice?.customer_name;
export const selectDueDate = (state: RootState) =>
    state[SLICE_NAME].invoice?.due_date;
export const selectInvoiceDate = (state: RootState) =>
    state[SLICE_NAME].invoice?.date;
export const selectInvoiceItems = (state: RootState) =>
    state[SLICE_NAME].invoice?.line_items;
export const selectInvoiceNumber = (state: RootState) =>
    state[SLICE_NAME].invoice?.invoice_number;
export const selectPaymentsMade = (state: RootState) =>
    (state[SLICE_NAME].invoice?.total ?? 0) -
    (state[SLICE_NAME].invoice?.balance ?? 0);
export const selectStatus = (state: RootState) =>
    state[SLICE_NAME].invoice?.status;
export const selectSubtotal = (state: RootState) =>
    state[SLICE_NAME].invoice?.sub_total;
export const selectTerms = (state: RootState) =>
    state[SLICE_NAME].invoice?.payment_terms_label;
export const selectTotal = (state: RootState) =>
    state[SLICE_NAME].invoice?.total;

export default invoiceSlice.reducer;
