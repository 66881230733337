import { createListenerMiddleware } from "@reduxjs/toolkit";
import { inventoryAuditActions } from "./state";
import { stockDetailsActions } from "../../state/details";

const productHistorySliceMiddlewear = createListenerMiddleware();
/*
 * Reset admin form box state everyttime a the box in state is added to the b2b order
 */
productHistorySliceMiddlewear.startListening({
    actionCreator: stockDetailsActions.setProduct,
    effect: (action, listenerApi) => {
        listenerApi.dispatch(
            inventoryAuditActions.setFilterSku(action.payload.sku)
        );
    },
});

export { productHistorySliceMiddlewear };
