import { useAppDispatch, useAppSelector } from "hooks";
import { Alert, Button, Modal } from "react-bootstrap";
import { dirtyStateActions, dirtyStateSelectors } from "./state";

type DirtyStateModalProps = {
    onContinue: () => void;
    onGoBack?: () => void;
};

function DirtyStateModal({ onContinue, onGoBack }: DirtyStateModalProps) {
    const show = useAppSelector(dirtyStateSelectors.selectShowDirtyStateModal);

    const dispatch = useAppDispatch();
    const handleContinue = () => {
        dispatch(dirtyStateActions.hideDirtyStateModal());
        onContinue();
    };
    const handleGoBack = () => {
        dispatch(dirtyStateActions.hideDirtyStateModal());
        onGoBack?.();
    };

    return (
        <Modal show={show}>
            <Modal.Body>
                <Alert variant="warning">
                    <Alert.Heading>Unsaved Changes</Alert.Heading>
                </Alert>
                <p>
                    You have unsaved changes, if you continue they will be lost.
                    Are you sure you want to continue?
                </p>
                <div className="d-flex justify-content-between">
                    <Button variant="outline-secondary" onClick={handleGoBack}>
                        Go Back
                    </Button>
                    <Button variant="primary" onClick={handleContinue}>
                        Continue
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default DirtyStateModal;
