import { ZMongoId, ZTimestamps } from "common";
import { STRING_MEDIUM_MAX_LENGTH } from "consts";
import { z } from "zod";

export const ZB2BPick = z
  .object({
    b2b_id: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
    company_id: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
    item_id: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
    location_id: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
    picked_by: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
    quantity: z.number().int().positive(),
    warehouse_id: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
  })
  .extend(ZMongoId)
  .extend(ZTimestamps);
export type B2BPick = z.infer<typeof ZB2BPick>;

export const ZB2BPickCreateInput = ZB2BPick.omit({
  createdAt: true,
  updatedAt: true,
}).partial({
  _id: true,
  picked_by: true,
});
export type B2BPickCreateInput = z.infer<typeof ZB2BPickCreateInput>;
