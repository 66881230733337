import { z } from "zod";

export const ZShipheroAddress = z.object({
  address1: z.string().optional(),
  address2: z.string().optional(),
  city: z.string().optional(),
  country: z.string().optional(),
  name: z.string().optional(),
  phone: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),
});
export type ShipheroAddress = z.infer<typeof ZShipheroAddress>;

export const ZShipheroDimensions = z.object({
  height: z.string().optional(),
  length: z.string().optional(),
  width: z.string().optional(),
  weight: z.string().optional(),
});
export type ShipheroDimensions = z.infer<typeof ZShipheroDimensions>;
