import { ZTimestamps } from "common";
import { ZShipheroProduct } from "shiphero";
import { z } from "zod";
import { ZProductIntegrations } from "./product";

export const ZWarehouseProduct = z
  .object({
    active: z.boolean().optional(),
    allocated: z.number().optional(),
    available: z.number().optional(),
    backorder: z.number().optional(),
    company_id: z.string(),
    integrations: ZProductIntegrations.default({
      shiphero: {},
    }),
    on_hand: z.number(),
    price: z.string().optional(),
    sku: z.string(),
    warehouse_id: z.string(),
  })
  .extend(ZTimestamps);
export type WarehouseProduct = z.infer<typeof ZWarehouseProduct>;

export const ZShipheroProductWithWarehouseProducts = ZShipheroProduct.extend({
  warehouse_products: z.array(ZWarehouseProduct),
});
export type ShipheroProductWithWarehouseProducts = z.infer<
  typeof ZShipheroProductWithWarehouseProducts
>;
