import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
    from,
    fromPromise,
} from "@apollo/client";
import { V3_GRAPHQL_URI } from "LibrexConstants";
import { setContext } from "@apollo/client/link/context";
import { ACCESS_TOKEN, SHIPHERO_ACCESS_TOKEN } from "managers/ManagerConstants";
import { onError } from "@apollo/client/link/error";
import { generateAccessTokenWithRefreshToken } from "managers/HttpUtil";

let tokenRefreshPromise: Promise<LibrexAPIReply<{
    token: string;
    user_id: string;
}> | void> = Promise.resolve();
let isRefreshing: boolean = false;

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (
        !isRefreshing &&
        graphQLErrors?.some(({ message }) => message.includes("jwt expired"))
    ) {
        isRefreshing = true;
        tokenRefreshPromise = generateAccessTokenWithRefreshToken();
        tokenRefreshPromise.then(() => {
            isRefreshing = false;
        });
        return fromPromise(tokenRefreshPromise).flatMap(() =>
            forward(operation)
        );
    }
});

/**
 * Apollo Client likes to add the __typename field to all of its queries/mutations.
 * While the __typename is good for caching, it is not good for the backend.
 * The server may fail a request if it sees the __typename field (depending on the field types).
 * This occurs with Dimensions fields (e.g. b2bOrder.pallets.dimensions).
 *
 * This solution is modeled after (https://github.com/apollographql/apollo-feature-requests/issues/6#issuecomment-428560796)
 */
const omitTypename = (key: string, value: any) =>
    key === "__typename" ? undefined : value;

const removeTypenameMiddlewear = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        operation.variables = JSON.parse(
            JSON.stringify(operation.variables),
            omitTypename
        );
    }
    return forward(operation);
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem(ACCESS_TOKEN);
    const shipheroAccessToken = localStorage.getItem(SHIPHERO_ACCESS_TOKEN);
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
            "x-shiphero-access-token": shipheroAccessToken || "",
        },
    };
});

const httpLink = new HttpLink({
    uri: V3_GRAPHQL_URI,
});

const client = new ApolloClient({
    uri: V3_GRAPHQL_URI,
    cache: new InMemoryCache(),
    link: from([
        errorLink,
        removeTypenameMiddlewear,
        authLink.concat(httpLink),
    ]),
});

export default client;
