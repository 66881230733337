import { ZMongoId, ZTimestamps } from "common";
import { z } from "zod";
import { STRING_LONG_MAX_LENGTH, STRING_MEDIUM_MAX_LENGTH } from "consts";
import {
  ZInboundShipmentItem,
  ZInboundShipmentItemCreateInput,
  ZInboundShipmentReceivedItem,
} from "./items";

export const ZInboundShipmentStatus = z.enum([
  "CANCELED",
  "COMPLETED",
  "ERROR",
  "INBOUND",
  "PROCESSING",
]);
export const INBOUND_SHIPMENT_STATUS = ZInboundShipmentStatus.enum;
export type InboundShipmentStatus = z.infer<typeof ZInboundShipmentStatus>;

export const ZInboundShipmentType = z.enum([
  "FULFILLMENT",
  "FBA_PREP",
  "B2B",
  "STORAGE",
]);
export const INBOUND_SHIPMENT_TYPE = ZInboundShipmentType.enum;
export type InboundShipmentType = z.infer<typeof ZInboundShipmentType>;

export const ZInboundShipmentDates = z.object({
  estimated_arrival: z.string().datetime().optional(),
  processed: z.string().datetime().optional(),
  arrived: z.string().datetime().optional(),
});
export type InboundShipmentDates = z.infer<typeof ZInboundShipmentDates>;

export const ZInboundShipmentEventType = z.enum([
  "ARRIVED",
  "CANCELED",
  "COMPLETED",
  "CREATED",
]);
export const INBOUND_SHIPMENT_EVENT_TYPE = ZInboundShipmentEventType.enum;
export type InboundShipmentEventType = z.infer<
  typeof ZInboundShipmentEventType
>;

export const ZInboundShipmentEvent = z
  .object({
    description: z.string(),
    type: ZInboundShipmentEventType,
  })
  .extend(ZTimestamps);
export type InboundShipmentEvent = z.infer<typeof ZInboundShipmentEvent>;

export const ZInboundShipmentIntegrations = z.object({
  shiphero: z
    .object({
      account_id: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH).optional(),
      id: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH).optional(),
    })
    .optional(),
});
export type InboundShipmentIntegrations = z.infer<
  typeof ZInboundShipmentIntegrations
>;

export const ZInboundShipmentTracking = z.object({
  carrier: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
  tracking_number: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
});
export type InboundShipmentTracking = z.infer<typeof ZInboundShipmentTracking>;

export const ZInboundShipmentTraits = z.object({
  amazon_removal: z.boolean().default(false),
  has_been_billed: z.boolean().default(false),
  num_cartons: z.number().int().positive().default(0),
  num_pallets: z.number().int().nonnegative().default(0),
});
export type InboundShipmentTraits = z.infer<typeof ZInboundShipmentTraits>;

export const ZInboundShipment = z
  .object({
    active: z.boolean().default(true),
    company_id: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
    dates: ZInboundShipmentDates.default({}),
    history: z.array(ZInboundShipmentEvent).default([]),
    items: z.array(ZInboundShipmentItem),
    integrations: ZInboundShipmentIntegrations,
    name: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH).default(""),
    notes: z.string().trim().max(STRING_LONG_MAX_LENGTH).default(""),
    received_items: z.array(ZInboundShipmentReceivedItem).default([]),
    status: ZInboundShipmentStatus.default(INBOUND_SHIPMENT_STATUS.INBOUND),
    tracking: z.array(ZInboundShipmentTracking).optional(),
    traits: ZInboundShipmentTraits,
    type: ZInboundShipmentType,
    warehouse_id: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
  })
  .extend(ZMongoId)
  .extend(ZTimestamps);
export type InboundShipment = z.infer<typeof ZInboundShipment>;

export const ZInboundShipmentCreateInput = ZInboundShipment.partial({
  _id: true,
  active: true,
  amazon_removal: true,
  createdAt: true,
  dates: true,
  history: true,
  name: true,
  notes: true,
  num_pallets: true,
  received_items: true,
  status: true,
  updatedAt: true,
})
  .omit({
    items: true,
    integrations: true,
  })
  .extend({
    items: ZInboundShipmentItemCreateInput.array(),
    integrations: ZInboundShipmentIntegrations.default({}),
  });
export type InboundShipmentCreateInput = z.infer<
  typeof ZInboundShipmentCreateInput
>;
