export function getLocationNameFromWarehouseId(warehouse_id: string): string {
    switch (warehouse_id) {
        case "V2FyZWhvdXNlOjcyNjky":
            return "Fredrick, Maryland";
        case "V2FyZWhvdXNlOjgyMDU2":
            return "Rochester, NY";
        case "V2FyZWhvdXNlOjc4MjY3":
            return "Chicago, Illinois";
        default:
            return "";
    }
}

export const buildWarehouseDisplay = (warehouse?: WarehouseI | null) => {
    if (!warehouse || !warehouse.address) return "";
    return `${warehouse.address.city}, ${warehouse.address.state}`;
};
