import QueryString from "qs";
import { z } from "zod";
import { SHIPHERO_ACCESS_TOKEN } from "../../managers/ManagerConstants";
import { baseSlice } from "../base";

export const warehouseUrl = (
    route: string = "",
    query: Record<string, any> = {}
): string => {
    const params = QueryString.stringify(query, { addQueryPrefix: true });
    const url = `/warehouses${route}${params}`;
    return url;
};

export type GetLocationsForSkuAtWarehouseParams = {
    shiphero_account_id: string;
    sku: string;
    warehouse_id: string;
};
export const getLocationsForSkuAtWarehouseZchema = z.object({
    shiphero_account_id: z.string(),
    sku: z.string(),
    warehouse_id: z.string(),
});
export const areValidGetLocationsForSkuAtWarehouseParams = (
    params: GetLocationsForSkuAtWarehouseParams
) => getLocationsForSkuAtWarehouseZchema.safeParse(params).success;
export type GetWarehousesParams = Partial<WarehouseI> & {
    search?: string;
};

export const warehouseApi = baseSlice.injectEndpoints({
    endpoints: (builder) => ({
        getLocationsForSkuAtWarehouse: builder.query<
            LocationI[],
            GetLocationsForSkuAtWarehouseParams
        >({
            query: ({ shiphero_account_id, sku, warehouse_id }) =>
                warehouseUrl(`/${warehouse_id}/locations/${sku}`, {
                    shiphero_account_id,
                }),
            transformResponse: (response: { data: LocationI[] }) =>
                response.data ?? [],
            providesTags: (results) =>
                results
                    ? [
                          ...results.map(({ _id }) => ({
                              type: "WarehouseProductLocation" as const,
                              id: _id,
                          })),
                      ]
                    : [],
        }),
        getLocationsForWarehouse: builder.query<
            LocationI[],
            Partial<LocationI>
        >({
            query: (query) => warehouseUrl("/locations", query),
            transformResponse: (response: { data: LocationI[] }) =>
                response.data ?? [],
            providesTags: (results) =>
                results
                    ? [
                          ...results.map(({ _id: id }) => ({
                              type: "Location" as const,
                              id,
                          })),
                          { type: "Location" as const, id: "LIST" },
                      ]
                    : [{ type: "Location" as const, id: "LIST" }],
        }),
        getUsersForWarehouse: builder.query<User[], string>({
            query: (warehouse_id) => warehouseUrl(`/${warehouse_id}/users`),
            transformResponse: (response: { data: User[] }) =>
                response.data ?? [],
            providesTags: (results) =>
                results
                    ? [
                          ...results.map(({ _id: id }) => ({
                              type: "Accounts" as const,
                              id,
                          })),
                          { type: "Accounts" as const, id: "LIST" },
                      ]
                    : [{ type: "Accounts" as const, id: "LIST" }],
        }),
        getWarehouse: builder.query<WarehouseI | undefined, string>({
            query: (warehouse_id) => warehouseUrl(`/${warehouse_id}`),
            transformResponse: (response: { data: WarehouseI }) =>
                response.data ?? undefined,
            providesTags: (results, error, arg) => [
                { type: "Warehouse" as const, id: arg },
            ],
        }),
        getWarehouses: builder.query<WarehouseI[], GetWarehousesParams>({
            query: (query) => warehouseUrl("", query),
            transformResponse: (response: { data: WarehouseI[] }) =>
                response.data ?? [],
            providesTags: (results) =>
                results
                    ? [
                          ...results.map(({ _id: id }) => ({
                              type: "Warehouse" as const,
                              id,
                          })),
                          { type: "Warehouse" as const, id: "LIST" },
                      ]
                    : [{ type: "Warehouse" as const, id: "LIST" }],
        }),
        syncLocationsForWarehouse: builder.mutation<LocationI[], string>({
            query: (warehouse_id) => ({
                url: warehouseUrl(`/${warehouse_id}/locations/shiphero/sync`),
                method: "POST",
                body: {
                    shiphero_access_token: localStorage.getItem(
                        SHIPHERO_ACCESS_TOKEN
                    ),
                },
            }),
            transformResponse: (response: { data: LocationI[] }) =>
                response.data ?? [],
            invalidatesTags: [{ type: "Location" as const, id: "LIST" }],
        }),
    }),
});

export const {
    useGetLocationsForSkuAtWarehouseQuery,
    useGetLocationsForWarehouseQuery,
    useGetUsersForWarehouseQuery,
    useGetWarehouseQuery,
    useGetWarehousesQuery,
    useSyncLocationsForWarehouseMutation,
} = warehouseApi;
