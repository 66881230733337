import { ProductAttribute } from "@librex-fulfillment/librex-zchemas";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { ProductCreateOneVariables } from "../graphql-documents";

type CreateProductSliceState = {
    company_id?: string;
    sku: string;
    name: string;
    attributes: ProductAttribute[];
};
const initialState: CreateProductSliceState = {
    sku: "",
    name: "",
    attributes: [],
};

export const createProductSlice = createSlice({
    name: "create",
    initialState,
    reducers: {
        resetFormState: () => initialState,
        setCompanyId: (state, action: PayloadAction<string>) => {
            state.company_id = action.payload;
        },
        setSku: (state, action: PayloadAction<string>) => {
            state.sku = action.payload;
        },
        setName: (state, action: PayloadAction<string>) => {
            state.name = action.payload;
        },
        toggleAttribute: (state, action: PayloadAction<ProductAttribute>) => {
            const index = state.attributes.findIndex(
                (attribute) => attribute === action.payload
            );
            if (index === -1) {
                state.attributes.push(action.payload);
            } else {
                state.attributes.splice(index, 1);
            }
        },
    },
});

export const productCreateActions = createProductSlice.actions;

export const productCreateSelectors = {
    selectSku: (state: RootState) => state.inventory.stock.create.sku,
    selectName: (state: RootState) => state.inventory.stock.create.name,
    selectAttributes: (state: RootState) =>
        state.inventory.stock.create.attributes,
    /** Derived Selectors */
    selectCreateOneGqlInput: (state: RootState): ProductCreateOneVariables => ({
        record: {
            attributes: state.inventory.stock.create.attributes,
            company_id: state.inventory.stock.create.company_id,
            name: state.inventory.stock.create.name,
            sku: state.inventory.stock.create.sku,
        },
    }),
};
