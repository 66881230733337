import {
    createSelector,
    createSlice,
    type PayloadAction,
} from "@reduxjs/toolkit";
import { InboundShipment_FE } from "../types";
import { type RootState } from "store";
import { inboundShipmentSelectors } from "./inboundShipment";
import {
    InboundShipmentReceivedItem,
    InboundShipmentReceivedItemCreateInput,
} from "@librex-fulfillment/librex-zchemas";

type NewlyReceivedItem = Partial<InboundShipment_FE["received_items"][number]>;
export type NewlyReceivedItems = Record<string, NewlyReceivedItem>;

type MoreReceivedThanExpectedState = {
    idx?: number;
    item?: InboundShipmentReceivedItem | InboundShipmentReceivedItemCreateInput;
};
export type InboundShipmentReceivingState = {
    newlyReceivedItems: NewlyReceivedItems;
    moreReceivedThanExpected: MoreReceivedThanExpectedState;
};
const initialState = {
    newlyReceivedItems: {},
    moreReceivedThanExpected: {
        idx: undefined,
        item: undefined,
    },
} as InboundShipmentReceivingState;

export const inboundShipmentReceivingSlice = createSlice({
    name: "receiving",
    initialState,
    reducers: {
        acceptExtraQuantity(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars -- used in middleware
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars -- used in middleware
            action: PayloadAction<MoreReceivedThanExpectedState["item"]>
        ) {},
        clearMoreReceivedThanExpected(state) {
            state.moreReceivedThanExpected.idx = undefined;
        },
        hideMoreReceivedThanExpected(state) {
            state.moreReceivedThanExpected.idx = undefined;
        },
        rejectExtraQuantity(state) {
            state.moreReceivedThanExpected = {};
        },
        resetMoreReceivedThanExpected(state) {
            state.moreReceivedThanExpected = {};
        },
        resetState() {
            return initialState;
        },
        showMoreReceivedThanExpected(
            state,
            action: PayloadAction<MoreReceivedThanExpectedState>
        ) {
            state.moreReceivedThanExpected = {
                ...state.moreReceivedThanExpected,
                ...action.payload,
            };
        },
        setNewlyReceivedItems(
            state,
            action: PayloadAction<NewlyReceivedItems>
        ) {
            state.newlyReceivedItems = action.payload;
        },
        updateNewlyReceivedItemBySku(
            state,
            action: PayloadAction<{
                sku: string;
                receivedItem: Partial<
                    InboundShipment_FE["received_items"][number]
                >;
            }>
        ) {
            state.newlyReceivedItems[action.payload.sku] = {
                ...state.newlyReceivedItems[action.payload.sku],
                ...action.payload.receivedItem,
            };
        },
    },
});

export const inboundShipmentReceivingActions =
    inboundShipmentReceivingSlice.actions;
/* Static selectors */
const selectMoreReceivedThanExpected = (state: RootState) =>
    state.inbound_shipment.receiving.moreReceivedThanExpected;
const selectMoreReceivedThanExpectedIdx = (state: RootState) =>
    state.inbound_shipment.receiving.moreReceivedThanExpected.idx;
const selectMoreReceivedThanExpectedItem = (state: RootState) =>
    state.inbound_shipment.receiving.moreReceivedThanExpected.item;
const selectNewlyReceivedItems = (state: RootState) =>
    state.inbound_shipment.receiving.newlyReceivedItems;
/* Derived selectors */
const selectItemFieldBySku = <
    T extends keyof InboundShipment_FE["received_items"][number]
>(
    sku: string,
    field: T
) => createSelector(selectNewlyReceivedItemBySku(sku), (item) => item?.[field]);
const selectShowMoreReceivedThanExpected = createSelector(
    selectMoreReceivedThanExpectedIdx,
    (idx) => idx !== undefined
);
const selectNewlyReceivedItemBySku = (sku?: string) =>
    createSelector(
        selectNewlyReceivedItems,
        (newReceivedItems) => newReceivedItems[sku ?? ""]
    );
const selectTotalReceivedQuantityForItem = (sku?: string) =>
    createSelector(
        inboundShipmentSelectors.selectNumReceivedForItemBySku(sku),
        selectNewlyReceivedItemBySku(sku),
        (qtyReceived, newlyReceivedItem) =>
            (qtyReceived ?? 0) + (newlyReceivedItem?.quantity ?? 0)
    );

export const inboundShipmentReceivingSelectors = {
    selectItemFieldBySku,
    selectMoreReceivedThanExpected,
    selectMoreReceivedThanExpectedIdx,
    selectMoreReceivedThanExpectedItem,
    selectNewlyReceivedItems,
    selectNewlyReceivedItemBySku,
    selectShowMoreReceivedThanExpected,
    selectTotalReceivedQuantityForItem,
};
