import type { RootState } from "store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type SidebarNavLink = {
    to: string;
    "aria-label": string;
};

export type SidebarState = {
    open: boolean;
    navLink?: SidebarNavLink;
};
export const SIDEBAR_REDUCER = "sidebar" as const;
export type SidebarReducer = typeof SIDEBAR_REDUCER;

export const initialState: SidebarState = {
    navLink: undefined,
    open: false,
};

export const sidebarReducer = createSlice({
    name: SIDEBAR_REDUCER,
    initialState,
    reducers: {
        clearNavLink: (state) => {
            state.navLink = undefined;
        },
        setNavLink: (state, action: PayloadAction<SidebarNavLink>) => {
            state.navLink = action.payload;
        },
        openSideBar: (state) => {
            state.open = true;
        },
        closeSideBar: (state) => {
            state.open = false;
        },
    },
});

export const { clearNavLink, setNavLink, openSideBar, closeSideBar } =
    sidebarReducer.actions;

export const selectNavLink = (state: RootState) => state.sidebar.navLink;
export const selectOpen = (state: RootState) => state.sidebar.open;

export default sidebarReducer.reducer;
