import { combineReducers } from "redux";
import { listSlice } from "./list";
import { detailsSlice } from "./details";
import { createProductSlice } from "./create";

const stockReducer = combineReducers({
    [createProductSlice.name]: createProductSlice.reducer,
    [detailsSlice.name]: detailsSlice.reducer,
    [listSlice.name]: listSlice.reducer,
});

export default stockReducer;
