/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
import {
    createAsyncThunk,
    createSelector,
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";
import * as UserActions from "./userActions";
import { librexGetRequest, librexPostRequest } from "../../managers/HttpUtil";
import { V2_AUTH, V3_BASE } from "../../LibrexConstants";
import {
    ACCESS_TOKEN,
    REFRESH_TOKEN,
    SHIPHERO_ACCESS_TOKEN,
    USER_ID,
} from "../../managers/ManagerConstants";
import type { RootState } from "../../store";
import { toast } from "react-toastify";

export interface UserState {
    isAuthenticated: boolean;
    loading: boolean;
    mapbox_token: string | null;
    user: User | null;
}

const initialState = {
    isAuthenticated: false,
    loading: false,
    user: null,
    mapbox_token: null,
} as UserState;

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        deleteUser: (state) => {
            state.user = null;
        },
        setUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMapboxToken.fulfilled, (state, { payload }) => {
            state.mapbox_token = payload;
        });
        builder.addCase(fetchUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
            state.user = payload;
            state.isAuthenticated = !!payload;
            state.loading = false;
        });
        builder.addCase(fetchUser.rejected, (state) => {
            state.user = null;
            state.isAuthenticated = false;
            state.loading = false;
        });
        builder.addCase(login.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(login.fulfilled, (state, { payload }) => {
            state.user = payload;
            state.isAuthenticated = !!payload;
            state.loading = false;
        });
        builder.addCase(login.rejected, (state) => {
            state.user = null;
            state.isAuthenticated = false;
            state.loading = false;
        });
    },
});

export const { deleteUser, setUser } = userSlice.actions;

const selectEmail = (state: RootState) => state.user.user?.email;
const selectId = (state: RootState) => state.user.user?._id;
export const selectIsAuthenticated = (state: RootState) =>
    state.user.isAuthenticated;
export const selectIsAuthenticating = (state: RootState) => state.user.loading;
export const selectMapboxToken = (state: RootState) => state.user.mapbox_token;
export const selectUser = (state: RootState) => state.user.user;
export const selectUserWarehouses = (state: RootState) =>
    state.user.user?.warehouses;
export const selectUserWarehousesIds = createSelector(
    [selectUserWarehouses],
    (warehouses) => warehouses?.map((w) => w._id)
);
const selectIsAdmin = createSelector(
    [selectUser],
    (user) => user?.role === "admin"
);

export const userSelectors = {
    selectEmail,
    selectId,
    selectIsAdmin,
    selectIsAuthenticated,
    selectIsAuthenticating,
    selectMapboxToken,
    selectUser,
    selectUserWarehouses,
    selectUserWarehousesIds,
};

export const fetchUser = createAsyncThunk(
    UserActions.FETCH_USER,
    async (user_id: string) => {
        const get_user_response = await librexGetRequest(
            `${V3_BASE}/users/${user_id}`
        );
        // Login was unsuccessful
        if (get_user_response.success === false) {
            return null;
        }
        const user: User | null = get_user_response.data;

        return user;
    }
);
export const fetchMapboxToken = createAsyncThunk(
    UserActions.FETCH_MAPBOX_TOKEN,
    async (user_id: string) => {
        const get_mapbox_token_response = await librexGetRequest(
            `${V3_BASE}/users/${user_id}/tokens/mapbox`
        );
        if (get_mapbox_token_response.success === false) {
            localStorage.clear();
        }
        // Login was unsuccessful
        return get_mapbox_token_response.data;
    }
);

/**
 * Handles generating a refresh token and access token
 * Along with storing these values in local storage
 */
export const login = createAsyncThunk(
    UserActions.LOGIN,
    async (login_data: { email: string; password: string }) => {
        const login_response = await librexPostRequest(`${V2_AUTH}/login`, {
            email: login_data.email,
            password: login_data.password,
        });

        // Login was unsuccessful
        if (!login_response.success) {
            // TODO: Create the notification slice and dispatch notification action
            if (
                (login_response.message as string).includes(
                    "Password is incorrect"
                )
            ) {
                toast.error("Invalid email or password.");
                return;
            }
        }

        const {
            data: { token: refresh_token, user_id, shipheroAccessToken },
        } = login_response;

        localStorage.setItem(REFRESH_TOKEN, refresh_token);
        localStorage.setItem(USER_ID, user_id);
        // Admins also receive a shiphero access token for API requests. store this in state as well
        if (shipheroAccessToken) {
            localStorage.setItem(SHIPHERO_ACCESS_TOKEN, shipheroAccessToken);
        }

        // TODO: Seperate generate access token and fetch user into seperate thunks. Call the actions here
        const generate_access_token_response = await librexPostRequest(
            `${V2_AUTH}/token/refresh`,
            { refresh_token }
        );

        if (!generate_access_token_response.data?.token) {
            // TODO: Create the notification slice and dispatch notification action
            return;
        }

        localStorage.setItem(
            ACCESS_TOKEN,
            generate_access_token_response.data.token
        );
        const user = await librexGetRequest(`${V3_BASE}/users/${user_id}`);

        return user.data;
    }
);

export default userSlice.reducer;
