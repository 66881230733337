import { ZMongoId, ZTimestamps } from "common";
import { STRING_LONG_MAX_LENGTH, STRING_MEDIUM_MAX_LENGTH } from "consts";
import { z } from "zod";

export const ZInboundShipmentItem = z
  .object({
    company_id: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
    quantity: z.number().int().positive(),
    sku: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
  })
  .extend(ZMongoId)
  .extend(ZTimestamps);
export type InboundShipmentItem = z.infer<typeof ZInboundShipmentItem>;

export const ZInboundShipmentItemCreateInput = ZInboundShipmentItem.partial({
  _id: true,
  createdAt: true,
  updatedAt: true,
});
export type InboundShipmentItemCreateInput = z.infer<
  typeof ZInboundShipmentItemCreateInput
>;
// Enum of possible conditions for received items
export const ZInboundShipmentReceivedItemCondition = z.enum([
  "DAMAGED",
  "EXPIRED",
  // "FOR_PARTS",
  "NO_ISSUE",
  "UNKNOWN",
  "WRONG_PRODUCT",
]);
export type InboundShipmentReceivedItemCondition = z.infer<
  typeof ZInboundShipmentReceivedItemCondition
>;
export const RECEIVED_ITEM_CONDITIONS =
  ZInboundShipmentReceivedItemCondition.enum;

export const ZInboundShipmentReceivedItemDates = z.object({
  received: z.string().datetime(),
});

export const ZInboundShipmentReceivedItem = z
  .object({
    company_id: z
      .string({
        required_error: "Company ID is required",
      })
      .trim()
      .max(STRING_MEDIUM_MAX_LENGTH),
    condition: ZInboundShipmentReceivedItemCondition.array().min(1, {
      message:
        "Must select at least one condition for this item to be received",
    }),
    dates: ZInboundShipmentReceivedItemDates,
    images: z
      .string()
      .url({
        message: "Images must be be a valid URL",
      })
      .array()
      .optional(),
    location_id: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH).min(1, {
      message: "Location ID is required",
    }),
    notes: z.string().trim().max(STRING_LONG_MAX_LENGTH).optional(),
    quantity: z
      .number({
        required_error: "A positive quantity is required",
        invalid_type_error: "Quantity must be a number",
      })
      .int()
      .positive({
        message: "Quantity must be a positive number",
      }),
    sku: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
    warehouse_id: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
  })
  .extend(ZMongoId)
  .extend(ZTimestamps);

export type InboundShipmentReceivedItem = z.infer<
  typeof ZInboundShipmentReceivedItem
>;

export const ZInboundShipmentReceivedItemCreateInput =
  ZInboundShipmentReceivedItem.partial({
    _id: true,
    createdAt: true,
    updatedAt: true,
  });
export type InboundShipmentReceivedItemCreateInput = z.infer<
  typeof ZInboundShipmentReceivedItemCreateInput
>;
