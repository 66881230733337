export const ALERT_FACTORY = (
    show?: boolean,
    message?: string,
    color?:
        | "danger"
        | "librex"
        | "primary"
        | "secondary"
        | "success"
        | "warning",
    spinner?: boolean,
    spinner_color?: string
) => ({
    show: show || false,
    message: message || "",
    color: color || "",
    spinner: spinner || false,
    spinner_color: spinner_color || "",
});

export const buildDataArrayFromDict = (data: LooseObject) => {
    let data_array: any[] = [];
    const data_keys = Object.keys(data);
    for (let i = 0; i < data_keys.length; i++) {
        const key = data_keys[i];
        if (Array.isArray(data[key])) {
            data_array = data_array.concat(data[key]);
        }
    }
    return data_array;
};

/**
 * Formats an address object into an address string
 */
export function buildStreetAddress(address: StreetAddressI | CustomerAddressI) {
    let address_string = address.street1;
    const append = (field: any) => {
        if (field) {
            address_string += `, ${field}`;
        }
        return address_string;
    };
    // Handle street 2 being optional
    if (address.street2) address_string += `, ${address.street2}`;
    append(address.city);
    append(address.state);
    append(address.country);
    append(address.zip);
    return address_string;
}

export function buildStreetAddressGoogleMapsLink(
    address: StreetAddressI | CustomerAddressI
) {
    return `https://www.google.com/maps/place/${encodeURI(
        buildStreetAddress(address).split(" ").join("+").replace("#", "")
    )}`;
}

export function buildTrackingLink(
    tracking_number: string,
    carrier: string
): string | undefined {
    const cleanCarrier = carrier
        ?.toLowerCase()
        .trim()
        .replace(/shippo/g, "")
        .replace(/_/g, " ");
    switch (cleanCarrier) {
        case "ups":
            return `http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${tracking_number}`;
        case "globegistics":
            return `https://a1.asendiausa.com/tracking/?trackingnumber=${tracking_number}`;
        case "usps":
            return `https://tools.usps.com/go/TrackConfirmAction?tLabels=${tracking_number}`;
        default:
            return `https://www.google.com/search?q=${encodeURI(
                `${carrier} ${tracking_number}`
            )}`;
    }
}

/**
 * Validates a dimensions object
 * All dimensions should float parsable
 */
export function dimensionsAreValid(dimensions: Dimensions) {
    if (
        Number.isNaN(parseFloat(dimensions.length)) ||
        Number.isNaN(parseFloat(dimensions.width)) ||
        Number.isNaN(parseFloat(dimensions.height)) ||
        Number.isNaN(parseFloat(dimensions.weight))
    )
        return false;

    return true;
}

export const isNonEmptyString = (value: any) =>
    typeof value === "string" && value !== "";
export const isStringOrUndefined = (value: any) =>
    typeof value === "undefined" || typeof value === "string";

export const isPositiveNonZeroNumber = (value: any) =>
    Number.isNaN(value) === false && value > 0;

export const joinClasses = (...args: (string | undefined)[]) =>
    args.filter((arg) => !!arg).join(" ");

/**
 * Prints any and all fields missing values.
 * Typically used for printing all missing required fields when doing input validation
 * @param {object} fields Object where field names are the key and their value is the value
 * @returns comma seperated string of variables with falsy values
 */
export const printMissingFields = (fields: LooseObject) => {
    const field_names = Object.keys(fields);
    return field_names
        .filter((field: string) => !fields[field])
        .map((field) => `${field}`)
        .join(", ");
};
