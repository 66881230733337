export const ROUTES = {
    ACCOUNTS: {
        path: "accounts" as const,
        text: "Accounts" as const,
    },
    ADMIN: {
        path: "admin" as const,
        text: "Admin" as const,
    },
    AUTH: {
        path: "auth" as const,
        text: "Auth" as const,
    },
    B2B: {
        path: "b2b" as const,
        text: "B2B Orders" as const,
    },
    BILLING: {
        path: "billing" as const,
        text: "Billing" as const,
    },
    COLLECTIONS: {
        path: "collections" as const,
        text: "Collections" as const,
    },
    INBOUND_SHIPMENTS: {
        path: "inbound-shipments" as const,
        text: "Inbound Shipments" as const,
    },
    INVENTORY: {
        path: "inventory" as const,
        text: "Inventory" as const,
    },
    INVOICES: {
        path: "invoices" as const,
        text: "Invoices" as const,
    },
    LOGIN: {
        path: "login" as const,
        text: "Login" as const,
    },
    ORDERS: {
        path: "orders" as const,
        text: "Orders" as const,
    },
    STOCK: {
        path: "stock" as const,
        text: "Stock" as const,
    },
    WAREHOUSING: {
        path: "warehousing" as const,
        text: "Warehousing" as const,
    },
};

export type FeatureRoute = (typeof ROUTES)[keyof typeof ROUTES];

export const FEATURE_PATHS = Object.values(ROUTES).map((route) => route.path);
export const buildFeatureRoute = (...routes: FeatureRoute[]) =>
    `/${routes.map((route) => route.path).join("/")}`;
