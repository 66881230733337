import { combineReducers } from "redux";
import { inboundShipmentDetailsSlice } from "./inboundShipment";
import { createInboundShipmentSlice } from "./create";
import { inboundShipmentReceivingSlice } from "./receiving";

export default combineReducers({
    [createInboundShipmentSlice.name]: createInboundShipmentSlice.reducer,
    [inboundShipmentDetailsSlice.name]: inboundShipmentDetailsSlice.reducer,
    [inboundShipmentReceivingSlice.name]: inboundShipmentReceivingSlice.reducer,
});
