import qs from "qs";
import { toast } from "react-toastify";
import { SHIPHERO_ACCESS_TOKEN } from "../../managers/ManagerConstants";
import { baseSlice } from "../base";

export const usersUrl = (route: string, query?: Record<string, any>) =>
    `/users${route}?${qs.stringify(query)}`;
export const accountsApi = baseSlice.injectEndpoints({
    endpoints: (builder) => ({
        addWarehouseToAccount: builder.mutation<
            User | null,
            { user_id: string; warehouses: string[] }
        >({
            query: ({ user_id, warehouses }) => ({
                url: usersUrl(`/${user_id}/warehouses/add`),
                method: "POST",
                body: {
                    warehouses,
                },
            }),
            transformResponse: (response: { data: User | null }) =>
                response.data,
            invalidatesTags: (result, error, arg) => [
                { type: "Accounts", id: arg.user_id },
            ],
        }),
        createAccount: builder.mutation<User | null, UserInput>({
            query: (user) => ({
                url: usersUrl(""),
                method: "POST",
                body: {
                    user,
                    shiphero_access_token: localStorage.getItem(
                        SHIPHERO_ACCESS_TOKEN
                    ),
                },
            }),
            transformResponse: (response: { data: User | null }) =>
                response.data,
            onQueryStarted(arg, { queryFulfilled }) {
                toast.info(
                    `Creating account for ${arg.firstName} ${arg.lastName} (${arg.business})`
                );
                queryFulfilled
                    .then((result) => {
                        if (!result) {
                            toast.error(
                                `Failed to create account for ${arg.firstName} ${arg.lastName} (${arg.business})`
                            );
                        } else {
                            toast.success(
                                `Created account for ${arg.firstName} ${arg.lastName} (${arg.business})`
                            );
                        }
                    })
                    .catch(() => {
                        toast.error(
                            `Failed to create account for ${arg.firstName} ${arg.lastName} (${arg.business})`
                        );
                    });
            },
            invalidatesTags: [{ type: "Accounts" as const, id: "LIST" }],
        }),
        deactivateAccount: builder.mutation<User | null, string>({
            query: (user_id) => ({
                url: usersUrl(`${user_id}/deactivate`),
                method: "POST",
            }),
            transformResponse: (response: { data: User | null }) =>
                response.data,
            invalidatesTags: (result, error, args) => [
                { type: "Accounts" as const, id: args },
            ],
        }),
        getAccounts: builder.query<User[], Record<string, any>>({
            query: (query = {}) => usersUrl("", query),
            transformResponse: (response: { data: User[] }) =>
                response.data ?? [],
            providesTags: (results) =>
                results
                    ? [
                          ...results.map(({ _id: id }) => ({
                              type: "Accounts" as const,
                              id,
                          })),
                          { type: "Accounts" as const, id: "LIST" },
                      ]
                    : [{ type: "Accounts" as const, id: "LIST" }],
        }),
        getAccount: builder.query<User | null, string>({
            query: (user_id: string) => usersUrl(`/${user_id}`),
            transformResponse: (response: { data: User | null }) =>
                response.data,
            providesTags: (result, error, arg) => [
                { type: "Accounts" as const, id: arg },
            ],
        }),
        removeWarehouseFromAccount: builder.mutation<
            User | null,
            { user_id: string; warehouses: string[] }
        >({
            query: ({ user_id, warehouses }) => ({
                url: usersUrl(`/${user_id}/warehouses/remove`),
                method: "POST",
                body: {
                    warehouses,
                },
            }),
            transformResponse: (response: { data: User | null }) =>
                response.data,
            invalidatesTags: (result, error, arg) => [
                { type: "Accounts", id: arg.user_id },
                "Accounts",
            ],
        }),
        syncUserWithShiphero: builder.mutation<User | null, string>({
            query: (user_id) => ({
                url: usersUrl(`/${user_id}/shiphero/sync`),
                method: "POST",
                body: {
                    shiphero_access_token: localStorage.getItem(
                        SHIPHERO_ACCESS_TOKEN
                    ),
                },
            }),
            transformResponse: (response: { data: User | null }) =>
                response.data,
            invalidatesTags: (result, error, arg) => [
                { type: "Accounts", id: arg },
                "Accounts",
            ],
            onQueryStarted: (arg, { queryFulfilled }) => {
                toast.info("Syncing user with Shiphero....");
                queryFulfilled
                    .then((data) => {
                        if (!data)
                            toast.error(
                                "There was an error syncing the user with Shiphero."
                            );
                        else
                            toast.success(
                                "The user Shiphero sync was successful"
                            );
                    })
                    .catch(() => {
                        toast.error(
                            "There was an error syncing the user with Shiphero."
                        );
                    });
            },
        }),
        updateAccount: builder.mutation<
            User | null,
            {
                user_id: string;
                user_update: Partial<User>;
            }
        >({
            query: ({ user_id, user_update }) => ({
                url: usersUrl(`/${user_id}`),
                method: "PUT",
                body: {
                    updates: user_update,
                },
            }),
            transformResponse: (response: { data: User | null }) =>
                response.data,
            invalidatesTags: (result, error, arg) => [
                { type: "Accounts", id: arg.user_id },
                "Accounts",
            ],
            onQueryStarted: (arg, { queryFulfilled }) => {
                toast.info(`Updating user (${arg.user_id})`);

                queryFulfilled
                    .then((data) => {
                        if (!data) {
                            toast.error(
                                `There was an error updating user (${arg.user_id})`
                            );
                        } else {
                            toast.success(
                                `User was updated successfully (${arg.user_id})`
                            );
                        }
                    })
                    .catch(() => {
                        toast.error(
                            `There was an error updating user (${arg.user_id})`
                        );
                    });
            },
        }),
    }),
});

export const {
    useAddWarehouseToAccountMutation,
    useCreateAccountMutation,
    useDeactivateAccountMutation,
    useGetAccountQuery,
    useGetAccountsQuery,
    useRemoveWarehouseFromAccountMutation,
    useSyncUserWithShipheroMutation,
    useUpdateAccountMutation,
} = accountsApi;
