import { STRING_MEDIUM_MAX_LENGTH } from "consts";
import { ZShipheroDimensions } from "shiphero";
import { z } from "zod";
import { ZTimestamps } from "common";
import { ZPackagingPrefs, ZReturnPrefs, ZStockPrefs } from "./prefs";

export const ZProductIntegrationShiphero = z.object({
  account_id: z.string().optional(),
  id: z.string().optional(),
});
export type ProductIntegrationShiphero = z.infer<
  typeof ZProductIntegrationShiphero
>;

export const ZProductIntegrations = z.object({
  shiphero: ZProductIntegrationShiphero,
});
export type ProductIntegrations = z.infer<typeof ZProductIntegrations>;

export const ZProductAttribute = z.enum([
  "",
  "batteries",
  "fragile_items",
  "hazardous",
  "perishable",
  "oversized",
  "drugs",
]);
export type ProductAttribute = z.infer<typeof ZProductAttribute>;
export const PRODUCT_ATTRIBUTES = ZProductAttribute.enum;

export const ZProduct = z
  .object({
    _id: z.string(),
    active: z.boolean().optional(),
    attributes: z.array(ZProductAttribute).default([]),
    barcode: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH).optional(),
    company_id: z.string().trim(),
    dimensions: ZShipheroDimensions.optional(),
    integrations: ZProductIntegrations.default({
      shiphero: {},
    }),
    kit_components: z
      .array(
        z.object({
          sku: z.string().trim(),
          quantity: z.number(),
        }),
      )
      .optional(),
    name: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
    packing_prefs: ZPackagingPrefs.optional(),
    return_prefs: ZReturnPrefs.optional(),
    sku: z.string().trim().max(STRING_MEDIUM_MAX_LENGTH),
    stock_prefs: ZStockPrefs.optional(),
    special_requests: z.string().trim().optional(),
  })
  .extend(ZTimestamps);
export type Product = z.infer<typeof ZProduct>;

export const ZProductCreateInput = ZProduct.omit({
  _id: true,
  createdAt: true,
  updatedAt: true,
});
export type ProductCreateInput = z.infer<typeof ZProductCreateInput>;
