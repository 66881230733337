import { z } from "zod";
import { ZShipheroAddress } from "./common";

export const ZShipheroWarehouse = z.object({
  account_id: z.string().optional(),
  address: ZShipheroAddress.optional(),
  company_alias: z.string().optional(),
  company_name: z.string().optional(),
  dynamic_slotting: z.boolean().optional(),
  id: z.string().optional(),
  identifier: z.string().optional(),
  invoice_email: z.string().optional(),
  legacy_id: z.number().optional(),
  phone_number: z.string().optional(),
  profile: z.string().optional(),
  return_address: ZShipheroAddress.optional(),
});
export type ShipheroWarehouse = z.infer<typeof ZShipheroWarehouse>;
