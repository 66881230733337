import { useEffect, useRef, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { appSelectors, appActions } from "./app/appSlice";
import type { RootState, AppDispatch } from "./store";

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * Handles updating active page title in redux state
 * @param active_page Name of the newly active page
 */
export const useActivePage = (active_page: string) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(appActions.setActivePage(active_page));
        return () => {
            dispatch(appActions.setActivePage(""));
        };
    }, [active_page, dispatch]);
};

/**
 * Custom hook for handling click events that occur off an array elements.
 * The click must happen outside of ALL elements
 *
 * @param ref A reference to the element being monitored for a click outside
 * @param handler Function for handling the click outside event
 */
export const useClickOutsideAllHandler = (refs: any[], handler: Function) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: MouseEvent) {
            if (
                refs.every(
                    (ref) => ref.current && !ref.current.contains(event.target)
                )
            ) {
                handler();
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refs, handler]);
};

/**
 * Custom hook for handling click events that occur off an element.
 *
 * @param ref A reference to the element being monitored for a click outside
 * @param handler Function for handling the click outside event
 */
export const useClickOutsideHandler = (ref: any, handler: Function) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target)) {
                handler();
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, handler]);
};

export const useDebounceCallback = (
    callback: () => void,
    delay: number = 500
) => {
    useEffect(() => {
        const timeout = setTimeout(() => {
            callback();
        }, delay);
        return () => {
            clearTimeout(timeout);
        };
    }, [callback, delay]);
};

export const useDebounceValue = (value: any, delay = 500) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    const timerRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        timerRef.current = setTimeout(() => setDebouncedValue(value), delay);

        return () => {
            clearTimeout(timerRef.current);
        };
    }, [value, delay]);

    return debouncedValue;
};

export const useNavigateWithSearchParams = () => {
    const [search_params] = useSearchParams();
    const navigate = useNavigate();
    return (identifier: string) => {
        const navigate_to = {
            pathname: identifier,
            search: `?${search_params.toString()}`,
        };
        navigate(navigate_to);
    };
};

export const usePrevious = <T = unknown>(value: T) => {
    const value_ref = useRef<T>(value);

    // Handles mainting the previous document_url value
    useEffect(() => {
        value_ref.current = value;
    }, [value]);

    return value_ref.current;
};

/**
 * Scrolls the page to the top of the page when a component is rendered
 */
export const useScrollToTop = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "auto" });
    }, []);
};

/**
 * Scrolls the page to the top of the page when a component is rendered
 * When the component is removed the page scrolls back to the scrollY it come from
 */
export const useScrollToTopAndReturn = () => {
    useEffect(() => {
        window.scrollTo({ top: 0 });

        const top = window.scrollY;
        return function cleanup() {
            window.scrollTo({ top });
        };
    }, []);
};

export const useWhiteBgOnMobile = () => {
    const view_mode = useAppSelector(appSelectors.selectViewMode);
    useEffect(() => {
        if (view_mode === "mobile") {
            document.body.classList.add("bg-white");
        }
        return () => {
            document.body.classList.remove("bg-white");
        };
    }, [view_mode]);
};
