import {
    AnyAction,
    combineReducers,
    configureStore,
    ConfigureStoreOptions,
} from "@reduxjs/toolkit";

// Base Redux
import { appSlice } from "app/appSlice";
import collectionsReducer from "features/inventory/collections/CollectionsSlice";
import inboundShipmentReducer from "features/inventory/inbound-shipments/state";
import invoiceReducer, {
    SLICE_NAME as invoiceSliceName,
} from "features/billing/Invoices/Invoice/InvoiceSlice";
import orderReducer from "features/orders/state";
import sidebarReducer from "components/Sidebar/reducer";
import userReducer from "features/user/userSlice";
import modalReducer from "modals/slice";
// RTK Query
import { baseSlice } from "api";
import b2bReducer, { b2bUiSliceMiddlewear } from "features/b2b/state";
import { b2bAdminFormSliceMiddlewear } from "features/b2b/admin/state/formSlice";
import inventoryReducer from "features/inventory/state";
import { productHistorySliceMiddlewear } from "features/inventory/stock/components/ProductHistory/middleware";
import { adminCreateUserSelectSlice } from "components/AdminCreateUserSelect";
import modulesReducer from "modules/state";
import { queryAndFilterMiddleware } from "modules/QueryAndFilter";
import { inboundShipmentMiddleware } from "features/inventory/inbound-shipments/middlewear";

export const RESET_STORE = "reset";

const portalReducer = combineReducers({
    [adminCreateUserSelectSlice.name]: adminCreateUserSelectSlice.reducer,
    [appSlice.name]: appSlice.reducer,
    b2b: b2bReducer,
    collections: collectionsReducer,
    inbound_shipment: inboundShipmentReducer,
    inventory: inventoryReducer,
    [invoiceSliceName]: invoiceReducer,
    modules: modulesReducer,
    modal: modalReducer,
    order: orderReducer,
    sidebar: sidebarReducer,
    user: userReducer,
    [baseSlice.reducerPath]: baseSlice.reducer,
});

const rootReducer = (state: any, action: AnyAction) => {
    if (action.type === RESET_STORE) {
        return portalReducer(undefined, action);
    }
    return portalReducer(state, action);
};

export const createStore = (
    options?: ConfigureStoreOptions["preloadedState"] | undefined
) =>
    configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(
                baseSlice.middleware,
                b2bUiSliceMiddlewear.middleware,
                b2bAdminFormSliceMiddlewear.middleware,
                productHistorySliceMiddlewear.middleware,
                queryAndFilterMiddleware.middleware,
                ...inboundShipmentMiddleware
            ),
        ...options,
    });
const store = createStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;

export const clearReduxCache = () => {
    store.dispatch({
        type: RESET_STORE,
    });
};
