import { createListenerMiddleware } from "@reduxjs/toolkit";
import {
    inboundShipmentReceivingActions,
    inboundShipmentReceivingSelectors,
} from "../state/receiving";
import { RootState } from "store";
import { inboundShipmentActions } from "../state/inboundShipment";

export const inboundShipmentReceivingMiddleware = createListenerMiddleware();

/* Dispatches the receive item action when the confirmation is successful */
inboundShipmentReceivingMiddleware.startListening({
    actionCreator: inboundShipmentReceivingActions.acceptExtraQuantity,
    effect: (action, listenerApi) => {
        const item =
            inboundShipmentReceivingSelectors.selectMoreReceivedThanExpectedItem(
                listenerApi.getState() as RootState
            );
        if (item) {
            listenerApi.dispatch(inboundShipmentActions.receiveItem(item));
            listenerApi.dispatch(
                inboundShipmentReceivingActions.resetMoreReceivedThanExpected()
            );
        }
    },
});

/* Resets newly received item state for a sku when that sku is received  */
inboundShipmentReceivingMiddleware.startListening({
    actionCreator: inboundShipmentActions.receiveItem,
    effect: (action, listenerApi) => {
        listenerApi.dispatch(
            inboundShipmentReceivingActions.updateNewlyReceivedItemBySku({
                sku: action.payload.sku,
                receivedItem: {
                    quantity: 0,
                },
            })
        );
    },
});
