import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { queryAndFilterActions } from "./slice";
import { appActions } from "app/appSlice";

export const queryAndFilterMiddleware = createListenerMiddleware();
queryAndFilterMiddleware.startListening({
    matcher: isAnyOf(appActions.setFeature, appActions.setSubFeature),
    effect: (action, listenerApi) => {
        listenerApi.dispatch(queryAndFilterActions.reset());
    },
});
