export const VIEW_MODE__MOBILE = "mobile";
export const VIEW_MODE__DESKTOP = "desktop";

export const PAGE__ACCOUNT = "Account";
export const PAGE__ACCOUNT_DETAILS = "Account Details";

export const PAGE__COLLECTIONS = "New Collection";
export const PAGE__CREATE_ORDER = "Create Manual Order";

export const PAGE__INVENTORY = "Inventory";
export const PAGE__B2B_DETAILS = "B2B Order Details";
export const PAGE__INBOUND_SHIPMENT_DETAILS = "Inbound Shipment Details";

export const PAGE__BILLING = "Billing";
export const PAGE__INVOICE = "Invoice";

export const PAGE__ORDER_DETAILS = "Order Details";
export const PAGE__ORDERS = "Orders";

export const PAGE__PRODUCT_DETAILS = "Product Details";

export const PAGE__WAREHOUSE_DETAILS = "Warehouse Details";

export const SVG_ACTIVE_BACKGROUND_COLOR = "#E5E9F2";
