import { v4 } from "uuid";
import { B2BPallet_FE, B2BPick_Input_FE } from "./types";
import { B2BBox, B2BBoxItem } from "@librex-fulfillment/librex-zchemas";

export function dimensionsFactory(): Dimensions {
    return {
        length: "0",
        width: "0",
        height: "0",
        weight: "0",
    };
}
export const defaultDimensions: Dimensions = dimensionsFactory();

export const CLIENT_BOX_PREFIX = "BOX_CLIENT_";
export function b2bBoxFactory(): B2BBox {
    return {
        _id: `${CLIENT_BOX_PREFIX}${v4()}`,
        createdAt: new Date().toISOString(),
        dimensions: dimensionsFactory(),
        items: [],
        quantity: 0,
        updatedAt: new Date().toISOString(),
    };
}
export const defaultB2bBox: B2BBox = b2bBoxFactory();

export const CLIENT_BOX_ITEM_PREFIX = "BOX_ITEM_CLIENT_";
export function b2bBoxItemFactory(
    boxItem: Partial<B2BBoxItem> = {}
): B2BBoxItem {
    return {
        _id: `${CLIENT_BOX_ITEM_PREFIX}${v4()}`,
        item_id: "",
        name: "",
        quantity: 0,
        sku: "",
        ...boxItem,
    };
}

export function b2bFormBoxFactory(): Omit<B2BBox, "dimensions" | "quantity"> {
    return {
        _id: v4(),
        createdAt: new Date().toISOString(),
        items: [b2bBoxItemFactory()],
        updatedAt: new Date().toISOString(),
    };
}

export function b2bPalletFactory(): B2BPallet_FE {
    return {
        // The id field is not defined in the backend
        // We need it on the frontend for the purpose of adding and removing pallets
        // Nothing about a pallet is unique and this is an issue for rendering the list of pallets
        id: v4(),
        dimensions: dimensionsFactory(),
        quantity: 0,
    };
}

export const B2BPickInputFactory = ({
    b2b_id = "",
    company_id = "",
    item_id = "",
    location,
    location_id = "",
    quantity = 0,
    warehouse_id = "",
}: Partial<B2BPick_Input_FE>): B2BPick_Input_FE => ({
    _id: v4(),
    b2b_id,
    company_id,
    item_id,
    location,
    location_id,
    quantity,
    warehouse_id,
});
